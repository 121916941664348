/*cargador
homo(.cargando-eventos) - secciones(.progress-disabled)
==========================================================*/
.cargando-eventos,
.progress-disabled{
   &::before{
      content: " ";
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.38) !important ;
      background-position: center center;
      z-index: 999999999 !important;
      widows: inherit;
   }
   &::after{
      content: " ";
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100% !important;
      height: 100% !important;
      background: url(../img/loader.gif) no-repeat center center;
      z-index: 999999999;
      background-color: transparent !important;
      color: transparent;
      margin-left: 0 !important;
   }
}
.ajax-processed.progress-disabled{
   border: none !important;
   color: transparent !important;
}
