.page-node .main-container{
    top: 0;
    font-family: 'Alegreya', serif;
    * {
        font-family: 'Alegreya', serif;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Alegreya', serif;
    }
   
    h1 {
        text-align: center;
        font-size: 40px;
        color: black;
        line-height: 1.2;
        padding: 0 40px;
    }

    .group-2018-interna-date-sec {
        text-align: center;
    }

    .field-name-post-date {
        color: black;
        text-transform: lowercase;
        display: inline-block;
    }

    .field-type-taxonomy-term-reference {
        color: black;
        text-transform: lowercase;
        display: inline-block;
        font-size: 18px;
        padding-left: 8px;
        margin-left: 8px;
        border-left: 1px solid black;
        font-weight: 600;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid black;
        a {
            color: black;
            text-transform: capitalize;
        }
    }

    .field-name-field-foto-cuadrada-cortesia {
        background: #666666;
        display: inline-block;
        float: right;
        margin-top: -45px;
        padding: 3px 9px;
        color: white;
        font-style: italic;
        font-size: 18px;
        z-index: 1;
        position: relative;
    }

    
    .field-name-field-escrito-por {
        font-size: inherit;
        .field-item::before {
            content: "Por: ";
            font-size: inherit;
            font-weight: bold;
            text-transform: capitalize;
            font-weight: bold;
        }
    }

    .field-name-lo-mas-reciente h2.block-title2,
    .field-name-recent-content h2.block-title2 {
        background: transparent;
        color: black;
        text-transform: lowercase;
        font-size: 26px;
        &:before {
            display: none;
        }
        &::first-letter {
            text-transform: uppercase;
        }

        &:after {
            content: "";
            display: inline-block;
            width: calc(100% - 174px);
            height: 1px;
            background-color: black;
            vertical-align: middle;
            margin-left: 5px;
        }
    }

    .field-name-lo-mas-reciente .field-type-taxonomy-term-reference,
    .field-name-recent-content .field-type-taxonomy-term-reference{
        padding: 0;
        margin: 0;
        border: 0;
        h3 {
            font-weight: 600;
        }
    }
}


.field-name-lo-mas-reciente .view-content .views-row .group-flotado,
.field-name-recent-content .view-content .views-row .group-flotado{
    .field-name-title div a {
        font-family: 'Alegreya', serif;
    }
    .field-name-field-seccion.field-type-taxonomy-term-reference {

        .taxonomy-term {
            &.taxonomy-term-música,
            &.taxonomy-term-deportes,
            &.taxonomy-term-arte,
            &.taxonomy-term-noticias,
            &.taxonomy-term-sexo,
            &.taxonomy-term-opinión,
            &.taxonomy-term-diseño,
            &.taxonomy-term-agenda,
            &.taxonomy-term-historias,
            &.taxonomy-term-moda,
            &.taxonomy-term-420
            { 
                background-color: transparent;
                padding: 0;
            }
        }
      
        .field-name-title {
            clear: both;
            h3 a {
                font-family: 'Alegreya', serif;
                color: black !important;
            }
        } 
    
        .taxonomy-term .field-name-field-icono {
            display: none;
        }
    }
}

.field-name-newsletter-block{
    background-color: #edede8;
    form button {
        font-family: 'Alegreya', serif;
        background-color: #666666;
        text-transform: lowercase;
        padding: 0 10px;
        &::first-letter {
            text-transform: uppercase;
        }
        &:after {
            display: none;
        }
    }

    form input {
        border: 0;
        box-shadow: none;
        background-color: transparent;
    }



}
        .group-comentarios-fb {
            h3 {
                background: black;
                padding: 9px;
                font-size: 18px;
                color: white;
                margin: 0;
            }
            & > div {
                background: #f3f3f4;
            }
        }

.page-node .main-container {

    .group-contenido-relacionado {
        h3 {
            background: transparent;
            padding: 0;
            margin-top: 40px;
            font-size: 26px;
            span {
                color: black;
                text-transform: capitalize;
                &:after {
                    content: "";
                    display: inline-block;
                    width: calc(100% - 257px);
                    height: 1px;
                    background-color: black;
                    vertical-align: middle;
                    margin-left: 5px;
                }
            }
        }
    
        .field-type-taxonomy-term-reference {
            border: 0;
            padding: 0;
            margin: 0;
            /*
            .taxonomy-term.vocabulary-sections {
                background: transparent !important;
                padding: 0;
            }
            */
        }
       
        .field-name-relacionado 
        #related_content_slider ul li 
        .node.view-mode-relacionado_principal 
        .group-right{
            .taxonomy-term {
               padding: 0;
               background: transparent !important;
               .field-name-field-icono {
                   display: none;
               }
               .field-name-title {
                   margin: 0;
                   h3{
                       font-size: 24px;
                       letter-spacing: 1px;
                       font-weight: 600;                
                       a{
                           color: black;
                       }
                   } 
               }
           }
           .field-name-title h2 {
            font-size: 20px;
            font-weight: 100;
                a {
                    color: #7b7b7b;
                }
           }
        }
    }
}

