.region-footer {
    * {
        font-family: 'Alegreya', serif;
    }
    .newsletter {
        form > div {
            display: flex;
            justify-content: center;
            max-width: 700px;
            margin: 0 auto;

            .mailchimp-newsletter-mergefields {
                flex-basis: calc(100% - 104px);
            }
        }

        input {
            border: 1px solid #666666;
            background: transparent;
            margin: 0;
            height: 25px;
            width: 100%;
            font-family: 'Alegreya', serif;
            text-align: center;
        }

        .btn.form-submit{
            background: #666666;
            border: 0;
            color: white;
            font-weight: 500;
            border-radius: 0;
            height: 25px;
            line-height: 1;
            margin-left: 10px;
        }
    }

    .second {
        text-align: center;
        position: relative;
        margin-bottom: -31px;
        .politica {
            display: inline-block;
            padding: 20px 10px 0;
            width: 100%;
            max-width: 350px;
            background: white;
            font-family: 'Alegreya', serif;
        }
        &:after {
            max-width: 350px;
            display: block;
            margin: 0 auto;
            content: "";
            width: 100%;
            height: 20px;

            border: 15px solid white;
            border-color: white transparent transparent transparent;
        }
        a {
            color: black;
            &:before,
            &:after {
                display: inline-block;
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: black;
                position: relative;
                top: -2px;
                margin: 0 8px;
            }
        }
    }

    .third {
        background-color: black;
        .contact-info {
            text-align: center;
            padding: 40px 0 10px;
        }

        h3 {
            font-size: 24px;
            font-weight: 600;
            color: white;
            margin: 10px 0 20px;
            font-family: 'Alegreya', serif;
        }

        ul {
            list-style: none;
            padding: 0;
            li{
                display: inline-block;
                a{
                    display: inline-block;
                    margin: 10px;
                    width: 25px;
                    height: 25px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    font-size: 0;
                }
            }

            .fb a{
                background-image: url('../img/facebook-white.svg');
            }
            .inst a{
                background-image: url('../img/instagram-white.svg');
            }
            .tw a{
                background-image: url('../img/twitter-white.svg');
            }
            .mail a{
                background-image: url('../img/mail-white.svg');
            }
            .manif a {
                background-image: url('../img/manifiesto-footer.png');
            }
            .team a {
                background-image: url('../img/team-footer.png');
            }
            .pauta a {
                background-image: url('../img/paute-footer.png');
            }

        }

        .second-contact-info {
            text-align: center;
            color: white;
            padding: 0 10px 30px;
            .phone {
                &:before{
                    content: "";
                    width: 12px;
                    height: 12px;
                    background-image: url(../img/phone-white.svg);
                    background-size: contain;
                    display: inline-block;
                    margin-right: 4px;
                }
            }
            .address {
                display: block;
                font-weight: 500;
            }
            .copyright {
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }

    .cartel-hr {
        position: relative;
        &:after{
            content: "";
            width: 100%;
            height: 1px;
            background: white;
            position: absolute;
            left: 0;
            top: 25px;
            z-index: 0;
        }

        //
        &:before {
            content: "";
            background-image: url('../img/icon-black.png');
            width: 50px;
            height: 50px;
            -webkit-background-size: contain;
            background-size: 30px;
            background-repeat: no-repeat;
            margin: 0 auto 30px;
            background-position: 50%;
            display: block;
            position: relative;
            background-color: black;
            z-index: 1;
        }
    }
}

@media(max-width: 767px){
    .region-footer .second {
        text-align: center;
        position: relative;
        margin-bottom: -26px;
        .politica {
            padding-bottom: 15px;
        }

        &:after {
            max-width: 160px;
        }
    }

    .region-footer .newsletter form>div {
        display: block;
        text-align: center;
        padding: 10px;
        input {
            font-size: 11px;
        }
    }

    .secondary-item-container {
        width: 110px !important;
    }
}

.page-newsletter-cartel{
    #mailchimp-signup-subscribe-block-form-signup-block-form{
        text-align: center;
        margin-top: 70px;
    }
}

.color-blank,
.color-blank:hover,
.color-blank:active{
    color: #fff;
}


.secondary-item-container{
  width:150px;
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin: 10px 0 20px;
  font-family: 'Alegreya',serif;
  position: relative;
}

.secondary-item-container *{
  display:block;
}


.secondary-item-container a::before{
  content:"";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.region-footer .third ul.secondary-icons-footer li a{
    width: 30px;
    height:40px;
}

.page-node-29809 .media_embed{
height: auto !important
}

div.contextual-links-wrapper.contextual-links-processed > ul > li.node-webform{
display:block !important;	
}

#google_ads_iframe_104795515\/Cartel_Galeria_728x90_interna_0__container__{
text-align:center !important;
}

body.full {

  .field-type-taxonomy-term-reference{
    display: none !important;
  }

  .field-name-title{
    text-align: center;
    margin-left: 20px;
  }

  .field-name-post-date {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 20px 0 !important;
  }

  .field-name-field-imagen-cuadrada {
    text-align: center;
  }

  .field-name-field-imagen-redes{
    text-align: center;
    img{
      width: 60vw;
      height: 80vh; 
    }
  }

  .field-name-summary-body,
  .field-name-body {
    margin: 0 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .field-name-field-escrito-por {
    text-align: center;
  }

  #node_article_full_group_comentarios{
    margin: 0 10%;
  }

  .group-title-image{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

}



@media (max-width:600px)  { 
  body.full{

    .field-name-field-imagen-redes img {
        width: 80vw;
        height: 20vh;
        margin-bottom: 30px;
    }

    .field-name-body img {
      max-width: 300px;
      max-height: 200px;
    }

    .group-title-image{
        display: flex;
        flex-direction: column;
    }

    display: block !important;
    margin: 0 10% !important;
    text-align: center;

    .field-name-social-network{
      display: none;
    }

    .footer-2018{
      width: 129%;
      position: relative;
      left: -14%;
    }

    .field-name-title, 
    .field-name-post-date{
	padding-left: 0 !important;
	margin-left: 0 !important;
	padding: 0;
	margin: 0;
    }
  }
}

