/*------------------------------------------------------------*/
/*---------------------interna-edicion-impresa----------------*/
/*------------------------------------------------------------*/
.page-taxonomy{/*cambiar por nuevo vocabulario body*/
   .block{
      .region-top{
         position: relative;
         .field-name-imagen-fondo-mobile{
            display: none;
         }
         .field-name-field-imagen-campo-edicion{/*img banner superior*/
            img{
               width: 100%;
               height: auto;
               /* background: linear-gradient( #000 , rgba(0, 0, 255, 0));
               &::after{
               background: linear-gradient( #000 , rgba(0, 0, 255, 0));
            }*/

            }
         }
         .group-revista-imagen{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .field-name-field-caratula{/*img edicion*/
               img{
                  //width: 80%;
                  width: 80%;
                  height: auto;
                  margin: 0 auto;
                  position: relative;
                  left: 50%;
                  transform: translate(-50%);
               }
            }/*/img edicion*/
            .field-name-field-numero-edicion{/*titulo nuemero de edicion*/
               font-family: 'bebas_neueregular',Arial-narrow;
               font-weight: bold;
               letter-spacing: 2px;
               font-size: 35px;
               text-align: center;
               color: #fff;
               border-bottom: 2px solid #00a3a3;
               width: 50%;
               margin: 0 auto;
               margin-top: 18px;

               .field-label{
                  display: inline-block !important;
                  float: none !important;
               }
               .field-items{
                  display: inline-block !important;
                  float: none !important;
                  a{
                     color: @blanco;
                  }
               }
            }/*/nuemero edicion*/
            .field-name-field-entradilla-edicion-impresa{
               width: 80%;
               position: relative;
               left: 50%;
               transform: translate(-50%);
               line-height: 17px;
               color: #fff;
               text-align: center;
               margin-top: 5px;
            }
         }/*/group-revista-imagen*/
      }/*/region-top*/

      /*------------------nodos----------------*/
      .pane-edicion-impresa-panel-pane-1{
         max-width: 1280px;
         margin: 0 auto;
         margin-top: -119px;
         .views-row{
            width: 30%;
            display: inline-block;
            margin: 13px;
            clear: both;
            box-shadow: 5px 5px 20px 0 rgba(50,50,50,0.77);
         } 
      }
   }/*/block*/

}/*/.page-taxonomy*/
