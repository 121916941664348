@media (max-width: 1024px) and (min-width: 320px) {
    /*---------------------------nodos-------------------------*/
    @import "home/mobile/_nodo-view-mode-carta-mobile";
    /*----------------------home -------------------------*/
    @import "home/mobile/_destacados-home-mobile";
    @import "home/mobile/_layout_listado_nodo_home_mobile";
    /*--------------------edicion impresa interna--------------*/
    @import "home/mobile/_m-interna-edicion-impresa";
    /*--------------------menu lateral--------------*/
    //@import "home/mobile/_m-menu-lateral";

}/*/mediaq*/


/*
mediaq Desktop nodos
=====================================================================*/
.block-cu-mas-popular,
.pane-cu-mas-popular-los-eventos-mas-popular{
    @media (max-width: 1366px) {
        height: 765px;
    }
    @media (max-width: 1280px) {
        height: 738px;
    }
}

.home-panel .node-publicidad{
    @media (max-width: 1366px) {
     height: 368px;
    }
    @media (max-width: 1280px) {
        height:  356px;
    }
}

.node-enlace:not(.ds-1col.contextual-links-region.view-mode-slider_home_eventos){
    @media (max-width: 1366px) {
        height: 372px;
    }
    @media (max-width: 1280px) {
        height:  358px;
    }
}

