.agenda-eventos { min-height: 450px; } /* evita que el cargador no se vea cuando no hay eventos */

/* listado de eventos
------------------------------------------------------------- */
.page-eventos .view-content {
	.clearfix();

	/* stilos moviles ================ */
	@media (max-width: @screen-sm-min) {
		margin-left: -7.5px;
		margin-right: -7.5px;

		.cartel-block {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
	}
}

.page-eventos .agenda-eventos .view-content { /* wrap: listado de eventos */
	.transition(all 0.5s ease-in-out);
	margin: auto;
	max-width: 1300px; /* fix grandes resoluciones */
}

.entity-eventos, .node-eventos {
	position: relative;
	overflow: hidden;
	padding-bottom: 20px;
	&:hover {
		.group-evento-hover { .translate3d(0,0,0); }
		.group-desde-hasta,
		.group-fecha-evento { .translate3d(0,150%,0); }
	}
}

.group-imagen-evento {
	overflow: hidden;
	position: relative;
	font-family: @bebas;
	color: white;

	.field-name-field-imagen-cuadrada a {
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 5;
		}
	}
}

.group-desde-hasta,
.group-fecha-evento { .transition(transform 0.45s ease-out); }

/* grupo fecha corta ------------------------- */
.group-fecha-evento {
	position: absolute;
	width: 55px;
	height: auto;
	min-height: 56px;
	bottom: 5px;
	left: 0;
	background-color: @azul;

	z-index: 2;

	&::after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		border-left: 15px solid @azul;
		border-top: 15px solid transparent;
		right: -15px;
		bottom: 0;
	}

	/* stilos moviles ================ */
	@media (max-width: @screen-sm-min) { bottom: 0;}

	.field-name-fecha-corta {
		padding-top: 3px;
		padding-left: 5px;
		padding-bottom: 2px;
		font-size: 22px;
		line-height: 18px;

		> .field-items { width: 65%; border-bottom: 1px solid white; }
	}

	.field-name-field-tipo-de-evento {
		h2 {
			position: relative;
			margin: 0;
			padding-left: 5px;
			font-size: 13px;
			z-index: 2;
			a {
				color: white;
			}
		}
		.content { .hidden; }
	}
}

/* grupo desde - hasta ----------------------- */
.group-desde-hasta {
	display: none; visibility: hidden;
	/* stilos desktop ================ */
	@media (min-width: @screen-sm-min) {
		.mostrar;
		position: absolute;
		width: 100%;
		height: 56px;
		right: 0;
		bottom: 5px;
		background-color: @negro;
		text-transform: uppercase;
		font-family: @strait;
		color: white;

		z-index: 1;

		> div { padding-left: 36%; padding-top: 11px; line-height: 15px; }

		span { font-size: 14px; }

		span.date-display-start::before { content: 'DESDE: '; }

		span.date-display-end::before { content: 'HASTA: '; }
	}
}

/* titulo evento ----------------------- */
.entity-eventos > .field-name-title,
.node-eventos > .field-name-title
 {

	h2 { font-size: 24px; line-height: 21px; margin-top: 10px; margin-bottom: 0; color: @negro; }

	/* stilos desktop ================ */
	@media (min-width: @screen-sm-min) {
		overflow: hidden;
		height: 50px;
		background-color: white;
		border: 2px solid @azul;
		margin-top: 4px;
		padding: 3px 10px;

		h2 { margin: 0; }
	}
}

/* Grupo hover ------------------------- */
.group-evento-hover {
	font-family: @bebas;

	/* estilos moviles ================== */
	@media (max-width: @screen-sm-min) {
		display: none !important;
		visibility: hidden !important;
	}

	.field-items,
	.field-item.even,
	.field-label { font-weight: normal !important; }

	/* stilos desktop ================ */
	@media (min-width: @screen-sm-min) {
		position: absolute;
		bottom: 5px;
		top: 5px;
		left: 6px;
		right: 6px;
		text-transform: uppercase;
		text-align: center;
		color: white;

		z-index: 1;

		.transition(transform 0.45s ease-out);
		.translate3d(0,-150%,0);

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			opacity: 0.7;
			z-index: -1;
			background: rgba(0,0,0,0.7);
		}

		.field-name-field-donde {
			width: 70%;
			overflow: hidden;
			max-height: 54px;
			margin: 10px auto 0;

			.field-item.even {
				font-size: 27px;
				line-height: 21px;
			}

			.field-label { line-height: 12px; }
		}

		.field-name-field-precio {
			position: absolute;
			bottom: 20px;
			left: 0;
			right: 0;
			text-align: center;

			.field-item.even {
    			font-size: 30px;
    			line-height: 17px;
			}
		}

		.field-name-icono-tipo-evento {
			h2, .field-label { .hidden; }
			.field-item { font-size: 80px; }
		}
	}

	/* stilos moviles ================ */
	@media (max-width: @screen-sm-min) {
		.field-items,
		.field-item.even,
		.field-label { display: inline; }
	}
}

/* ---------------------------------------------- 
			estilos unicos moviles
----------------------------------------------- */
@media (max-width: @screen-sm-min) {
	.agenda-eventos { /* se establece alto minimo en moviles */
		.entity-eventos,
		.node-eventos {
			min-height: 220px;
		}
	}
}

/* ---------------------------------------------- 
			estilos unicos desktop
----------------------------------------------- */
@media (min-width: @screen-sm-min) {
	/* estructura
	-------------------------------------------------- */
	.page-eventos .agenda-eventos .view-content { /* wrap: listado de eventos */
		width: 83.33333333%;
		min-width: 1124px;
	}
}


/*fondo temporal*/
.page-eventos-agenda {
	.view-agenda{
		.view-content{
			//background-image: url('../img/fondo-agenda-nov.jpg');
			background-size: 90px;
			&:before{
				content: "";
				width: 100%;
				position: absolute;
				display: block;
				z-index: 0;
				left: 0;
				min-height: 500px;
				height: 100%;
				//background-image: url('../img/TEXTURA-MIAW.png');
				background-size: 90px;
				opacity: .7;
			}
		}
	}
	@media(max-width: 768px){
		.view-content .cartel-block{
			margin-bottom: 10px;
			height: 290px;
			overflow: auto;
		div:not(.ds-1col)>.field-name-title{
			background-color: #fff;
			h2 {
				padding: 5px;
				border: 1px solid #07b9e3;
			}
		}
		}

	}
}
.node-type-eventos{
	section#block-system-main{

		//background-image: url('../img/fondo-agenda-nov.jpg');
		background-size: 90px;
			&:before{
				content: "";
				width: 100%;
				position: absolute;
				display: block;
				z-index: 0;
				left: 0;
				min-height: 500px;
				height: 100%;
				//background-image: url('../img/TEXTURA-MIAW.png');
				background-size: 90px;
				opacity: .7;
			}
		.region-left  {
			background-color: #fff;
				@media(min-width: 768px) and (max-width: 1280px){
					width: 64.333333% !important;
				}
			}
		}
		.region-right {
			margin-top: 64px;
		}
	#container-popular-events {
		background-color: #fff;
	}
}


.view-agenda.view-id-agenda{

table tr td {
	width: 25%;
    vertical-align: top;

	#node_eventos_teaser_group_fecha_evento {
		font-family: 'bebas_neueregular',Arial-narrow;
    		color: white;
	}
}

@media (max-width: 768px){

	table, tr, tbody, td {
		display:block !important;
		width: 100% !important;
	}
	table {
		padding-top: 10px;
	}

}


}










.view-mode-2018_agenda_feed_card{
	margin-bottom: 50px !important;
}

.view-mode-2018_agenda_feed_card .field-name-title,
.view-mode-2018_agenda_feed_card .field-name-field-fecha {
	font-family: 'Alegreya', serif;
}
.view-mode-2018_agenda_feed_card .field-name-title h2 {
	font-family: 'Alegreya', serif;
}

.view-mode-2018_agenda_feed_card .field-name-field-fecha{
	text-align: center;
	margin-top:10px;
	margin-bottom:15px;
}

.entity-eventos>.field-name-title, .node-eventos>.field-name-title {
	border: none !important;
}

.field-name-field-fecha{
	font-size:20px;
}

.field-name-field-entradilla{
	color: #000 !important;
	text-shadow: none !important;
	font-size:24px !important;
	font-weight: normal !important;
}

.field-name-field-direccion{
	margin-top: -80px;
}


.view-id-nodequeue_4 .field-name-title h2{
	font-family: 'Alegreya',serif;
	color: #000;
	margin-top: 40% !important;
	margin-left: 40%;
}

.view-id-nodequeue_4 .field-name-post-date {
	margin-left: 49%;
}




.view-id-nodequeue_11 ul{
	text-align: center;
	font-family: 'Alegreya',serif;
}
.view-id-nodequeue_11 ul li{
	display: inline-block;
	vertical-align: middle;
	width: 32%;
	padding: 0px 10px 0px 10px !important;
	margin: 0 !Important;
}

.view-id-nodequeue_11 ul li .field-name-field-fecha{
	border-bottom: solid 1px #666;
	margin: 0px 100px;
	text-align: center;
}

.view-id-nodequeue_11 ul li .field-name-field-tipo-de-evento{
	font-size:30px !important;
	color: #000;
}

.view-id-nodequeue_11 ul li h2{
	font-family: 'Alegreya',serif;
	color:#666666 !important;
}

.view-id-nodequeue_11 .view-mode-2018_home_second_card .field-name-field-imagen-cuadrada img{
    width: 100%;
    height: auto;
    margin-bottom:20px;
}

@media (max-width: 768px){
	#block-system-main > div > div.agenda-tpl.cartel-panel.panel-display.agenda-eventos > div > div > div.contextual-links-region.panel-pane.pane-views-panes.pane-agenda-panel-pane-1 > div.pane-content > div > div.view-content{
		text-align: center !important;
	}
  #node_eventos_full_mobile_group_infe_evento{
    position: relative!important;
    display: block!important;
    margin-top: 130px !important;
  }

	.view-id-nodequeue_11 ul li{
		display: block !important;
		width: 100% !important;
		text-align: center !important;
		padding-bottom: 25px !important;
	}
}

@media (max-width: 768px){
.view-agenda.view-id-agenda table, .view-agenda.view-id-agenda tr, .view-agenda.view-id-agenda tbody, .view-agenda.view-id-agenda td {
    display: block !important;
    width: 100% !important;
    text-align: center;

}
}

#dfp-ad-cartel_interna_728x90-wrapper{

text-align: center;
    margin-bottom: 20px;

}
