.page-taxonomy {
  font-family: 'Alegreya', serif;
  * {
    font-family: 'Alegreya', serif;
  }
  .pane-content {
    display: flex;
    flex-wrap: wrap;
    .view-mode-2018_home_feed_card,
    .view-mode-2018_agenda_feed_card{
      flex-basis: 33.3%;
      padding: 10px;
      margin-bottom: 20px;
    }
  }

  .col-sm-10 #div_more > div {
    display: flex;
    flex-wrap: wrap;
  }

  .region-top .cabezote-seccion {
    &:after {
      background: none;
    }
    .background-titulo-seccion {
      display: none;
    }

    .titulo-seccion {
      position: static;
      transform: translate(0);
      width: auto;
      height: auto;
      margin: 0;
      text-align: center;
      margin-top: -18px;
      h1 {
        line-height: 1;
        background: black;
        font-size: 26px;
        text-shadow: none;
        opacity: 1;
        padding: 5px 10px;
        color: white;

      }
    }
  }

  .pane-nodequeue-1 {
    width: 100%;
    margin-bottom: 35px;
  }
  .view-mode-2018_home_double_card {
    display: flex;
    .field-name-field-imagen-apaisada {
      width: 66.6%;
      float: none;
      padding-right: 10px;
    }

    .group-right {
      width: 33.3%;
      float: none;
      padding: 0 20px;
    }

    .field-name-title h2 {
      font-size: 26px;
      line-height: 1;
    }

    .field-name-field-subtitulo {
      font-size: 18px;
      color: #545454;
    }

    .group-2018-date-seccion {
      & > div {
        display: inline-block;
      }
      .field-type-taxonomy-term-reference {
        font-weight: bold;
        font-size: 18px;
        margin-left: 8px;
        padding-left: 8px;
        border-left: 1px solid black;
        line-height: 1;
        display: inline-block;
        a {
          color: black;
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .region-main.col-sm-10 {
    min-width: 800px;
    max-width: 1300px;
  }

  #dfp-ad-cartel_noticias_300x250-wrapper {
    position: absolute;
    top: 300px;
    right: 50px;
  }
}




