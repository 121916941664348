.popup-banner{
    position: fixed;
    top: 100%;
    width: 100%;
    background: rgba(0,0,0,.7);
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    overflow: hidden;
}

.popup-banner.content-ad{

margin: auto;
position: absolute;
top: 0;
left:0;
right:0;
bottom: 0;
max-height: 0%;
max-width: 0%;
border: 3px solid white;
box-shadow: 0px 0px 8px rgba(0,0,0,.3);
box-sizing: border-box;
-webkit-transition: .5s ease-in-out;
-moz-transition: .5s ease-in-out;
-o-transition: .5s ease-in-out;
transition: .5s ease-in-out;
        
}

/* menu admin */
#admin-menu { z-index: 99999; }

/* custom mixins */
.gradient-hover { /* from blue - to purple */
    background: rgb(107,111,144); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(107,111,144,1) 0%, rgba(170,117,142,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(107,111,144,1)), color-stop(100%,rgba(170,117,142,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(107,111,144,1) 0%,rgba(170,117,142,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(107,111,144,1) 0%,rgba(170,117,142,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(107,111,144,1) 0%,rgba(170,117,142,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(107,111,144,1) 0%,rgba(170,117,142,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b6f90', endColorstr='#aa758e',GradientType=0 ); /* IE6-9 */
}

/* definir tipografia base */
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: @lato;
    font-weight: normal;
    font-size: 18px;
    color: @gris-oscuro;
}
body::-webkit-scrollbar{
    width: 15px;  /* for vertical scrollbars */
    height: 10px; /* for horizontal scrollbars */
}

body::-webkit-scrollbar-track{
    background: rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb{
    background: rgb(0, 0, 0);
    border-radius: 10px;
}

/* cuando menu lateral esta activo bloqueamos el scroll */
body.MenuLateralActivo { 
    overflow: hidden;

    &::after {
        content:'';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 1020;
    }
}


/* contenedor principal */
.main-container { overflow: hidden; }

/* tamaños fuentes - encabezados */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: @bebas;
    a {
        color: @negro;
        &:hover { color: @negro; }
    }
}

a, a:hover, a:active {text-decoration: none !important;}

/* fomularios ------ */
.btn-success { font-family: @bebas; }

/* entradilla split screen -------- */

.layout-split .field-name-summary-body {
    background-color: rgb(237, 237, 237);
    padding: 5px 15px;
    font-weight: bold;
    margin: 10px 0px;
}

/* quitando margen en ul tag */
.item-list {
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
        }
    }
}

/* poniendole margen a las listas -------- */
.field-type-text-with-summary li {
    margin: 20px 0px;
}

b, strong { font-weight: 700 !important; }

p { word-wrap: break-word; }

/* ajuste layout ------------------------------ */
.region-main.col-sm-10 { float: none; margin: auto !important; }
@media (min-width: 769px) {
    .region-main.col-sm-10 {
        min-width: 1124px;
        max-width: 1300px;
    }
}

/* fix break en grilla de nodos -------------------------------------- */
@media (min-width: @screen-sm-min) {
    .col-sm-8 { width: 66.44444%; }
    .col-sm-4 { width: 33.3333333333%; }
}

/* Global - fuentes */
.field-name-field-icono { font-family: "cartel-urbano" !important; }
.field-name-post-date { font-family: @strait; }

/* side bar */
.region-right img { max-width: 100%; height: auto; }

/* mobile */
@media (max-width: @screen-sm-min) {

    body { font-size: 13px; }

    h2 { font-size: 26px; }
    h3 { font-size: 24px; }

    .cartel-block.col-xs-12 {
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 1px solid @gris;
    }

    /* global */
    .cartel-panel {
        .pane-cu-list-content-section-block-list-content-section {
            .pane-content {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }

}

/* STICKY CLASS
=================================================== */
#block-cu-menu-sections-block-menu-sections {
    .item-list {
        .transition(background-color 0.3s ease-in-out);
        background-color: white;
    }

    ul#menu-sections { .transition(padding 0.45s ease-in-out); }
}

/* sticky solo para desktop ===================== */
@media (min-width: @screen-sm-min) {

    body.sticky { padding-top: 94px; }

    .region-header-seccion.sticky {
        position: fixed;
        left: 0;
        right: 0;
        top: -82px;
        z-index: 9999;
        height: 0px;
        border-top: 0;

        height: 13%;
        #block-cu-menu-sections-block-menu-links-extras { right: 15px; top: 82px;}

        #block-cu-menu-sections-block-menu-sections {
            .item-list { background-color: @negro; }

            .cartel-logo-icon { .translate3d(0,0,0); }

            ul#menu-sections {
                padding-left: 60px;
                a { color: white; }
            }
        }

        #block-cu-menu-sections-block-menu-links-extras {
            .cartel_tv,
            .galerias,
            .edicion_impresa { a { color: white !important; } }
        }
        .main-logo-home{
            svg{
            filter:invert(100%);}
        }
    }

    .main-logo-home.sticky{
        position: fixed;
        top: -43px !important;
        svg{
            filter:invert(100%);
            width: 90%;
           // fill: @blanco;
        }
        img{

            max-width: 78% !important;
        }
    }

    .main-container > .row{


        width: 100% !important;
        float: none;
        margin: 0 !important;
    }
    .main-container > .row.full{
        //width: ~'calc(100% - 145px)';
        width: ~'calc(100% - 160px)' !important;
        float: right;
    }

    .footer.full{
        width: ~'calc(100% - 160px)' !important;
    }


} 
@media (max-width: 767px) and (min-width: 320px){
    .footer.full {
        /* padding-top: 0 !important; */
        width: 100% !important;
    }
    .row{/*margen por barra agenda - ed-impresa - carteltv para moviles*/
       /* margin-top: 99px;*/
    }
}
.region-header-seccion{
    height: 100%;
}
.main-logo-home{
    position: absolute;
    float: none;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    span{
        margin: 0 !important;
    }
}
.front .main-logo-home,
.page-ediciones-impresas .main-logo-home{
    //top: 125px;
    //top: 30px;
    svg{
        //width: 100%;
    }
}

/* Breadcrumbs
============================================================ */
#block-cu-breadcrumb-block-breadcrumb,
.pane-cu-breadcrumb-block-breadcrumb,
.pane-page-breadcrumb,
.breadcrumbs {
    padding: 10px 0;
    border-bottom: 1px solid @gris-oscuro;

    font-family: @strait;
    font-size: 13px;

    a {
        padding: 0 5px;
        margin: 0 5px;
        background-color: #d1d2d4;

        text-transform: uppercase;
        font-size: 15px;
        color: @negro;
    }
    a:nth-last-of-type(1) {
        background-color: #818285;
        color: white;
    }
}

@media (min-width: @screen-sm-min) {
    .two-8-4-column #block-cu-breadcrumb-block-breadcrumb {
        width: 81%;
        margin: auto;
        margin-bottom: 40px;
    }
}

.pane-cu-breadcrumb-block-breadcrumb { /* landing taxonomia */
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
}

.page-taxonomy .pane-page-breadcrumb {
    margin-bottom: 40px;
}

.page-carteltv .breadcrumbs {
    span { color: white; }
}

.node-type-video .breadcrumbs {
    border-bottom: none;
}

/* breadcrumb split --------------------------- */
.node-type-article #block-cu-breadcrumb-block-breadcrumb,
.node-type-gallery #block-cu-breadcrumb-block-breadcrumb {
    width: 81%;
    margin: auto;
}

/* breadcrumbs ocultos en moviles ===================== */
@media (max-width: @screen-sm-min) {
    .pane-page-breadcrumb,
    .breadcrumbs { .hidden; }
}

/* Paginador
============================================================ */
.text-center {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        top: 34px;
        left: 0;
        border-bottom: 1px solid @negro;
    }

    ul.pagination {
        position: relative;
        padding: 0 50px;
        background: white;
        z-index: 2;

        li {
            a {
                background-color: white;
                border-radius: 0;
                border-color: white;
                font-size: 11px;
                color: @negro;
            }
            &.active {
                a { border-color: @negro; }
            }
            &.next,
            &.prev {
                a {
                    text-transform: uppercase;
                    font-family: @strait;
                    letter-spacing: 5px;

                    &::after {
                        content:'v';
                        position: absolute;
                        display: block;
                        top: 5px;

                        background-color: white;
                        text-transform: none;
                        font-family: "cartel-urbano";
                    }
                }
            }

            &.next a::after {
                right: 0px;
            }

            &.prev a::after {
                content: 't';
                left: 0px;
            }
        }
    }
}

/* relacionados ============================================ */

/*.field-name-relacionado {
    background-color: @negro;
}
.two-8-4-column .region-bottom .pane-cartel-section-header .pane-title,
.one-column-bottom .pane-cartel-section-header .pane-title,
.field-name-relacionado > .field-items > .field-item.even > div:nth-of-type(1) {
    position: absolute;
    right: 0;
    top: -70px;
    left: 0;
    text-align: center;
    padding: 15px;
    font-size: 26px;
    font-family: @strait;
    font-weight: normal;
    color: white;
    background: @negro;
}*/

/* videos ============================================ */
.group-video-slider .contextual-links-region { position: static !important; }

/* #toolbar ====================== */
#toolbar { z-index: 1100; }

/* bocadillo landing de seccion ------------------------------------------ */
.node-bocadillo blockquote {
    display: block !important;
    visibility: visible !important;
    border-left: none;
    height: 290px;
    max-height: 290px;
    padding-top: 20%;
    text-align: right;
    font-family: @bebas;
    font-size: 27px;
    color: black;
}

/* Facebook comments hack ------------------------------------------ */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget span iframe[style] {
    min-width: 100% !important;
    width: 100% !important;
}


/* moviles
=============================================================== */
@media (max-width: @screen-sm-min) {

    /* ajusta padding ene le top */
    .node-type-article #block-system-main .two-8-4-column.cartel-panel.panel-display:nth-of-type(1),
    .node-type-article #block-system-main .two-8-4-column.cartel-panel.panel-display:nth-of-type(2) {
        padding-top: 15px;
    }

    /* breadcrumb split --------------------------- */
    .node-type-article #block-cu-breadcrumb-block-breadcrumb,
    .node-type-gallery #block-cu-breadcrumb-block-breadcrumb {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        border: none;
        background: rgb(240, 240, 240);

        font-size: 0;

        span::after { content: ': ' }

        span, a { font-size: 12px; }

        a:nth-of-type(1) { margin-left: 0; }
    }

    /* flexslider relacionados
    ------------------------------------------ */
/*(    .region-bottom{
        margin-top: 1em !important;
        .two-8-4-column .region-bottom .pane-cartel-section-header .pane-title,
        .one-column-bottom .pane-cartel-section-header .pane-title,
        .field-name-relacionado > .field-items > .field-item.even > div:nth-of-type(1) {
            position: static;
            top: 0;
            line-height: 24px;
            font-size: 23px;
        }
    })*/

    #related_content_slider .flex-viewport::before,
    #related_content_slider .flex-viewport::after { border: none !important; }
}


body.front{
    .field-name-field-patrocinado-por-{
        display: none;
    }  
}
@media(max-width: 768px){
    .field-name-field-patrocinado-por-{
        display: none;
    }      
}

// pie de foto imagenes
.rteright{
    &:before{
        content: "";
        display: block;
        width: 100%;
        height: 20px;       
    }
}

// only galerias
.node-type-gallery{
    .field-name-summary-body{
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 700;
        background: #f3f3f4;
        margin-right: 6%;
        .field-items{
            width: 63%;
            p{
                margin: 0;
            }
        }
    }
}
//Los eventos recomendados HORIZONTAL
a.class-img-catfish-banner{
   width: 100%;
   height: auto;
   max-height: 300px;
}

a.class-img-catfish-banner img{
   text-align: center;
   width: 100%;
   height: auto;
   max-height: 300px;
	margin-top:50px;	
}

#node_article_detalle_sidebar_group_eventos_recomendados{
	width: 100%;
	.view-nodequeue-8{
		.views-row{
			width: 33%;
			display: inline-block;
			vertical-align: top;
			height: 195px !important;
			.field-name-field-fecha{
				position: absolute;
				bottom: 209px;
				width: 44%;
				background: linear-gradient(-121deg, transparent 29px, #07b9e3 0);
				height: 32px;
				a{
					color: #FFF;
					position: relative;
					top: 4px;
					left: 16px;
					font-weight: bold;
				}
			}
			#node_eventos_eventos_recomendados_group_abajo_galeria_popular{
				height: 150px;				
				.field-name-title{
					height: 50px;
					position: relative;
					bottom: 38px;
				}
				h2{	
					    font-size: 19px;
					a{
						color: gray;
					}
				}
				#node_eventos_eventos_recomendados_group_event_more_info{
					width: 91%;
					margin-left: 5%;
					position: relative;
					border-bottom: gray solid 1px;
					margin: 0 0;
					padding-right: 28px;
					bottom: 40px;
					div{
						display: inline-block;
						vertical-align: middle;
						width: 49%;
					}
					.field-name-field-tipo-de-evento{
						color: #07b9e3;
						font-weight: bold;
					}
					.field-name-field-precio{
						text-align: right;
						a, a:hover, a:active{
							color: #808080;
						}
					}
				}
			}
		}
	}
}


//Eventos Recomendados vertical
.field-name-eventos-recomendados-derecha{
	width: 100%;
	.view-nodequeue-8{
		.views-row{
			height: 206px !important;
			/*border: solid 1px #e0d8d8;
			-webkit-box-shadow: 6px 12px 22px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
			-moz-box-shadow: 6px 12px 22px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
			box-shadow: 6px 12px 22px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;*/
			.field-name-field-fecha{
				position: absolute;
				bottom: 180px;
				width: 35%;
				background: linear-gradient(-121deg, transparent 29px, #07b9e3 0);
				height: 32px;
				a{
					color: #FFF;
					position: relative;
					top: 4px;
					left: 16px;
					font-weight: bold;
				}
			}
			#node_eventos_eventos_recomendados_group_abajo_galeria_popular{
				height: 150px;				
				.field-name-title{
					height: 50px;
					position: relative;
					bottom: 10px;
				}
				h2{	
					font-size: 19px;
margin-left: 8px;
    padding-top: 8px;
					a{
						color: gray;
					}
				}
				#node_eventos_eventos_recomendados_group_event_more_info{
					width: 95%;
					margin-left: 3%;
					position: relative;
					border-bottom: gray solid 1px;
					bottom: 20px;
					padding-right: 5%;
					div{
						display: inline-block;
						vertical-align: middle;
						width: 49%;
					}
					.field-name-field-tipo-de-evento{
						color: #07b9e3;
						font-weight: bold;
						text-transform: uppercase;
						font-size: 14px;
					}
					.field-name-field-precio{
						text-align: right;
						a, a:hover, a:active{
							color: #808080;
						}
					}
				}
			}
		}
	}
}

//Eventos recomendados títulos
#node_article_detalle_sidebar_group_eventos_recomendados_d > h3,
#node_article_detalle_sidebar_group_eventos_recomendados >	h2{
	background-color: #07b9e3;
	color: #FFF;
	font-size: 30px;
	padding:5px 0px 5px 5px;
	text-transform: uppercase;
    margin-bottom: 0px;
	&::before{
		content: "H";
		font-family: "cartel-urbano";
		margin-right:8px;
	}
}

///Temporal
/*#node_article_detalle_sidebar_group_eventos_recomendados_d,
#node_article_detalle_sidebar_group_eventos_recomendados{
display:none !important;
}*/

//Mobile recommended events
@media only screen and (max-width: 767px) {
	//Titles
	#node_article_detalle_sidebar_group_eventos_recomendados_d>h3,
	#node_article_detalle_sidebar_group_eventos_recomendados>h2{
		font-size: 27px;
	}

	//Mobile horizontal
	#node_article_detalle_sidebar_group_eventos_recomendados{
		.views-row{
			width: 100% !important;
			.field-name-field-fecha{
				bottom: 180px !important;
			}
			#node_eventos_eventos_recomendados_group_abajo_galeria_popular{
				.field-name-title{
					bottom: 8px !important;
				}
				#node_eventos_eventos_recomendados_group_event_more_info{
					bottom: 52px !important;
				}
			}
		}
	}
	
//Vertical
	.field-name-eventos-recomendados-derecha{
		.views-row {
			#node_eventos_eventos_recomendados_group_abajo_galeria_popular{
				#node_eventos_eventos_recomendados_group_event_more_info{
					width: 83%;
					.field-name-field-precio{
						position: relative !important;
					    left: 26px !important;
					    text-align: center !important;
					}
				}
			}
			.field-name-field-fecha{
				a{
    				left: -16px !important; 
				}
			} 
		}
	} 
.field-name-eventos-recomendados-derecha .view-nodequeue-8 .views-row #node_eventos_eventos_recomendados_group_abajo_galeria_popular h2{
    text-align: left;
}

//Correciones home mobile

.field-name-summary-body{
	//display: none !important;
}
.view-nodequeue-5 .taxonomy-term-noticias .field-name-title h1, .pane-cu-list-content-section-block-list-content-section .taxonomy-term-noticias .field-name-title h1, .view-ver-mas-home .taxonomy-term-noticias .field-name-title h1, .view-sliderhome .taxonomy-term-noticias .field-name-title h1{
	font-size: 27px !important;
}

.view-sliderhome .views-row-1 img{
	min-height: 230px !important;
}

.view-sliderhome{
	margin-top: 31%;
}


#node_article_detalle_sidebar_group_eventos_recomendados .view-nodequeue-8 .views-row #node_eventos_eventos_recomendados_group_abajo_galeria_popular #node_eventos_eventos_recomendados_group_event_more_info {
    width: 91%;
    margin-left: 5%;
    position: relative;
    border-bottom: gray solid 1px;
    margin: 0 0;
    padding-right: 28px;
    bottom: 52px !important;
}


#node_article_detalle_sidebar_group_eventos_recomendados_d{
display: none !important;
}



}



