.region-header-seccion { position: relative; }

/* header menu - login, registro
=================================================== */
#block-cu-menu-links-user-bloque-links-usuario {
   position: relative;
   height: 22px;
   margin: 0 -15px;
   padding-left: 10px;
   padding-right: 10px;
   background-color: rgb(63, 63, 63);
   z-index: 1000;

   .menu_links_usuario ul {
      margin: 0;
      text-align: right;
      list-style: none;
   }
   .menu_links_usuario ul li {
      display: inline-block;
      padding: 0 10px;
      font-family: @strait;
      font-size: 12px;

      a { color: white; }

      a:hover { color: @azul; }
   }
}

/* header menu - Sidebar
=================================================== */
/* scroll ---------------- */
#block-system-main-menu .ps-container .ps-scrollbar-y {
   background-color: #0086A6;
}
/* main ----------------- */
#block-system-main-menu {
   position: fixed;
   display: block;
   width: 0px;
   height: 0px;
   /*width: 160px;
   max-width: 160px;
   height: 100%;*/
   float: left;
   margin-top: 40px;
   background: @negro;
   transition: all linear 0.1s;
   z-index: 200;
   //.transition(transform 0.45s ease);
   .translate3d(0,0,0);
   &.inactive { 
      transition: all linear 0.1s;
      width: 160px;
      max-width: 160px;
      height: 100%;
      overflow: hidden;
   }

   /* oculto permanente */
   .menu-attach-block-drop-link { .hidden; }

   /* cerrar submenu ============================ */
   .cerrar-submenu {
      display: none;
      position: absolute;
      right: -40px;
      top: 10px;
      font-size: 30px;
      line-height: 30px;
      color: white !important;
      cursor: pointer;
      border: solid 2px;
      border-radius: 20px;
      width: 30px;
      height: 32px;
      padding: 0 6px;
   }

   /* header ==================================== */
   div.header {
      position: relative;
      background-color: white;
      height: 150px;
      padding: 0 15px;
      z-index: 1000;

      div.logo-sidebar {
         position: relative;
         display: block;
         background-color: @negro;
         text-align: center;
         padding: 35px 0;

         &::before {
            content: 'X';
            position: absolute;
            display: block;
            top: 10px;
            right: 10px;
            color: white;
            cursor: pointer;
         }

         &::after {
            content:'m';
            position: absolute;
            display: block;
            width: 100%;
            bottom: -28px;
            left: 0;

            text-align: center;
            font-family: "cartel-urbano";
            font-size: 30px;
            color: @negro;
         }
      }
   }

   /* menu lateral */
   ul.menu.nav {
      li{
         border-bottom: solid 1px #373637;
      }
      li.first > a{
         display: none;
      }
      li:hover{
         a{
            color: black;
         }
      }
      div, a, p{
         font-family: 'bebas_neueregular';
         color: #8c8c8b;
      }
      /* boton */
      background: @negro;
      #block-cu-home-redes-menu-redes-sociales-menu{
         z-index: 0;
         max-width: 100%;
         top: inherit;
         position: static;
         padding: 0px;
         background: black;
         .redes{
            &>div{
               display: inline-block;
               padding: 0 5px;
               a{
                  color: white;
               }
            }
         }
      }
   }

   #block-block-17{
      width: 100%;
      border: 1px solid #000;
      background-color: #191919;
      border: none;
      transform: translateY(-40px);
      top: 25px;
      p{
         display: inline-block;
         img{
            width: 109% !important;
            height: auto !important;
            position: relative;
            left: 50%;
            transform: translate(-50%);
         }
      }
   }
}

/* header menu top 
=================================================== */
#block-cu-menu-general-block-menu-links-redes {
   .clearfix();
   position: relative;
   margin-left: -15px;
   margin-right: -15px;
   //padding: 5px 0;
   padding-right: 15px;
   background-color: rgba(0,0,0,1);

   height: 43px;

   form { margin-bottom: 0; }

   .container_info_site { float: left; }
   .container_link_redes { float: right; }

   /* LEFT CONTAINER - LOGO, SLOGAN */
   .container_info_site {
      > a,
      .lema_site { float: left; }

      .logo_site {
         display: block;
         //max-width: 180px;
         margin-left: 70px;
         padding: 2px 0px;
         img, svg {
            position: relative;
            left: 50%;/*ajuste menu desplegado*/
            transform: translate(-50%);
            transition: all ease-out 0.3s;
            width: 61%;
            top: 30px;
            @media (max-width: @screen-sm-min) {
               top: 0;
               left: 56% !important;
               fill: @blanco;
               width: 100%;
            }
         }
      }
      .full-logo svg {/*ajuste menu contraido*/
         left: 78%
      }

      .lema_site {
         height: 43px;
         margin-left: 55px;
         padding-left: 40px;
         //border-left: 1px solid white;

         line-height: 50px;
         font-family: @bebas;
         font-size: 22px;
         color: white;
         transform: translateX(-69px);
      }

      /* icono */
      .abrir-menu {/*btn movil*/
         opacity: 0;
         cursor: pointer;
         @media (max-width: 768px) {
            opacity: 1;
         }
         @media (min-width: 768px) {
            &::before{
               // background-image: url(../img/menos.png);
            }
         }
         &::before{
            cursor: pointer;
            content: " ";
            position: absolute;
            width: 30px;
            height: 30px;
            background-size: cover;
            top: 12px;
            left: 23px;
            transform: rotate(0deg);
            transition: transform linear 0.2s;
            background-image: url(../img/mas.png);
         }
         &.sticky{
         }
      }
      .icon-cartel-ico-menu.icon-cartel-ico-menu-toggle{
         &::before{
            background-image: url(../img/menos.png);
            opacity: 1;
            transition: transform linear 0.2s;
            transform: rotate(180deg);
         }
      }
   }

   /* RIGHT CONTAINER - FORM, SOCIAL, TOP, EDICION IMPRESA */
   .container_link_redes {
      > div { float: left; position: relative; }

      input#edit-search-block-form--2,
      input#edit-mergevars-email {
         height: 25px;
         border-radius: 2px;
         outline: none;
      }

      /* newsletter */
      .newslleter {
         overflow: hidden;
         width: 20px;
         height: 50px;
         .transition(width 0.45s ease-in-out);

         &:hover { width: 240px; }

         &::before {
            content: "\2709";
            display: block;
            height: 50px;
            float: left;
            margin-right: 10px;

            line-height: 50px;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: normal;
            color: white;

            cursor: pointer;
         }
      }

      .newslleter form {
         position: absolute;
         width: 240px;
         left: 25px;
         top: 0;

         .btn-primary,
         p { .hidden; }
         > div {
            display: table;
            height: 50px;

            #mailchimp-newsletter-67829-mergefields {
               display: table-cell;
               vertical-align: middle;

               .form-type-textfield { display: table-cell; }
            }
         }
      }

      /* buscador */
      .buscador {
         overflow: hidden;
         width: 240px;
         height: 50px;
         margin-left: 8px;
         .transition(width 0.45s ease-in-out);


         &::before {
            display: block;
            float: left;
            height: 50px;
            padding-left: 10px;
            padding-right: 10px;
            margin-right: 20px;

            line-height: 50px;
            color: white;

            cursor: pointer;
         }
         form {
            position: absolute;
            width: 240px;
            left: 35px;
            top: 0;

            &::before {
               content:'';
               position: absolute;
               display: block;
               height: 15px;
               top: 17px;
               left: -35px;
               border-left: 1px solid white;
            }

            > div {
               display: table;
               height: 50px;

               > div, .input-group {
                  display: table-cell;
                  vertical-align: middle;
               }

               .input-group span { .hidden; }

               .input-group .form-control { width: 200px; }
            }
         }
      }
      .icon-cartel-08{
         width: 20%;
         position: absolute;
         display: inline-block;
         right: 29px;
         @media (max-width: 1366px) {
            right: 50px;
         }
         #search-block-form{
            width: 100%;
            &::before{
               content: none;
            }
            #edit-search-block-form--2{
               width: 322px;
               top: -7px;
               outline: none;
               font-size: 16px;
               text-transform: lowercase;
               font-style: italic;
               &:hover, &:active, &:focus{
                  outline: none;
               }
            }

         }
         &::before{
            //position: fixed;
            position: absolute;
            right: 15px;
            z-index: 3;
            margin: 0;
            padding: 0;
         }
      }

      /* social */
      .redes {
         position: relative;
         padding-left: 7px;
         margin-left: 5px;
         margin-right: 10px;

         &::before {
            content:'';
            position: absolute;
            display: block;
            height: 15px;
            left: 0;
            top: 17px;
            border-left: 1px solid white;
         }

         > div { float: left; margin: 0 3px; }

         a {
            display: block;
            height: 50px;
            line-height: 50px;
            font-size: 22px;
            color: white;

            &::before { line-height: 50px; }
         }
      }

      .ver_por,
      .edicion_impresa {
         position: relative;
         width: 70px;
         height: 50px;
         padding-top: 6px;

         text-align: center;
         line-height: 4px;
         font-size: 11px;
         color: white;
      }

      /* ver por */
      .ver_por {
         border-left: 1px solid white;
         border-right: 1px solid white;

         cursor: pointer;

         &:hover {
            #menu-type-article {
               .translate3d(0,0,0);
            }
         }

         &::before {
            display: block;
            font-size: 26px;
         }

         #menu-type-article {
            .transition(transform 0.35s ease-in);
            .translate3d(200%,0,0);
            position: absolute;
            width: 150px;
            padding: 15px 0;
            right: -82px;
            top: 50px;

            background-color: @negro;
            z-index: 9999;

            text-align: left;
            line-height: 20px;
            font-size: 14px;

            a {
               color: white;
            }

            li {
               padding: 10px;

               &:hover {
                  background-color: #353535;
               }
            }
         }
      }

      /* edicion impresa */
      .edicion_impresa {
         a {
            display: block;
            color: white;

            &::before {
               display: block;
               font-size: 26px;
            }
         }
      }
   }
}

/* header menu bottom
=================================================== */
#block-cu-menu-sections-block-menu-sections {
   position: relative;

   &:hover {
      #div_sub_menu_section {
         .translate3d(0,0,0);
      }
   }

   .item-list {
      .clearfix();
      height: 51px;
      margin-left: -15px;
      margin-right: -15px;
      padding: 5px 0;
      border-bottom: 1px solid @gris;
      background-color: white;
   }

   .cartel-logo-icon {
      position: absolute;
      width: 42px;
      height: 50px;
      top: 1px;
      left: 0;
      background: url(../img/header_icon.png) no-repeat center center;
      background-color: white;

      .transition(transform 0.45s ease-in-out);
      .translate3d(-100px,0,0);

      a { display: block; width: 100%; height: 50px; }
   }

   ul#menu-sections {
      position: relative;
      li {
         position: relative;
         float: left;
         padding: 0 15px;

         text-transform: uppercase;
         line-height: 40px;
         font-size: 24px;
         font-family: @bebas;
         color: @negro;

         a { color: @negro; }

         .ajax-progress {
            position: absolute;
            top: 7px;
            left: 0px;

            font-size: 10px;
            color: @negro;
         }
      }
   }
}

/* header menu bottom - submenu =================== */
#div_sub_menu_section {
   position: absolute;
   overflow: hidden;
   height: auto;
   min-height: 160px;
   max-height: 210px;
   top: 51px;
   left: -15px;
   right: -15px;
   border-top: 2px solid @azul;
   border-bottom: 2px solid @azul;
   z-index: -1;
   background-color: white;

   .transition(transform 0.45s ease-out);
   .translate3d(0,-250px,0);

   &:hover {
      .translate3d(0,0,0);
   }

   /* ocultamos blockquotes del menu ========================== */
   blockquote { .hidden; }

   #flexslider-items-submenu {
      width: 66.66666666%;
      min-height: 165px;
      margin: auto;

      .flex-viewport {
         overflow: visible !important;
         min-height: 165px;

         &::after,
         &::before {
            content: '';
            position: absolute;
            width: 50%;
            min-height: 100%;
            top: 0;
            bottom: 0;

            background-color: rgba(255,255,255,0.7);
            z-index: 2;
         }

         &::before { left: -50%; border-right: 6px solid white; }
         &::after { right: -50%; border-left: 6px solid white; }

         /* stilos desktop */
         @media (min-width: @screen-sm-min) {
            &::before,
            &::after {
               background-color: rgba(0,0,0,0.7);
               border: none;
            }
         }

      }

      h2 {
         font-size: 18px;
      }

      ul {
         margin: 0;
         padding-left: 0;
         padding-right: 0;
         list-style: none;
      }

      ul.slides {
         .clearfix();
         padding-top: 25px;

         li {
            > div.node {
               float: left;
               width: 25%;
               min-height: 160px;
               padding: 0 30px;
               border-left: 1px solid @azul;

               /* grupo imagen, icono */
               .group-imagen-submenu {
                  position: relative;
                  /* imagen */
                  img { width: 100%; height: auto; }
                  /* icono tipo de contenido */
                  .field-name-field-tipo {
                     position: absolute;
                     width: 50px;
                     height: 50px;
                     top: 50%;
                     left: 50%;
                     margin-left: -25px;
                     margin-top: -25px;

                     text-shadow: 0 0 5px #1F1F1F;
                     text-align: center;
                     line-height: 50px;
                     font-size: 50px;
                     color: white;
                  }
               }
               /* titulo */
               .field-name-title {}
            }
         }
      }

      .flex-direction-nav {

         li a { opacity: 1 !important; }

         a.flex-prev { left: 0; }
         a.flex-next { right: 0; }
      }
   }
}


/* header menu extras
=================================================== */
.region-header-seccion.sticky #block-cu-menu-sections-block-menu-links-extras{
   background: rgb(0, 0, 0);
   top: 83px;
}
#block-cu-menu-sections-block-menu-links-extras {
   position: absolute;
   right: 0;
   //top: 83px;/*altura anterior*/

   top: 43px;
   width: 100%;
   background: white;

   .clearfix();
   .container_link_general {
      .clearfix();
      float: right;
      > div { float: left; }
      .abrir-menu{
         /*position: absolute;
         left: 60px;
         top: 10px;
         cursor: pointer;
         vertical-align: middle;
         color: #000;
         font-family: 'bebas_neueregular';
         font-size: 20px;*/
      }
      .abrir-menu.sticky{
         /*position: absolute;
         left: 60px;
         top: 10px;
         cursor: pointer;
         vertical-align: middle;
         color: @blanco;
         font-family: 'bebas_neueregular';
         font-size: 20px;*/
      }

      .cartel_tv,
      .galerias,
      .edicion_impresa {
         a {
            display: block;
            height: 40px;
            padding: 0 15px;

            text-align: center;
            line-height: 5px;
            font-size: 11px;
            color: @negro;

            &::before {
               display: block;
               font-size: 29px;
            }
         }
      }


      .facebook,.twitter,.instagram, .carteltv{
          width: 28px;
          margin: 5px 9px;
          img{
              width: 100%;
          }
      }
       .carteltv{
           width: 30px;
       }



      .galerias a { border-left: 1px solid @negro; }

      .agenda {
         a {
            display: block;
            height: 40px;
            margin-right: -15px;
            margin-top: 0px;
            padding-left: 15px;
            padding-right: 15px;

            background-color: @azul;
            font-family: @bebas;
            font-size: 24px;
            color: white;
            padding-right: 28px;
            line-height: 1.8;

            &::before {
               display: inline-block;
               vertical-align: middle;
               padding-right: 15px;
               font-size: 30px;
            }
         }
      }
   }
}
@media(min-width: 768px){
    .page-node-{
        #block-cu-menu-sections-block-menu-links-extras {
            position: relative;
            top: 93px;
        }  
        #block-cu-menu-general-block-menu-links-redes{
           // top: 10px;
        }
        section.col-sm-12 ul.tabs--primary.nav.nav-tabs{
            //padding-top: 60px;
        }
        #block-system-main{
           // padding-top: 40px;
        }
    }
}

/* header sticky menu 
=================================================== */
/* sticky solo para desktop ===================== */
@media (min-width: @screen-sm-min) {
   .page-node #block-cu-menu-sections-block-menu-sections {
      .item-list { background-color: @negro; }
      .cartel-logo-icon { .translate3d(0,0,0); }

      ul#menu-sections {
         padding-left: 60px;
         a { color: white; }
      }
   }

   .page-node #block-cu-menu-sections-block-menu-links-extras {
      .cartel_tv,
      .galerias,
      .edicion_impresa {
         color: #000;
         a {
            color: #000 !important;
         }
      }
   }

   .region-header-seccion {
      #block-system-main-menu{
         overflow: auto;
         overflow-x: hidden;
         //margin-top: 0px;
         position: fixed;
         top: 0;
         left: 0px;
         .redes{
            padding-top: 5px;
         }

         body {
            scrollbar-face-color: slategray;
            scrollbar-track-color: #b8c0c8;
         }
         ul.menu{
            margin-top: 41px;
            /*ajuste menu diseño*/
            li.edicion-impresa {
               a{
                  color: @blanco;
                  letter-spacing: 4px;
                  text-align: center;
                  &:hover{
                     background: none;
                  }
               }
               &:hover{
                  background: none;
                  color: @blanco;
               }
            }
            li.proteccion-datos{
               transform: translateY(172px);/*tamaño de img edicion*/
               z-index: 9999;
               a{
                  font-size: 14px;
                  color: @blanco;
                  text-align: center;
                  padding-bottom: 3px;
                  &:hover{
                     color: @negro;
                  }
               }
            }
         }
      }


      #block-system-main-menu::-webkit-scrollbar{
         width: 10px;  /* for vertical scrollbars */
         height: 10px; /* for horizontal scrollbars */
      }

      #block-system-main-menu::-webkit-scrollbar-track{
         background: rgba(0, 0, 0, 0);
      }

      #block-system-main-menu::-webkit-scrollbar-thumb{
         background: rgb(57, 56, 56);
         border-radius: 10px;
      }
   }
}

.region-header-seccion.sticky{
   #block-system-main-menu{
      margin-top: 0px;
   }
}
.page-taxonomy-term {
   .container_info_site .logo_site img {
      max-width: 78% !important;

   }
}
/* Z-INDEXES
=============================================== */
#block-system-main-menu 						{ z-index: 10; }
#block-cu-menu-general-block-menu-links-redes 	{ z-index: 1000; }
#block-cu-menu-sections-block-menu-links-extras { z-index: 600; }
#block-cu-menu-sections-block-menu-sections		{ z-index: 500; }


/*
desktop
=========================================*/

#page-header-seccion{
   .region-header-seccion{
      .block-cu-menu-sections{
         .icon-cartel-ico-menu{
            cursor: pointer;
            &::before{
               content: " ";
               position: absolute;
               width: 40px;
               height: 40px;
               top: 44px;
               left: 53px;
               background-image: url(../img/mas.png);
               background-repeat: no-repeat;
               background-size: cover;
               transform: rotate(0deg);
               transition: all linear 0.2s;
               opacity: 1;
               @media (max-width: 768px) {
                  opacity: 0;
               }
            }
         }
         .icon-cartel-ico-menu.icon-cartel-ico-menu-toggle{
            &::before{
               background-image: url(../img/menos.png);
               left: 179px;
               transform: rotate(180deg);
               transition: all linear 0.2s;
            }
         }
      }
   }
}




/*
posicion menu lateral logged-in
=============================================*/
body.logged-in.admin-menu{
   .region-header-seccion{
      #block-cu-menu-sections-block-menu-links-extras{/*barra superior*/
         // margin-top: 30px;
      }
      #block-system-main-menu{/*barra lateral*/
         margin-top: 75px;
      }
   }
   .region-header-seccion.sticky{/*posicion sticky*/
      #block-system-main-menu{
         margin-top: 0;
         // margin-top: 29px;
      }
   }
}/*/body*/

/*posicion menu menu lateral body page-node*/
body.logged-in.admin-menu.page-node{
   .region-header-seccion{
      #block-system-main-menu{/*barra lateral*/
         margin-top: 0px;
      }
   }
}/*/body*/




