/* COLORES ESPECIFICOS PARA MUSICA
======================================================= */
.taxonomy-term-música {
	.field-name-field-icono .field-items::after {
		background-color: @color-musica !important;
	}

	/* colores - slider cartel-tv, galerias home ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-musica !important;
	}
}

.Música::after { background-color: @color-musica !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-música .field-name-field-icono {
		background-color: @color-musica !important;
	}
}


/* COLORES ESPECIFICOS PARA DEPORTES
======================================================= */
.taxonomy-term-deportes {
	.field-name-field-icono .field-items::after {
		background-color: @color-deportes !important;
	}

	/* colores slider cartel-tv ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-deportes !important;
	}
}

.Deportes::after { background-color: @color-deportes !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-deportes .field-name-field-icono {
		background-color: @color-deportes !important;
	}
}


/* COLORES ESPECIFICOS PARA arte
======================================================= */
.taxonomy-term-arte {
	.field-name-field-icono .field-items::after {
		background-color: @color-arte !important;
	}

	/* colores slider cartel-tv ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-arte !important;
	}
}

.Arte::after { background-color: @color-arte !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-arte .field-name-field-icono {
		background-color: @color-arte !important;
	}
}

/* COLORES ESPECIFICOS PARA noticias
======================================================= */
.taxonomy-term-noticias {
	.field-name-field-icono .field-items::after {
		background-color: @color-noticias !important;
	}

	/* colores slider cartel-tv ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-noticias !important;
	}
}

.Noticias::after { background-color: @color-noticias !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-noticias .field-name-field-icono {
		background-color: @color-noticias !important;
	}
}

/* COLORES ESPECIFICOS PARA sexo
======================================================= */
.taxonomy-term-sexo {
	.field-name-field-icono .field-items::after {
		background-color: @color-sexo !important;
	}

	/* colores slider cartel-tv ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-sexo !important;
	}
}

.Sexo::after { background-color: @color-sexo !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-sexo .field-name-field-icono {
		background-color: @color-sexo !important;
	}
}

/* COLORES ESPECIFICOS PARA opinion
======================================================= */
.taxonomy-term-opinión {
	.field-name-field-icono .field-items::after {
		background-color: @color-opinion !important;
	}

	/* colores slider cartel-tv ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-opinion !important;
	}
}

.Opinión::after { background-color: @color-opinion !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-opinión .field-name-field-icono {
		background-color: @color-opinion !important;
	}
}

/* COLORES ESPECIFICOS PARA diseño
======================================================= */
.taxonomy-term-diseño {
	.field-name-field-icono .field-items::after {
		background-color: @color-diseno !important;
	}

	/* colores slider cartel-tv ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-diseno !important;
	}
}

.Diseño::after { background-color: @color-diseno !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-diseño .field-name-field-icono {
		background-color: @color-diseno !important;
	}
}

/* COLORES ESPECIFICOS PARA historia
======================================================= */
.taxonomy-term-historias {
	.field-name-field-icono .field-items::after {
		background-color: @color-historias !important;
	}

	/* colores slider cartel-tv ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-historias !important;
	}
}

.Historias::after { background-color: @color-historias !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-historias .field-name-field-icono {
		background-color: @color-historias !important;
	}
}

/* COLORES ESPECIFICOS PARA moda
======================================================= */
.taxonomy-term-moda {
	.field-name-field-icono .field-items::after {
		background-color: @color-moda !important;
	}

	/* colores slider cartel-tv ======================= */
	#node_video_teaser_group_textos_video_izq,
	.group-textos-galerias-der {
		border-color: @color-moda !important;
	}
}

.Moda::after { background-color: @color-moda !important; }

/* COLORES ROTADOR DE VIDEOS ============================ */
.pane-cu-rotator-videos-ctv-home-block-rotator-videos-ctv-home {
	.taxonomy-term-moda .field-name-field-icono {
		background-color: @color-moda !important;
	}
}



