/* ver por
====================================================== */
.one-column-top .pane-taxonomy-term {
	margin: 40px 0;
	.taxonomy-term {
		.clearfix();

		position: relative;
		width: 30%;
		margin: auto;
		text-align: center;

		> h2, .content { display: inline-block; }

		> h2 { font-size: 75px; }

		.field-label { .hidden; }

		.content {
			.field-items {
				position: absolute;
				width: 50px;
				height: 50px;
				right: 0;
				top: 25px;
				z-index: 2;

				text-align: center;
				line-height: 50px;
				font-size: 45px;
				color: white;

				&::before {
					content:'';
					position: absolute;
					width: 70px;
					height: 70px;
					left: 50%;
					top: 50%;
					margin-left: -35px;
					margin-top: -35px;
					z-index: -1;

					background-color: @azul;

					.rotate(45deg);
				}
			}
		}
	}
}