/* COLORES ESPECIFICOS PARA MUSICA
======================================================= */
.page-taxonomy-term-1 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-musica; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-musica; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-musica; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-musica; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-musica; }
}

/* COLORES ESPECIFICOS PARA DEPORTES
======================================================= */
.page-taxonomy-term-2 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-deportes; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-deportes; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-deportes; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-deportes; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-deportes; }
}

/* COLORES ESPECIFICOS PARA arte
======================================================= */
.page-taxonomy-term-3 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-arte; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-arte; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-arte; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-arte; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-arte; }
}

/* COLORES ESPECIFICOS PARA noticias
======================================================= */
.page-taxonomy-term-4 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-noticias; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-noticias; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-noticias; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-noticias; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-noticias; }
}

/* COLORES ESPECIFICOS PARA sexo
======================================================= */
.page-taxonomy-term-5 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-sexo; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-sexo; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-sexo; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-sexo; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-sexo; }
}

/* COLORES ESPECIFICOS PARA opinion
======================================================= */
.page-taxonomy-term-6 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-opinion; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-opinion; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-opinion; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-opinion; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-opinion; }
}

/* COLORES ESPECIFICOS PARA diseño
======================================================= */
.page-taxonomy-term-7 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-diseno; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-diseno; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-diseno; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-diseno; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-diseno; }
}

/* COLORES ESPECIFICOS PARA historia
======================================================= */
.page-taxonomy-term-9 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-historias; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-historias; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-historias; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-historias; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-historias; }
}

/* COLORES ESPECIFICOS PARA moda
======================================================= */
.page-taxonomy-term-10 {
	.cabezote-seccion .titulo-seccion span.cartel-icono::after { background-color: @color-moda; }
	.cartel-block .group-textos-articulo .field-name-field-icono { background-color: @color-moda; }
	.cartel-block .group-textos-articulo .group-textos-articulo-der { border: 1px solid @color-moda; }

	/* Destacado seccion ================ */
	.pane-nodequeue-1 .group-textos-destacado { border: 1px solid @color-moda; }

	/* social share ================= */
	.field-name-social-network span.count_total { background-color: @color-moda; }
}


/* NODOS PAG INTERNAS
======================================================= */

.page-taxonomy{
   .pane-block{
      //una col
      .view-mode-destacados_home_general{
         width: 31%;
         margin: 11px !important;
         box-shadow: 5px 5px 20px 0px rgba(50, 50, 50, 0.77);
         display: inline-block;
      }
      //dos coll
      .view-mode-card_destacado_home{
         .view-mode-destacados_home_general;
         width: 63.5% ;
      }
   }
   .div_more{
      display: none;
   }
}
@media (max-width: 1275px) and (min-width: 320px) {
  .node-type-gallery .field-name-fblikebutton-ds-field{
      position: static;
      width: 80px;
      display: block;
      margin: 0 auto;
      text-align: center;
  }
}

@media (max-width: 767px) and (min-width: 320px) {
   /*---------------------------nodos-------------------------*/
   .field-name-fblikebutton-ds-field{
       position: static;
       width: 80px;
       display: block;
       margin: 0 auto;
       text-align: center;
   }
   .pane-block{
      //una col
      .view-mode-destacados_home_general{
         width: 100% !important;
         margin: 0 !important;
         margin-top: 0 !important;

      }
      //dos coll
      .view-mode-card_destacado_home{
         .view-mode-destacados_home_general;
         width: 93% !important;
      }
   }

}/*/mediaq*/
