/* Lato, via google fonts */
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);



/* bebas */
@font-face {
    font-family: 'bebas_neueregular';
    src: url('../fonts/bebas/BebasNeue-webfont.eot');
    src: url('../fonts/bebas/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebas/BebasNeue-webfont.woff') format('woff'),
         url('../fonts/bebas/BebasNeue-webfont.ttf') format('truetype'),
         url('../fonts/bebas/BebasNeue-webfont.svg#bebas_neueregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bebas_neuebook';
    src: url('../fonts/bebas/BebasNeue-Book.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family: 'bebas_neuelight';
    src: url('../fonts/bebas/BebasNeue-Light.otf');
    font-weight: normal;
    font-style: normal;
}


/* strait */
@font-face {
    font-family: 'Strait-Regular';
    src: url('../fonts/strait/Strait-Regular.eot');
    src: url('../fonts/strait/Strait-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/strait/Strait-Regular.woff') format('woff'),
         url('../fonts/strait/Strait-Regular.ttf') format('truetype'),
         url('../fonts/strait/Strait-Regular.svg#strait') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* iconos */
@font-face {
  font-family: "cartel-urbano";
  src:url("../fonts/iconos/cartel-urbano.eot");
  src:url("../fonts/iconos/cartel-urbano.eot?#iefix") format("embedded-opentype"),
    url("../fonts/iconos/cartel-urbano.woff") format("woff"),
    url("../fonts/iconos/cartel-urbano.ttf") format("truetype"),
    url("../fonts/iconos/cartel-urbano.svg#cartel-urbano") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "cartel-urbano" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="cartel-icono"]:after,
[class*="cartel-icono"]:after {
  position: absolute;
  content: "";
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  transform: rotate(45deg) scale(0.8);
  z-index: -1;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  position: relative;
  font-family: "cartel-urbano" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 2;
}

.icon-cartel-01:before {
  content: "a";
}
.icon-cartel-02:before {
  content: "b";
}
.icon-cartel-03:before {
  content: "c";
}
.icon-cartel-04:before {
  content: "d";
}
.icon-cartel-05:before {
  content: "e";
}
.icon-cartel-06:before {
  content: "f";
}
.icon-cartel-07:before {
  content: "g";
}
.icon-cartel-08:before {
  content: "h";
}
.icon-cartel-09:before {
  content: "i";
}
.icon-cartel-10:before {
  content: "j";
}
.icon-cartel-12:before {
  content: "k";
}
.icon-cartel-11:before {
  content: "l";
}
.icon-cartel-13:before {
  content: "m";
}
.icon-cartel-26:before {
  content: "n";
}
.icon-cartel-25:before {
  content: "o";
}
.icon-cartel-24:before {
  content: "p";
}
.icon-cartel-23:before {
  content: "q";
}
.icon-cartel-22:before {
  content: "r";
}
.icon-cartel-21:before {
  content: "s";
}
.icon-cartel-20:before {
  content: "t";
}
.icon-cartel-19:before {
  content: "u";
}
.icon-cartel-18:before {
  content: "v";
}
.icon-cartel-17:before {
  content: "w";
}
.icon-cartel-16:before {
  content: "x";
}
.icon-cartel-15:before {
  content: "y";
}
.icon-cartel-14:before {
  content: "z";
}
.icon-cartel-27:before {
  content: "A";
}
.icon-cartel-28:before {
  content: "B";
}
.icon-cartel-29:before {
  content: "C";
}
.icon-cartel-30:before {
  content: "D";
}
.icon-cartel-31:before {
  content: "E";
}
.icon-cartel-32:before {
  content: "F";
}
.icon-cartel-33:before {
  content: "G";
}
.icon-cartel-34:before {
  content: "H";
}
.icon-cartel-35:before {
  content: "I";
}
.icon-cartel-36:before {
  content: "J";
}
.icon-cartel-37:before {
  content: "K";
}
.icon-cartel-38:before {
  content: "L";
}
.icon-cartel-39:before {
  content: "M";
}
.icon-cartel-52:before {
  content: "N";
}
.icon-cartel-51:before {
  content: "O";
}
.icon-cartel-50:before {
  content: "P";
}
.icon-cartel-49:before {
  content: "Q";
}
.icon-cartel-48:before {
  content: "R";
}
.icon-cartel-47:before {
  content: "S";
}
.icon-cartel-46:before {
  content: "T";
}
.icon-cartel-45:before {
  content: "U";
}
.icon-cartel-44:before {
  content: "V";
}
.icon-cartel-43:before {
  content: "W";
}
.icon-cartel-42:before {
  content: "X";
}
.icon-cartel-41:before {
  content: "Y";
}
.icon-cartel-40:before {
  content: "Z";
}
// 2018
@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-Italic.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-ExtraBold.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-MediumItalic.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-BoldItalic.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-Regular.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-Black.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-BlackItalic.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-Medium.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/alegreya/Alegreya-Bold.woff2') format('woff2'),
      url('../fonts/alegreya/Alegreya-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

