/*colore*/
node-video, .node.taxonomy-term-música{
   /*@col.or-musica*/
   /*seccion lateral superior*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-musica;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-musica;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-musica;
         }
         &::before{
            border-right: 20px solid @color-musica;
         }
      }
      h1::after{
         background-color: @color-musica;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-deportes{
   /*@color-deportes*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-deportes;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-deportes;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-deportes;
         }
         &::before{
            border-right: 20px solid @color-deportes;
         }
      }
      h1::after{
         background-color: @color-deportes;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-arte{
   /*@color-arte*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-arte;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-arte;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-arte;
         }
         &::before{
            border-right: 20px solid @color-arte;
         }
      }
      h1::after{
         background-color: @color-arte;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-noticias{
   /*@color-noticias*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-noticias;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-noticias;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-noticias;
         }
         &::before{
            border-right: 20px solid @color-noticias;
         }
      }
      h1::after{
         background-color: @color-noticias;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-sexo{
   /*@color-sexo*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-sexo;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-sexo;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-sexo;
         }
         &::before{
            border-right: 20px solid @color-sexo;
         }
      }
      h1::after{
         background-color: @color-sexo;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-opinión{
   /*@color-opinion*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-opinion;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-opinion;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-opinion;
         }
         &::before{
            border-right: 20px solid @color-opinion;
         }
      }
      h1::after{
         background-color: @color-opinion;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-diseño{
   /*@color-diseno*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-diseno;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-diseno;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-diseno;
         }
         &::before{
            border-right: 20px solid @color-diseno;
         }
      }
      h1::after{
         background-color: @color-diseno;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-agenda{
   /*@color-agenda*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-agenda;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-agenda;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-agenda;
         }
         &::before{
            border-right: 20px solid @color-agenda;
         }
      }
      h1::after{
         background-color: @color-agenda;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-historias{
   /*@color-historias*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-historias;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-historias;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-historias;
         }
         &::before{
            border-right: 20px solid @color-historias;
         }
      }
      h1::after{
         background-color: @color-historias;
      }
   }/*fin hover*/
}


.node-video, .node.taxonomy-term-moda{
   /*@color-moda*/

   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-moda;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-moda;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-moda;
         }
         &::before{
            border-right: 20px solid @color-moda;
         }
      }
      h1::after{
         background-color: @color-moda;
      }
   }/*fin hover*/
}



.node-video, .node.taxonomy-term-420{
   /*@color-420*/
   .group-contenedor{
      > .field-name-clone-seccion{
         background-color: @color-420;
      }
   }/*fin*/
   .group-hover{
      .group-texto-fecha{
         background-color: @color-420;
         &::after, &::before{
         }
         &::after{
            border-left: 20px solid @color-420;
         }
         &::before{
            border-right: 20px solid @color-420;
         }
      }
      h1::after{
         background-color: @color-420;
      }
   }/*fin hover*/
}





/*colores para baner pages
=========================================0*/
/*/@420*/
.page-taxonomy-term-46{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-420;
            &::before{
               border-right: solid 20px @color-420;
            }
            &::after{
               border-left: solid 20px @color-420;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-420;
               }
            }
         }
      }
   }
   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-420;
            &::before{
               border-right: solid 20px @color-420;
            }
            &::after{
               border-left: solid 20px @color-420;
            }
         }
      }
   }
}/*/@420*/

/*/historias*/
.page-taxonomy-term-9{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-historias;
            &::before{
               border-right: solid 20px @color-historias;
            }
            &::after{
               border-left: solid 20px @color-historias;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-historias;
               }
            }
         }
      }
   }

   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-historias;
            &::before{
               border-right: solid 20px @color-historias;
            }
            &::after{
               border-left: solid 20px @color-historias;
            }
         }
      }
   }
}/*/historias*/

/*noticias*/
.page-taxonomy-term-4{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-noticias;
            &::before{
               border-right: solid 20px @color-noticias;
            }
            &::after{
               border-left: solid 20px @color-noticias;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-noticias;
               }
            }
         }
      }
   }

   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-noticias;
            &::before{
               border-right: solid 20px @color-noticias;
            }
            &::after{
               border-left: solid 20px @color-noticias;
            }
         }
      }
   }
}/*noticias*/

/*sexo*/
.page-taxonomy-term-5{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-sexo;
            &::before{
               border-right: solid 20px @color-sexo;
            }
            &::after{
               border-left: solid 20px @color-sexo;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-sexo;
               }
            }
         }
      }
   }

   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-sexo;
            &::before{
               border-right: solid 20px @color-sexo;
            }
            &::after{
               border-left: solid 20px @color-sexo;
            }
         }
      }
   }
}/*/sexo*/

/*musica*/
.page-taxonomy-term-1{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-musica;
            &::before{
               border-right: solid 20px @color-musica;
            }
            &::after{
               border-left: solid 20px @color-musica;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-musica;
               }
            }
         }
      }
   }

   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-musica;
            &::before{
               border-right: solid 20px @color-musica;
            }
            &::after{
               border-left: solid 20px @color-musica;
            }
         }
      }
   }
}/*musica*/

/*deportes*/
.page-taxonomy-term-2{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-deportes;
            &::before{
               border-right: solid 20px @color-deportes;
            }
            &::after{
               border-left: solid 20px @color-deportes;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-deportes;
               }
            }
         }
      }
   }

   .panel-display{
      .group-texto{
         .group-texto-fecha{
            background: @color-deportes;
            &::before{
               border-right: solid 20px @color-deportes;
            }
            &::after{
               border-left: solid 20px @color-deportes;
            }
         }
      }
   }
}/*deportes*/

/*arte*/
.page-taxonomy-term-3{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-arte;
            &::before{
               border-right: solid 20px @color-arte;
            }
            &::after{
               border-left: solid 20px @color-arte;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-arte;
               }
            }
         }
      }
   }

   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-arte;
            &::before{
               border-right: solid 20px @color-arte;
            }
            &::after{
               border-left: solid 20px @color-arte;
            }
         }
      }
   }
}/*arte*/

/*/diseno*/
.page-taxonomy-term-7{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-diseno;
            &::before{
               border-right: solid 20px @color-diseno;
            }
            &::after{
               border-left: solid 20px @color-diseno;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-diseno;
               }
            }
         }
      }
   }

   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-diseno;
            &::before{
               border-right: solid 20px @color-diseno;
            }
            &::after{
               border-left: solid 20px @color-diseno;
            }
         }
      }
   }
}/*/diseno*/

/*moda*/
.page-taxonomy-term-10{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-moda;
            &::before{
               border-right: solid 20px @color-moda;
            }
            &::after{
               border-left: solid 20px @color-moda;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-moda;
               }
            }
         }
      }
   }

   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-moda;
            &::before{
               border-right: solid 20px @color-moda;
            }
            &::after{
               border-left: solid 20px @color-moda;
            }
         }
      }
   }
}/*/moda*/

/*opinion*/
.page-taxonomy-term-6{
   .region-top{
      .cabezote-seccion{
         .background-titulo-seccion{
            background: @color-opinion;
            &::before{
               border-right: solid 20px @color-opinion;
            }
            &::after{
               border-left: solid 20px @color-opinion;
            }
            .texto-entradilla-seccion{
               &::before{
                  background-color: @color-opinion;
               }
            }
         }
      }
   }

   .one-column-main.cartel-panel.panel-display{
      .group-texto.field-group-div{
         .group-texto-fecha{
            background: @color-opinion;
            &::before{
               border-right: solid 20px @color-opinion;
            }
            &::after{
               border-left: solid 20px @color-opinion;
            }
         }
      }
   }
}/*/opinion*/

