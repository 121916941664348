/* FOOTER
================================================================== */
footer.container-fluid{
	padding: 0 !important;
}
section#block-system-main{
	.opinion.row{
		display: none;
	}
}

.container_footer.footer-desktop{
	overflow: hidden;

	.contenedor-superior{
		.container-fluid{
			padding: 0 !important;
		}

		.newslleter_footer{
			position: relative;
			width: 50%;
			top: 30px;
			margin: 0 auto;
			font-size: 0;
			#mailchimp-newsletter-67829-mergefields{
				width: 80%;
				display: inline-block;
				vertical-align: top;
				input{
					padding: 0;
					margin: 0;
					padding-left: 15px;
					background: none;
					border: 1px solid @negro;
					color: @negro;
				}
				::-webkit-input-placeholder {
					color: @negro;
					text-align: center;
				}
				:-moz-placeholder { /* Firefox 18- */
					color: @negro;
					text-align: center;
				}
				::-moz-placeholder {  /* Firefox 19+ */
					color: @negro;
					text-align: center;
				}
				:-ms-input-placeholder {
					color: @negro;
					text-align: center;
				}
			}
			button{
				width: 15%;
				margin-left: 2%;
				display: inline-block;
				vertical-align: top;
				border-radius: inherit;
				background-color: @azulfooter;
				color: @blanco;
				border: none;
				font-family: @bebas;
				font-size: 18px;
				padding-bottom: 3px;
			}
		}
		.politica-datos{
			position: relative;
			top: 50px;
			z-index: 9;
			a{
				text-align: center;
				img{
					position: relative;
					width: inherit;
					width: 15%;
					left: 50%;
					bottom: -5px;
					transform: translate(-50%);
				}
			}
		}

		img{
			position: relative;
			width: 101%;
			max-width: 101%;
			height: auto;
			bottom: -28px;
			left: 50%;
			transform: translate(-50%);
		}
	}

	.footer-info{
		background-color: @azulfooter;
		padding-bottom: 1em;
		.col-footer-info{
			&:nth-child(1){
				padding-top: 2em;
				.enlaces_internos{
					position: relative;
					display: inline-block;
					left: 50%;
					transform: translate(-50%);
					h3{
						position: relative;
						display: inline-block;
						background-color: @blanco;
						color: @negro;
						left: 50%;
						transform: translate(-50%);
						padding: 0.1em 50px;
						font-size: 19px;
					}
					.list_sections,
					.container_link_general{
						display: inline-block;
						vertical-align: top;
						position: relative;
						margin-top: 5px;
						padding-left: 0.5em;
						margin-left: 2em;
						li {
							height: 15px;
							list-style: none;
							a{
								color: @blanco;
								font-size: 12px;
								text-transform: uppercase;
							}
						}

						&::before{
							content: "";
							position: absolute;
							left: 0;
							top: 0;
							width: 1px;
							height: 100%;
							background-color: @blanco;
						}
					}
					.list_sections{
					}
					.container_link_general{
					}
				}
			}

			&:nth-child(2){
				padding-top: 3em;
				.redes-sociales{
					h3{
						position: relative;
						display: inline-block;
						background-color: @blanco;
						color: @negro;
						left: 50%;
						transform: translate(-50%);
						padding: 0.1em 2.5em;
						font-size: 19px;
					}
					ul{
						position: relative;
						display: inline-block;
						padding: 0;
						left: 50%;
						transform: translate(-50%);
						padding-top: 2em;
						text-align: center;
						&::before{
							content: "";
							position: absolute;
							top: 66%;
							left: -57px;
							width: 50px;
							height: 2px;
							background-color: @blanco;
						}
						&::after{
							content: "";
							position: absolute;
							top: 67%;
							right: -61px;
							width: 50px;
							height: 2px;
							background-color: @blanco;
						}
						li{
							list-style: none;
							display: inline-block;
							//padding: 0 0.5em;
							img{
								width: 75%;
								height: auto;
							}
						}
					}
				}
			}

			&:nth-child(3){
				padding-top: 2em;
				.enlaces_externos{
					h3{
						position: relative;
						display: inline-block;
						background-color: @blanco;
						color: @negro;
						left: 50%;
						transform: translate(-50%);
						padding: 0.1em 7em;
						font-size: 19px;
					}
					.container-nuestros_amigos{
						margin-top: 5px;
						font-size: 0;
						ul{
							position: relative;
							display: inline-block;
							vertical-align: top;
							width: 50%;
							&::before{
								content: "";
								position: absolute;
								left: 28px;
								top: 0;
								width: 1px;
								height: 100%;
								background-color: @blanco
							}
							li{
								list-style: none;
								a{
									color: @blanco;
									font-size: 11px;
									text-transform: uppercase;
								}
							}
						}
					}
				}
			}
		}
	}

	.footer-middle{
		background-color: @azulfooter;
		height: 0;
		img{
			position: relative;
			width: 101%;
			max-width: 101%;
			height: auto;
			left: 50%;
			bottom: 14px;
			transform: translate(-50%);
		}
	}

	.footer-bottom{
		background-color: @negro;
		padding: 2em 0;
		padding-bottom: 1em;
		.sobre_cartel{
			width: 70%;
			margin: 0 auto;
			padding: 2px;
			border: 1px solid @blanco;
			color: @blanco;
			p{
				text-align: center;
			}
		}
		.row{
			padding-top: 2em;
			.col-footer-bottom{
				position: relative;
				min-height: 160px;
				.iab-icon{
					width: 110%;
				    position: relative;
				    left: -20px;
				}
				@media (max-width: 1366px) {
					min-height: 140px;
				}
				.icon.mocion-icon{
					text-align: center;
					img{
						position: relative;
						//bottom: -41px;
						//width: 22%;
						//top: 15px;
					}
				}
				#direccion,
				#derechos_autor{
					position: absolute;
					display: table;
					bottom: 10px;
					left: 50%;
					transform: translate(-50%);
					color: @blanco;
					font-size: 13px;
				}
				.list-icons{
					//width: 80%;
					width: 100%;
					margin: 0 auto;
					ul{
						padding: 0;
						text-align: center;
						font-size: 0;
						li{
							display: inline-block;
							width: 25%;
							list-style: none;
							padding-top: 12px;
							&.cartel-icon-li{
								width: 70%;
								margin: 1rem 0;
							}
							&.nodo-icon-li,
							&.creadores-icon-li{
								width: 35%;
							}
							img{
								width: 95%;
								height: auto;
							}
						}
					}
				}
			}
		}
	}

}

.container_footer.footermobile{
	overflow-x: hidden;
	.contenedor-superior{
		text-align: center;
		img{
			width: 70%;
			height: auto;
			padding: 1em 0;
		}
		.row{
			margin-top: 0;
		}
		.newslleter_footer{
			width: 100%;
			font-size: 0;
			#mailchimp-newsletter-67829-mergefields{
				width: 90%;
				display: inline-block;
				vertical-align: top;
				input{
					margin: 0;
					background: none;
					border: 1px solid @negro;
					color: @negro;
				}
				::-webkit-input-placeholder {
					color: @negro;
					text-align: center;
				}
				:-moz-placeholder { /* Firefox 18- */
					color: @negro;
					text-align: center;
				}
				::-moz-placeholder {  /* Firefox 19+ */
					color: @negro;
					text-align: center;
				}
				:-ms-input-placeholder {
					color: @negro;
					text-align: center;
				}
			}
			button{
				width: 10%;
				display: inline-block;
				vertical-align: top;
				border-radius: 0;
				border: none;
				position: relative;
				font-size: 0;
				&::before{
					content: "";
					position: absolute;
					top: 50%;
					left: 67%;
					width: 100%;
					height: 43px;
					transform: translate(-50%, -29%);
					background-image: url(../img/footer/email.png);
					background-position: center center;

				}
			}
		}
	}

	.footer-info{
		padding-bottom: 3em;
		background-color: @azulfooter;
		.row{
			margin-top: 0;
		}
		h3{
			position: relative;
			display: inline-block;
			background-color: #fff;
			color: #191919;
			left: 50%;
			transform: translate(-50%);
			padding: 0.1em 4em;
			font-size: 19px;
			margin: 1em 0;
		}
		ul{
			padding: 0;
			font-size: 0;
			text-align: center;
			li{
				list-style: none;
				display: inline-block;
				vertical-align: top;
				width: 25%;
				img{
					width: 80%;
					height: auto;
				}
			}
		}
	}
	.footer-middle{
		height: 0;
		img {
			position: relative;
			width: 100%;
			top: -35px;
			height: auto;
		}
	}
	.footer-bottom{
		padding-top: 3em;
		background-color: @negro;
		.row{
			margin-top: 0;
			.col-footer-text{
				padding: 23px 0;
				&>div{
					color: @blanco;
					text-align: center;
					font-size: 11px;
				}
			}
			ul{
				padding: 0;
				text-align: center;
				font-size: 0;
				li{
					list-style: none;
					display: inline-block;
					.cartel-icon img{
						width: 100% !important;
					}
					img{
						width: 100%;
						height: auto;
					}
				}
				&.list-icons-uno{
					li{
						width: 25%;
					}
				}
				&.list-icons-dos{
					margin: 0;
					text-align: center;
					li{
						width: 50%;
						img{
							width: 60%;
							height: auto;
						}
						&:nth-child(1){
							text-align: left;
						}
						&:nth-child(2){
							text-align: right;
						}

					}
				}
			}
		}
	}
}

