/* =============================================================
stilos moviles
============================================================== */
@media (max-width: @screen-sm-min) {

   /* header menu - Sidebar
   ====================================================================== */
   #block-system-main-menu {
      //width: 60%;
      //top: 60px;
      a{
         font-size: 17px;
      }
      &.activeSubMenu{ .translate3d(-100%,0,0); }
   }

   #block-system-main-menu ul.menu.nav > li.dropdown ul.dropdown-menu li .menu-attach-block-wrapper section#block-webform-client-block-602, 
   #block-system-main-menu ul.menu.nav > li.dropdown ul.dropdown-menu li .menu-attach-block-wrapper section#block-webform-client-block-601 {
      overflow-y: scroll; 
      top: -145px;
   }

   /* header menu top 
   ====================================================================== */
   #block-cu-menu-general-block-menu-links-redes {
      .foo{}

      /* left content 	================================= */
      .container_info_site {
         width: 95%;
         margin: auto;

         .lema_site { .hidden; }

         > a { float: right; }

         .icon-cartel-02{ left: 15px;
            opacity: 1;
         }
      }

      /* right content 	================================= */
      .container_link_redes { .hidden; }
      .container_link_redes { .hidden; }

   }

   /* header menu bottom
   ====================================================================== */
   #block-cu-menu-sections-block-menu-sections {
      .item-list {
         height: auto !important;
         border: none;
         text-align: center;
      }

      ul#menu-sections li {
         display: inline-block;
         float: none;
         padding-left: 10px;
         padding-right: 10px;
         font-size: 18px;

         &::after {
            content: '';
            position: absolute;
            display: block;
            width: 2px;
            height: 2px;
            right: -1px;
            top: 50%;
            margin-top: -1px;
            border-radius: 4px;
            background-color: @negro;
         }
      }
      ul#menu-sections li:nth-of-type(4)::after { .hidden; }
      ul#menu-sections li:nth-last-of-type(1)::after { .hidden; }
   }

   /* header menu bottom - submenu =================== */
   #div_sub_menu_section { .hidden; }

   /* header menu extras
   =================================================== */
   #block-cu-menu-sections-block-menu-links-extras {
      top: 60px;
      .container_link_general .cartel_tv a,
      .container_link_general .galerias a,
      .container_link_general .edicion_impresa a {
         padding: 0px 9px;
         font-size: 10px;
         color: #191919;
      }

      .container_link_general .abrir-menu.icon-cartel-02{
         display: none;
      }
   }
   .region-header-seccion.sticky{
      #block-system-main-menu{
         margin-top: 40px !important;
         overflow: auto;
         height: 100%;

      }
   }
}
/*
Menu Desplegable
=============================================*/
@media (max-width: 767px) and (min-width: 320px){
   #page-header-seccion{

      .block-menu{
         display: none;
         &.inactive{
            display: block;
         }
      }
   }
}
