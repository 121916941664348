.node-type-gallery{


	/*header*/
	#node_gallery_full_group_titulo_cabecera{
		width: 80%;
		margin: auto;
		#node_gallery_full_group_fecha_cabecera{
			padding: 1em 0;
			> div{
				display: inline-block;
				vertical-align: top;
				&.field-name-field-seccion{
				}
				&.field-name-title{
					margin-left: 0;
					h1{
						font-size: 31px;
					}
				}
			}
		}
	}

	/*galria*/
	.slick-list.draggable{
		//height: 300px !important;
		.slick-track{
			//height: inherit !important;
		}
	}
	.slick-wrapper{
		width: 100% !important;
		padding: 0 !important;
		overflow-x: hidden;
		background-color: #eaeaea !important;
		#pager{
			position: static !important;
			width: 100% !important;
			//margin-bottom: 25px;
			background: none !important;
			.content-pages .wrap > span {
				margin: 0 0 !important;
			}

		}

		.slick.slick--optionset--galerias{
			height: inherit;
			margin-bottom: 0;
			.slick-slider{
				.slide__media{
					width: 100% !important;
				}
				.slide__caption{
					position: relative !important;
					display: none !important;
					width: 100% !important;
					height: inherit !important;
					float: inherit !important;
					padding: 0 !important;

				}
			}
		}

		.slick--optionset--x-slick-nav{
			width: 100%;
			.slick__slider{
				.slick-list {
					.slick__slide{
						img{
							margin: 0;
						}
					}
				}
			}
		}
	}
	/*body*/
	.field-name-body{
		width: 90%;
		margin: auto;
	}

	/*socil*/
	.field-name-social-network{
		position: static;
		width: 100%;
		ul {
			width: 100%;
			li{
				ul{
					width: 100%;
					li{
						display: inline-block;
						margin-right: 20%;
					}
				}
			}
		}
	}
}

.region-left {
	padding: 0 !important;
}



