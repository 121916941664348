/* estilos alertas
=============================================================== */
.alert {
    transform: translateY(40px);
}
.main-container{
    padding: 0px;
}
/* estilos despliegues
=============================================================== */
.node-type-article {
    .field-name-summary-body {
        padding: 20px;
        margin: 20px 0;
        border-radius: 3px;
        background-color: rgb(242, 242, 242);
        font-weight: 700;
        color: @negro;
        p {
            margin: 0;
            &::first-letter {
                font-size: 16px !important;
            }
            strong{
                &::first-letter {
                    font-size: 16px !important;
                }
            }
        }
    }
}

.node-type-article #block-system-main .two-8-4-column.cartel-panel.panel-display:nth-of-type(1),
.node-type-article #block-system-main .two-8-4-column.cartel-panel.panel-display:nth-of-type(2) {
    max-width: 1550px;
    margin: auto;
    padding-top: 12px;
}

/* filtro cartel eventos en sidebar ------------------------- */
.page-node #edit-field-ciudad-target-id-wrapper label {
    font-size: 14px;
    &::after { content:': '; }
}

.page-node #edit-field-ciudad-target-id-wrapper .views-widget {
    display: inline-block;
}

.page-node .views-exposed-form .views-exposed-widget .form-submit {
    padding: 7px 10px;
    margin-top: 8px;
}

/* relacionados ============================== */
.one-column-bottom .region-bottom.col-fluid,
.two-8-4-column .region-bottom.col-fluid {
    margin-top: 110px;
}
.page-node .footer.footer.container-fluid { padding-top: 0; }


/* badge ---------------------------------- */
.page-node .badge {
    .translate3d(0,-25px,0);
    max-width: 100%;
    white-space: normal;
    background: black;
    border-radius: 0;
    padding: 7px;
}

.page-node {

    /* solo para desktop ===================== */
    @media (min-width: @screen-sm-min) {
        .main-container {
            position: relative;
            top: -52px;
        }
    }

    /* columnistas ----------------- */
    .vocabulary-columnistas > .group-left {
        overflow: hidden;
        position: relative;
        width: 100px;
        height: 100px;
        background-color: @negro;
        border-radius: 50px;

        img { .scale(2.4) }
    }

    .vocabulary-columnistas > .group-right {
        padding-left: 15px;
        h2 {
            font-size: 24px;
            margin-bottom: 0;
            margin-top: 25px;
        }
    }

    .region-left,
    .region-main .field-name-field-imagen,
    .region-main .field-name-body {
        img {
            width: 100% !important;
            height: auto !important;
            margin: 5px 0 0 0;
        }
        p img{
            margin: 4px 0;
        }
    }

    /* imagen nodo ================================ */
    .field-name-field-imagen { position: relative; }
    .field-name-field-imagen .image-field-caption {
        position: absolute;
        display: inline-block;
        right: -15px;
        bottom: 50px;
        margin: 0;
        padding: 10px 20px;
        border-left: none;
        background-color: @negro;

        font-size: 18px;
        font-family: @bebas;
        color: white;

        &::after {
            content:'';
            position: absolute;
            border-left: 15px solid @gris-oscuro;
            border-bottom: 20px solid transparent;
            right: 0;
            bottom: -20px;
        }
    }

    /* escrito por ================== */

    .field-name-author {
        font-family: @bebas;
        font-weight: normal !important;
        font-size: 24px;
        color: @negro;

        .field-label {
            font-weight: normal !important;
        }
    }


    .field-name-field-escrito-por {
        padding-top: 15px;
        padding-bottom: 15px;

        font-family: @bebas;
        font-size: 20px;
        line-height: 17px;
        color: @negro;

        .field-label {
            font-weight: normal;
            font-size: 16px;
        }
    }

    .field-name-field-escrito-por .field-item p {
        display: inline;
        font-size: 22px;
    }

    .field-name-field-escrito-por .field-item::before {
        content: 'por ';
        display: inline;
        font-size: 15px;
    }

    /* fecha ---------------------------------- */

    .field-name-post-date { /* campo de fecha */
        text-transform: uppercase;
        font-family: @strait;
        color: @gris;
    }

    .region-main,
    .region-left .field-name-body { /* contenido campo body */
        .clearfix();
        .field-item > p:nth-of-type(1):first-letter { /* primera letra mayuscula */
            /*
            text-transform: uppercase;
            font-size: 30px;
            font-weight: bold;
            line-height: 20px;
            */
        }

        blockquote {
            padding: 10px 30px 10px 0;
            border-left: none;
            line-height: 32px;
            font-family: @bebas;
            font-size: 25px;
            color: @azul;
        }

        .media_embed {
            position: relative;
            width: 100%;
            margin: 30px 0;
            height: 400px;
            // &::before {
            //     content:'';
            //     display: block;
            //     padding-top: 56%; /* aspect ratio 4:3 */
            // }
            @media(max-width: 768px){
               height: 170px; 
            }
        }


        iframe {
            /*
            position: absolute !important;
            width: 90% !important;
            height: 90% !important;
            //height: auto;
            margin: 0 !important;
            top: 50%;
            left: 50%;
            z-index: 5;

            .translate3d(-50%,-50%,0);
            */
            width: 100%;
            height: 100%;
        }


        }

    .group-titulo,  .group-fecha-cabecera.field-group-div{
        position: relative;
        padding-top: 0;
        h1 {
            margin: 0;
            @media (max-width: 768px) {
                font-size: 30px;

            }
        }

        .field-name-field-seccion {
            /* icono - tipo */
            .field-name-field-icono .field-items {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 20px;
                left: -70px;

                text-align: center;
                line-height: 50px;
                font-size: 34px;
                color: white;

                z-index: 2;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    min-height: 100%;
                    background-color: @azul;
                    z-index: -1;

                    .rotate(45deg);
                }
                a{
                    color: white;
                }
            }
        }

        /* estilos moviles ====================== */
        @media (max-width: @screen-sm-min) {
            .field-name-title {
                padding-left: 60px;
            }

            .field-name-field-seccion .field-name-field-icono .field-items {
                left: 0;
                top: 0;
                .scale(0.6);
            }
        }
    }

    @media (max-width: @screen-sm-min) {
        .region-main,
        .region-left {
            img { width: 100% !important; height: auto !important; margin: 0!important; }
        }
    }

}
/* split layout
================================================================ */
/* scroll galerias siempre visible */
.split-node .ps-container .ps-scrollbar-y-rail { opacity: 1 !important; }
/* stilos desktop ================ */
@media (min-width: @screen-sm-min) {
    .split-node { overflow: hidden; }
}

.split-node .layout-split.layout-split-top { padding-bottom: 30px; }

.split-node .layout-split {
    margin-top: 0;
    margin-bottom: 0;

    .region-left { padding-right: 50px; }
    /* perfect scroll */
    .region-left,
    .region-right {
        position: relative;
        overflow: hidden;
        img { max-width: 100%; height: auto; margin-bottom: 15px;}

        /* ocultar scroll horizontal */
        .ps-scrollbar-x-rail { .hidden; }

        /* stilos moviles ================ */
        @media (max-width: @screen-sm-min) {
            height: auto !important;
            width: 100%;
            float: none;
        }

    }
    @media (min-width: @screen-sm-min) {
        /* contenido */
        .region-left {
            width: 33.33333333%;
            padding-left: 8.33333333%;
            margin-left: 0;
        }
    }
    /* imagenes galeria */
    .region-right {
        padding-right: 8.33333333%;
        /* stilos moviles ================ */
        @media (max-width: @screen-sm-min) {
            padding-bottom: 40px;
        }
    }
}

.split-node footer.footer.container-fluid {
    .container_1,
    .container_2,
    .container_3 { display: none; visibility: hidden; }

    .container_4 {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

/* LO POPULAR
================================================================ */
.page-node .view-relacionado {
    .views-limit-grouping-group {
        position: relative;

        > h3 { .hidden; }

        .views-field-created {
            text-transform: uppercase;
            font-family: @strait;
            font-size: 14px;
            color: @gris;
        }

        .views-field-title {
            margin-top: 15px;

            &::after {
                content: '/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////';
                display: block;
                overflow: hidden;
                margin-top: 10px;
                margin-bottom: 15px;
                width: 100%;
                height: 5px;
                font-size: 8px;
                color: @gris;
            }

            .field-content {
                display: block;
                height: 42px;
                padding-right: 85px;
            }

            a { color: @negro; }
        }

        .views-field-field-icono .field-content {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 25px;
            right: 15px;

            text-align: center;
            line-height: 40px;
            font-family: "cartel-urbano";
            font-size: 30px;
            color: white;

            z-index: 1;

            &::after {
                content: '';
                position: absolute;
                width: 40px;
                height: 40px;
                left: 50%;
                top: 50%;
                margin-left: -20px;
                margin-top: -20px;
                background-color: @azul;

                z-index: -1;

                .rotate(45deg);
            }
        }
    }
}

/* Agenda
================================================================ */
.field-name-agendasidebar {}

/* Vista Header 	=============================== */
.field-name-agendasidebar .view-header {
    position: relative;

    text-align: center;
    font-family: @bebas;
    font-size: 21px;
    color: white;

    &::after {
        content: 'm';
        display: block;
        margin-bottom: 5px;

        text-align: center;
        line-height: 11px;
        font-family: "cartel-urbano";
        font-size: 24px;
        color: @azul;
    }

    p {
        padding: 10px 0;
        margin-bottom: 0;
        background-color: @azul;
        text-transform: uppercase;
    }
}

/* Vista Contenido 	=============================== */
.field-name-agendasidebar .view-content {

    border: 3px solid @negro;
    padding: 7px;

    .group-imagen-evento {
        position: relative;
        font-family: @bebas;
        color: white;
    }

    .group-fecha-evento {
        position: absolute;
        width: 55px;
        height: auto;
        bottom: 0;
        left: 0;
        background-color: @azul;

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 15px solid @azul;
            border-top: 15px solid transparent;
            right: -15px;
            bottom: 0;
        }



        .field-name-field-fecha {
            padding-top: 3px;
            padding-left: 5px;
            padding-bottom: 2px;
            font-size: 26px;
            line-height: 20px;

            .field-items { width: 80%; border-bottom: 2px solid white; }
        }

        .field-name-field-tipo-de-evento h2 { padding-left: 5px; font-size: 13px; position: relative; z-index: 2; }
    }

    .group-info-evento { padding: 5px; background-color: white; }

    .field-name-title {
        h2 { font-size: 26px; margin: 0; }
    }

    .field-name-body {
        line-height: 15px;

        .field-item > p:nth-of-type(1):first-letter { text-transform: initial; }
    }
}

/* Vista Footer 	=============================== */
.field-name-agendasidebar .view-footer {
    margin: 20px 0;

    text-align: center;
    letter-spacing: 2px;
    font-family: @strait;

    &::before {
        content: 'u';
        display: block;
        font-family: "cartel-urbano";
        font-size: 30px;
        line-height: 7px;
        color: @azul;
    }

    a {
        display: block;
        padding: 10px;
        background-color: @azul;

        color: white;
    }
}
/* stilos moviles ================ */
@media (max-width: @screen-sm-min) {
    .front .field-name-agendasidebar .view-footer {
        position: absolute;
        margin: 0;
        right: 10px;
        top: 4px;
        background-color: transparent;
        text-align: right;

        &::before { .hidden; }

        a {
            background-color: transparent;
        }
    }
    .field-name-field-escrito-por{
        padding-left: 15px;
        padding-right: 15px;
    }
}




/* moviles
=============================================================== */
@media (max-width: @screen-sm-min) {

    .page-node .field-name-post-date { /* alinea fecha con titulo */
        padding-left: 60px;
    }

    .page-node .field-name-body { /* ajuste textos en cuerpo de los articulos */
        font-size: 18px;
        line-height: 25px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .page-node .field-name-field-imagen .image-field-caption {
        font-size: 13px;
        padding: 2px 15px;
        bottom: 35px;
    }

    .page-node .field-name-field-imagen .image-field-caption::after {
        bottom: -10px;
        border-bottom: 10px solid transparent;
    }

    /* sidebar moviles
    ================================================================ */
    //.page-node.node-type-article .region-right { padding-top: 50px; .hidden(); } /* oculta agenda temporalmente */
    .page-node.node-type-article .region-right { 
        margin-top: 20px;
        text-align: center;
         }
}

/* Desktop
=============================================================== */
@media (min-width: @screen-sm-min) {
    .page-node .field-name-body blockquote {
        float: left;
        max-width: 50%;
        //.translate(-15%,0);
    }
}

/* fix font-size: larger ------------ */
.page-node .field-name-body p span { font-size: initial !important; }



/* publicidad 50 casos exitosos de autogestion
=============================================================== */
.not-front.page-node{
    .field-name-publicidad-2 .publicidad #div_ifr_ad_137GFIZ60KpxX5kai9bLHb3Ek5yxAyAg #ifr_ad_137GFIZ60KpxX5kai9bLHb3Ek5yxAyAg
    {
        margin-left: -82px !important;
    }
    .field-name-publicidad-2{
        text-align: center;
        img{
            margin: 0;
        }
    }
}


/*ocultando titulo de contenido
===============================================================*/
.page-taxonomy-term-165{
    .titulo-seccion {
        display: none;
    }
    .pane-cu-list-content-section-block-list-content-section{
        display: none;
    }
}



/*lo+popular internas noodos
===============================================================*/
.page-node{
    .field-name-lo-mas-popular-block{
        width: 100%;
        height: auto;
        //max-height: 760px;
        //max-height: 780px;
        box-sizing: border-box;
        clear: both;
        //float: right;
        background-color: #f4f4f4;
        //overflow: auto;
        overflow: hidden;
        .block-title{
            background-color: #07b9e3;
            margin: 0;
            color: #fff;
            font-size: 35px;
            text-align: left;
            padding-left: 20px;
            &::before{
                content: " ";
                position: relative;
                display: inline-block;
                width: 40px;
                height: 40px;
                top: 5px;

                background-repeat: no-repeat;
                background-size: 55%;
                z-index: 999;
            }

        }
        .contenedor-popuar{
            .popular-item{
                display: inline-block;
                padding: 10px;
                text-align: center;
                width: 100%;
                .popular-item-information{
                    img{
                        // width: 31% !important;
                        float: left;
                        vertical-align: top;
                    }
                    .column-float{
                        //width: 66%;
                        float: left;
                        color: #000;
                        margin-left: 3%;
                        vertical-align: top;
                        h4{
                            text-align: left;
                            font-size: 22px;
                            letter-spacing: 1px;
                            margin-top: 0;
                        }
                        p{
                            text-align: left;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
        &::-webkit-scrollbar{
            width: 6px;
            height: 10px;
        }

        &::-webkit-scrollbar-track{
            background: rgba(0, 0, 0, 0);
        }

        &::-webkit-scrollbar-thumb{
            background: rgba(7, 185, 227, 0.5);
            border-radius: 10px;
        }
    }

}

.field-name-lo-mas-reciente,
.field-name-recent-content{
    display: inline-block;
    margin-bottom: 2em;

    .field-items{
        margin-bottom: 2em;
        .block-title2 {
            background-color: #07b9e3;
            margin: 0;
            color: #fff;
            font-size: 35px;
            text-align: left;
            padding-left: 20px;
            &::before{
                content: " ";
                position: relative;
                display: inline-block;
                width: 40px;
                height: 40px;
                top: 5px;
                background: url(../img/icono-LoMasPopular.png);
                background-repeat: no-repeat;
                background-size: 55%;
                z-index: 999;
            }
        }
        .view-content{
            .views-row{
                width: 100%;
                clear: both;
                padding: 10px;
                .views-field-field-imagen-cuadrada {
                    float: left;
                    width: 32%;
                    vertical-align: top;
                    img{
                        width: 100%;
                    }
                }
                .container-float {
                    width: 65%;
                    float: right;
                    .views-field-title a{
                        font-weight: bold;
                        text-align: left;
                        font-size: 22px;
                        letter-spacing: 1px;
                        margin-top: 0;
                        font-family: 'bebas_neueregular',Arial-narrow;
                        color: #000;
                    }
                }
            }
        }
    }
}

/*
eventos-mas-populares
===============================================================*/
.field-name-block-fieldeventos-mas-populares {
    position: relative;
    display: inline-block;
    .block-cu-mas-popular{
        //max-height: 780px;
        margin-bottom: .5em;
        overflow: hidden;
    }
}


/*
lo mas+
====================================================*/
.page-node.node-type-article{
    .field-name-lo-mas-popular-block,
    .field-name-lo-mas-reciente,
    .field-name-recent-content,
    .field-name-block-fieldeventos-mas-populares{
        height: auto !important;
        max-height: none !important;

    }
}



#node_video_full_group_popular_body{/*ajuste pag video*/
    .field-name-body{
        width: 70%;
        display: inline-block;
        vertical-align: top;
    }
    .field-name-lo-mas-popular-block{
        display: inline-block;
        width: 365px;
        @media (min-width: @screen-sm-min){
            width: 315px;
            // width: 100%;
        }
        @media (max-width: 767px) and (min-width: 320px){
            width: 100%;
        }
    }
}

/*compartir articulo en*/
.group-redes-sociales{
    position: fixed;
    top: 40%;
    opacity: 1;
    &.social-fixed{
        opacity: 0;
        position: absolute;
        bottom: 214px;
        top: inherit;
    }
    .field-name-fblikebutton-ds-field iframe{
        position: relative;
        width: 75px !important;
        height: 22px;
        left: -8px;
        margin-top: 0.8em;
    }
}
.field-name-fblikebutton-ds-field iframe{
    position: relative;
    width: 75px !important;
    height: 22px;
    left: -8px;
    margin-top: 0.8em;
}

.page-node .region-right{
    .field-name-lo-mas-reciente,
    .field-name-recent-content,
    .field-name-publicidad,
    .field-name-block-fieldeventos-mas-populares,
    .field-name-lo-mas-popular-block,
    .field-name-publicidad-right-banner-2{
        margin: 0;
        padding: 0;
        //margin: 1em 0;
    }
    .field-name-lo-mas-reciente,
    .field-name-recent-content{
        .views-field-title a{
            line-height: 90%;
        }
        .views-field-field-subtitulo{
            line-height: 93%;
        }
    }
    .field-name-block-fieldeventos-mas-populares .block-cu-mas-popular{
        height: auto;
        .popular-item{
            display: inline-block;
        }
    }
}

.quizz-container{
    position: relative;
}

/*publicidad paginas internas
===========================================*/
.node-type-eventos .region-right{
    .field-name-agenda-publicidad{
        .publicidad{
            padding-top: 60%;
            margin-bottom: 0;
        }
    }
}



/*
ocultando addthis SOLUCION TEMPORAL
====================================0*/
.addthis_bar.addthis_bar_bottom.addthis_bar_fixed{
    display: none !important;
}




/*
banner cuadrado pruebas
=========================================*/
.gwd-page-container {
	min-height: 340px;
}



            /*Iframe only shock*/
            .field-name-shock-nodo {
                text-align: center;
                margin-top: -80px;
                .iframe-shock {
                    iframe {
                        margin:10px 0;
                        height: 504px !important;
                        width: 80% !important;
                        /*max-width: 695px;*/
                    }
                    @media (max-width: 1367px){
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        height: 163px;
                    }
                    @media (min-width: 890px) and (max-width: 1255px) {
                       iframe {
                            width: 700px !important;
                        } 

                    }
                           
                }                
            }

#block-system-main > div:nth-child(2) > div > div.region-right.region-inner.col-sm-3{
    padding-top: 34px;
}
