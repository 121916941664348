//## helper clases

.mostrar {
	display: block !important;
	visibility: visible !important;
}

/* degradado destacado - moviles */
.m-deg-destacado() {
	background: #9d2f89; /* Old browsers */
	background: -moz-linear-gradient(top,  #9d2f89 0%, #00deff 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#9d2f89), color-stop(100%,#00deff)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #9d2f89 0%,#00deff 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #9d2f89 0%,#00deff 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #9d2f89 0%,#00deff 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #9d2f89 0%,#00deff 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9d2f89', endColorstr='#00deff',GradientType=0 ); /* IE6-9 */
}

//## utilidades

.border-radius (@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}

.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
	-webkit-border-top-right-radius:    @topright;
	-webkit-border-bottom-right-radius: @bottomright;
	-webkit-border-bottom-left-radius:  @bottomleft;
	-webkit-border-top-left-radius:     @topleft;

	-moz-border-radius-topright:        @topright;
	-moz-border-radius-bottomright:     @bottomright;
	-moz-border-radius-bottomleft:      @bottomleft;
	-moz-border-radius-topleft:         @topleft;

	border-top-right-radius:            @topright;
	border-bottom-right-radius:         @bottomright;
	border-bottom-left-radius:          @bottomleft;
	border-top-left-radius:             @topleft;

	-moz-background-clip:    padding; 
	-webkit-background-clip: padding-box; 
	background-clip:         padding-box; 
}

.opacity (@opacity: 0.5) {
	-webkit-opacity: 	@opacity;
	-moz-opacity: 		@opacity;
	opacity: 		@opacity;
}

.perspective (@per: 500px) {
	-webkit-perspective: @per; /* Chrome, Safari, Opera */
	perspective: @per;
}

.transition (@transition) {
	-webkit-transition: @transition;  
	-moz-transition:    @transition;
	-ms-transition:     @transition; 
	-o-transition:      @transition;  
}

.scale (@factor) {
	-webkit-transform: scale(@factor);
	-moz-transform: 	 scale(@factor);
	-ms-transform: 		 scale(@factor);
	-o-transform: 		 scale(@factor);
}

.rotate (@deg) {
	-webkit-transform: rotate(@deg);
	-moz-transform: 	 rotate(@deg);
	-ms-transform: 		 rotate(@deg);
	-o-transform: 		 rotate(@deg);
}

.rotateY (@deg) {
	-webkit-transform: rotateY(@deg);
	-moz-transform: 	 rotateY(@deg);
	-ms-transform: 		 rotateY(@deg);
	-o-transform: 		 rotateY(@deg);
}

.rotateX (@deg) {
	-webkit-transform: rotateX(@deg);
	-moz-transform: 	 rotateX(@deg);
	-ms-transform: 		 rotateX(@deg);
	-o-transform: 		 rotateX(@deg);
}

.skew (@deg, @deg2) {
	-webkit-transform:       skew(@deg, @deg2);
	-moz-transform: 	 skew(@deg, @deg2);
	-ms-transform: 		 skew(@deg, @deg2);
	-o-transform: 		 skew(@deg, @deg2);
}

.translate (@x, @y:0) {
	-webkit-transform:   translate(@x, @y);
	-moz-transform: 	 translate(@x, @y);
	-ms-transform: 		 translate(@x, @y);
	-o-transform: 		 translate(@x, @y);
}

.translate3d (@x, @y, @z) {
	-webkit-transform:   translate3d(@x, @y, @z);
	-moz-transform: 	 translate3d(@x, @y, @z);
	-ms-transform: 		 translate3d(@x, @y, @z);
	-o-transform: 		 translate3d(@x, @y, @z);
}