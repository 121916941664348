#related_content_slider .ds-2col {
  > div {
    overflow: hidden;
    z-index: 2; 
  }

  .group-left img { width: 100%; height: auto; }

  .field-name-post-date { .hidden(); }
}
@media (max-width: @screen-sm-min) {
  #related_content_slider ul.slides li>.ds-2col h2 {
    min-height: 56px; /* pone en dos lineas el titulo */
  }
}