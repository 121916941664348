.respuesta {
	font-family: 'bebas_neuebook',Arial-narrow;
	background-color: #eee;
	width: 100%;
	margin: 0 auto;
	margin-top: 10rem;
	border-radius: 20px;
	text-align: center;
	padding: 25px;
	p {
		line-height: 45px;
		&:nth-child(1){
			font-size:6rem;
			font-weight: 900;
			&:before {
				content:"";
				background-image: url('../../assets/img/iconsobre.png');
				width: 61px;
			    height: 57px;
			    display: inline-block;
			    background-size: contain;
			    background-repeat: no-repeat;
			    background-position-y: 4px;
			}
		}
		&:nth-child(2){
			font-size: 4.5rem;
			padding-bottom: 10px;
			width: 500px;
			margin: 0 auto;
			border-bottom: 1px dashed black;
			@media (max-width: 768px){
				width: 100%;
			}
		}
		&:nth-child(3){
			margin-top: 10px;
			font-size: 3rem;
			font-family: 'bebas_neuelight',Arial-narrow;
			letter-spacing: 3px;
			&:after{
				content:"";
				background-image: url('../../assets/img/iconhand.png');
			    width: 34px;
			    height: 34px;
			    display: block;
			    background-size: contain;
			    background-repeat: no-repeat;
			    margin: 5px auto 0;
			}
		}
	}
}
.btn-respuesta-news {
	text-align: center;
	button {
		font-family: 'bebas_neuebook',Arial-narrow;
	    font-weight: 900;
	    background-color: #707070;
	    padding: 3px 9px 0;
	    color: #FFF;
	    border: none;
	    margin: 25px 0;
	    border-radius: 6px;
	    font-size: 2rem;
	}
}