.home-panel{
   .publicidad{
      iframe{
         z-index: 1 !important;
      }
      #__bs_rotation_49766{
      }
      
   }
   .node-publicidad{
   	height: 372px;
   }
}

.pane-cartel-section-publicidad{
	text-align: center;
	padding-bottom: 50px;
}

.field-name-field-publicidad .publicidad{
   .bs-yt-player{
      margin-top: 15%;
   }
   
}