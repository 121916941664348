/* ---------------------------------------------- 
estilos comunes
----------------------------------------------- */
.agenda-eventos .pane-title {}

.page-eventos-agenda h2.pane-title{
   margin-top: 0;
   padding: 10px;
   background-color: #07B9E3;
   text-align: center;
   line-height: 21px;
   color: white;
}

#main-agenda h2.pane-title{
   display:none !important;
}

.select2-selection__arrow,
.ajax-progress-throbber { .hidden(); }

.agenda-eventos .view-filters { /* wrap filtro */
   background-color: @negro;
}

.agenda-eventos .view-filters form {
   .views-submit-button { .hidden(); } /* ocultamos boton de submit */

   .views-exposed-widget { /* contenedor cada item (donde, cuando, que) */
      position: relative;
      width: 25%;
      padding: 0;
      float: left;
      text-align: center;

      label {
         text-transform: uppercase;
         font-family: @strait;
         color: #898989;
      }

      .form-submit {
         padding: 2px 25px;
         border-radius: 0;
         border: none;

         background-color: #363636;
         font-family: @bebas;
         color: #c1c1c1;
      }
   }

   :focus { outline: none; }
}

/* select
------------------------------------------------- */
.select2-container .select2-selection--single .select2-selection__rendered { /* item seleccionado */
   display: inline-block;
   font-family: @bebas;
   color: white;
}

.select2-dropdown { /* dropdown */
   text-align: center;
   font-family: @bebas;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
   background-color: @negro;
   color: white;
}

.select2-search--dropdown { .hidden(); }

/* cargador
------------------------------------------------- */
.cargando {
   .transition(all 0.4s ease-in-out);
   position: absolute;
   width: 200px;
   height: 200px;

   left: 50%;
   .translate(-50%, -50%); /* centra contenido */

   opacity: 0;
   z-index: -1;

   background: url(../img/loader.gif) no-repeat center center;
}



/* acciones cuando estan cargando los eventos
------------------------------------------------- */
.agenda-eventos.cargando-eventos {
   .cargando {
      opacity: 1;
      z-index: 100;
   }

   .view-content {
      opacity: 0;
   }
}

/* acciones cuando han cargando los eventos
------------------------------------------------- */
.agenda-eventos.eventos-cargados {
   .view-content {
      opacity: 1;
   }
}

/* ---------------------------------------------- 
estilos unicos moviles
----------------------------------------------- */
@media (max-width: @screen-sm-min) {

   .agenda-eventos .view-filters {
      height: 60px;
      margin-bottom: 30px;
   }

   .agenda-eventos .pane-title {
      margin-top: 0;
      padding: 10px;
      background-color: @azul;
      text-align: center;
      line-height: 21px;
      color: white;
   }

   .agenda-eventos .views-reset-button { .hidden(); }

   .agenda-eventos .view-filters form {
      padding-top: 9px;
   }

   .agenda-eventos .view-filters form .views-exposed-widget {
      width: 33%;
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid gray;
      text-align:left;
      &:nth-of-type(1) { border:none; }
      label {
         margin-bottom: 0;
         font-size: 10px;
      }
   }

   .cargando {
      top: 260px;

      &.sticky { position: fixed; top: 50%; }
   }

   /* filtro
   ------------------------------------------------ */
   .select2-container .select2-selection--single .select2-selection__rendered { font-size: 18px; }

   .agenda-eventos .select2-container--open {
      position: relative;
      &::before {
         content:'';
         display: block;
         position: absolute;
         bottom: -21px;
         left: 50%;

         .translate(-50%,0);

         border-color: transparent transparent #2f2f2f transparent;
         border-style: solid;
         border-width: 0 10px 10px 10px;
      }
   }

   body > span.select2-container.select2-container--default.select2-container--open { /* dropdown */
      width: 100%;
      left: 0 !important;
      right: 0;
      padding-top: 20px;

      .select2-dropdown { width: 100% !important; }

      ul li { color: white; font-size: 18px; }
      ul li:nth-of-type(odd) { background-color: #2f2f2f; }
      ul li:nth-of-type(even) { background-color: #545454; }
      ul li.select2-results__option--highlighted[aria-selected] { background-color: @azul; }
   }
}

/* ---------------------------------------------- 
estilos unicos desktop
----------------------------------------------- */
@media (min-width: @screen-sm-min) {

   .cargando { top: 350px; } /* cargador */

   .agenda-eventos .pane-title { .hidden(); }

   .agenda-eventos .view-filters form { /* contenedor */
      position: relative;
      width: 83.33333333%;
      margin: auto;
      min-width: 1124px;
      max-width: 1300px;

      padding-left: 15px;
      padding-right: 15px;
   }

   .agenda-eventos .view-filters form::after { /* poniendo icono */
      content: 'H';
      position: absolute;
      display: block;
      left: -81px;
      top: 65px;

      font-family: "cartel-urbano" !important;
      font-size: 50px;
      color: #9b9b9b;
   }

   .agenda-eventos .view-filters .views-exposed-widget { /* filtro item */
      height: 195px;

      &.views-reset-button::before { border-color: transparent; }
      &::before { /* flecha blanca superior */
         content: '';
         position: absolute;
         display: block;
         top: 0;
         left: 50%;
         .translate(-50%,0);

         border-color: white transparent transparent transparent;
         border-style: solid;
         border-width: 25px 25px 0 25px;
      }

      &::after {
         content: '';
         position: absolute;
         display: block;
         top: 40px;
         left: 0;
         width: 1px;
         height: 120px;
         background-color: #7b7b7b;
         z-index: 2;
      }

      label {
         padding-top: 50px;
      }

      .form-submit {
         margin-top: 83px;
         font-size: 18px;
      }
   }

   .select2-container { padding-bottom: 50px; }

   .select2-container--open { /* dropdown abierto */
      .select2-selection__rendered {
         position: relative;
         &::after {
            content:'';
            position: absolute;
            display: block;
            top: 0;
            left: -15px;
            right: -15px;
            bottom: 0;
            border: 2px solid @azul;
         }
      }
   }

   .select2-container .select2-selection--single .select2-selection__rendered { /* item seleccionado */
      font-size: 36px;
   }

   /* dropdown -------------------------------- */
   .select2-dropdown ul li {
      font-size: 24px;
      border-bottom: 1px solid black;
   }

   .select2-dropdown { /* dropdown */
      border: 4px solid #07b9de;

      &::before {
         content:'';
         display: block;
         position: absolute;
         top: -14px;
         left: 50%;

         .translate(-50%,0);

         border-color: transparent transparent #07b9de transparent;
         border-style: solid;
         border-width: 0 10px 10px 10px;
      }
   }
}
