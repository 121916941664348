.page-taxonomy-term .region-top .cabezote-seccion{
    img {
       visibility: visible !important;
       display: block !important ;
    }

   .titulo-seccion {
       background: transparent;

       h1::after {
           display: none;
       }
    }
}

.page-taxonomy-term 
.one-column-main.cartel-panel 
.panel-pane.pane-views.pane-nodequeue-1 {
    margin: 0;
    padding: 0;
}

.page-taxonomy 
.view-mode-2018_home_double_card {
    display: block;
    .field-name-field-imagen-apaisada {
        width: calc(100% + 30px);
        position: relative;
        left: -15px;
        padding: 0;
    }

    .group-right {
        width: 100%;
        padding: 0;
        text-align: center;
        .field-name-title {
            position: static;
            width: 100%;
            a {
                color: black;
            }
        }
    }
}

.page-taxonomy .pane-content 
.view-mode-2018_home_feed_card {
    flex-basis: 100%;
}
