/*------------------------------------------------------------*/
/*---------------------interna-edicion-impresa----------------*/
/*------------------------------------------------------------*/
.page-taxonomy{/*cambiar por nuevo vocabulario body*/
   .block{
      .region-top{
         position: relative;
         .field-name-imagen-fondo-mobile{
            display: block;
            width: 180%;
            height: 600px;
            margin-top: -40px;
            .field-name-field-imagen-campo-edicion{
               display: block;
            }

            img{
               width: 239%; /*ajuste provisional poner img desde cms*/
               height: auto;
            }
         }
         .field-name-field-imagen-campo-edicion{/*img banner superior*/
            display: none;
            img{
            }
         }
         .group-revista-imagen{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .field-name-field-caratula{/*img edicion*/
               img{
                  width: 140%;
               }
            }/*/img edicion*/
            .field-name-field-numero-edicion{/*titulo nuemero de edicion*/
               width: 100%;
               font-size: 23px;
               .field-label{
               }
               .field-items{
                  a{
                  }
               }
            }/*/nuemero edicion*/
            .field-name-field-entradilla-edicion-impresa{
               width: 100%;
            }
         }/*/group-revista-imagen*/
      }/*/region-top*/

      /*------------------nodos----------------*/
      .pane-edicion-impresa-panel-pane-1{
         width: 100%;
         .views-row{
            width: 100%;
            margin: 0px;
            margin-top: 15px;
         }
      }
   }/*/block*/
   .page-taxonomy .block .pane-edicion-impresa-panel-pane-1 .views-row {
      margin: 0;
      margin-top: 15px
   }
}/*/.page-taxonomy*/
/*margen footer*/
.one-column-bottom .region-bottom.col-fluid, .two-8-4-column .region-bottom.col-fluid {
   //margin-top: 0px !important;
   margin-top: 0px ;
}
/*barra blanca footer */
footer.footer.container-fluid {
   padding-top: 0 !important;
}
