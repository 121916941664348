/* flexslider common
===================================================== */
.flexslider {
	border: none;
	border-radius: 0;
	box-shadow: none;
}

.flexslider .slides img {
	width: 100%;
	height: auto;
}

.flexslider ul.flex-direction-nav a,
.flexslider-processed ul.flex-direction-nav a,
#flexslider-items-submenu ul.flex-direction-nav a {
	display: block;
	width: 32%;
	top: 20px;
	bottom: 0;
	height: auto;
	opacity: 1;

	text-indent: -99999px;
	font-size: 0;

	&.flex-next,
	&.flex-prev {
		&::before {
			.transition(all 0.30s ease-in-out);
			content: '';
			display: block;
			position: absolute;
			width: 200%;
			min-height: 200%;
			border: 6px solid rgba(255,255,255,0.7);

			border-top: 4.9px solid rgba(255,255,255,0.7);
			border-bottom: 4.9px solid rgba(255,255,255,0.7);

			-moz-transform: rotate(-65deg) skewX(-45deg);
			-webkit-transform: rotate(-65deg) skewX(-45deg);
			-o-transform: rotate(-65deg) skewX(-45deg);
			-ms-transform: rotate(-65deg) skewX(-45deg);
			transform: rotate(-65deg) skewX(-45deg);
		}
	}

	&.flex-next { 
		right: -33%;

		&:hover {
			&::before { left: -50%; }
		}

		&::before {
			left: -80%;
			bottom: 2%;

			-webkit-transform-origin: bottom center;
			-ms-transform-origin: bottom center;
			transform-origin: bottom center;
		}
	}

	&.flex-prev { 
		left: -33%;

		&:hover {
			&::before { right: -50%; }
		}

		&::before {
			right: -80%;
			top: 2%;

			-webkit-transform-origin: top center;
			-ms-transform-origin: top center;
			transform-origin: top center;
		}
	}

	/* stilos moviles */
	@media (max-width: @screen-sm-min) {
		width: 28%;

		&.flex-next,
		&.flex-prev {
			opacity: 0.5 !important;
			&::before { 
				border-color: @negro;
				-moz-transform: rotate(-57deg) skewX(-45deg);
				-webkit-transform: rotate(-57deg) skewX(-45deg);
				-o-transform: rotate(-57deg) skewX(-45deg);
				-ms-transform: rotate(-57deg) skewX(-45deg);
				transform: rotate(-57deg) skewX(-45deg);
			}
		}
	}
}

/* slider principal home
===================================================== */
#slideprincipal, #slider-eventos {
	margin-bottom: 0;

	.flex-direction-nav { .hidden; }

	.flex-control-nav.flex-control-paging {
		bottom: 0;
		li {
			a {
				border-radius: 0;
				background-color: white;
				.rotate(45deg);

				&.flex-active {
					background-color: @azul;
					&::before {background-color: @azul;}
				}
				/* linea paginador ----------- */
				@media (min-width: @screen-sm-min) {
					&::before {
						content: '';
						position: absolute;
						width: 50px;
						height: 1px;
						left: -19px;
						top: 220%;
						background-color: white;
						transform: translate3d(-36%, -93%, 0) rotate(-45deg);
						-webkit-transform: translate3d(-36%, -93%, 0) rotate(-45deg);
						-moz-transform: translate3d(-36%, -93%, 0) rotate(-45deg);
						-ms-transform: translate3d(-36%, -93%, 0) rotate(-45deg);
						-o-transform: translate3d(-36%, -93%, 0) rotate(-45deg);
					}
				}
			}
		}
	}
	.ds-1col.view-mode-slider_home_eventos{
		.field.field-name-field-enlace.field-type-link-field{
			.field-item.even{
				a{
					color: transparent;
					&:before {
						width: 100%;
						height: 100%;
						content: "";
						position: absolute;				
					}
				}
			}
		}
	}
	@media (min-width: @screen-sm-min) {
		.flex-control-nav.flex-control-paging {
			width: 30px;
			left: 40px;
			bottom: 50px;

			li {
				position: relative;
				margin: 10px 0;
				display: block;
				max-width: 30px;
			}
		}
	}

	@media (max-width: @screen-sm-min) {
		.flex-control-nav.flex-control-paging {
			height: 25px;
			background: rgba(0,0,0,0.7);
			li {
				margin: 7px 6px;
			}
		}
		.flex-direction-nav { .hidden; }
	}
}

/* slider galerias home
===================================================== */
#flexslider-1 {
	width: 60%;
	margin: auto;

	.flex-viewport {
		
		overflow: visible !important;

		&::after,
		&::before {
			content: '';
			position: absolute;
			width: 50%;
			min-height: 100%;
			top: 0;
			bottom: 0;
			
			background-color: rgba(255,255,255,0.7);
			z-index: 2;
		}

		&::before { left: -50%; border-right: 6px solid white; }
		&::after { right: -50%; border-left: 6px solid white; }

		/* stilos desktop */
		@media (min-width: @screen-sm-min) {
			&::before,
			&::after {
				background-color: rgba(0,0,0,0.7);
				border: none; 
			}
		}

	}
}

/* slider CARTEL TV 
===================================================== */
#flexslider {

	width: 60%;
	margin: auto;

	.flex-viewport {
		overflow: visible !important;

		&::after,
		&::before {
			content: '';
			position: absolute;
			width: 50%;
			min-height: 100%;
			top: 0;
			bottom: 0;
			
			background-color: rgba(255,255,255,0.7);
			z-index: 2;
		}

		&::before { left: -50%; border-right: 6px solid white; }
		&::after { right: -50%; border-left: 6px solid white; }

		/* stilos desktop */
		@media (min-width: @screen-sm-min) {
			&::before,
			&::after {
				background-color: rgba(0,0,0,0.7);
				border: none; 
			}
		}
	}

	ul.flex-direction-nav {

		a { opacity: 1; }

		a.flex-prev {
			left: 0;
		}

		a.flex-next {
			right: 0;
		}
	}
}
#flexslider ul.flex-direction-nav a.flex-next::before,
#flexslider ul.flex-direction-nav a.flex-prev::before { .hidden(); }

#flexslider ul.flex-direction-nav a.flex-next,
#flexslider ul.flex-direction-nav a.flex-prev { width: 20%; }
/*
#flexslider ul.flex-direction-nav a.flex-next::before {
	left: -80%;
	top: -117%;
	bottom: initial;
	transform: rotate(-60deg) skewX(-45deg);
}

#flexslider ul.flex-direction-nav a.flex-next:hover::before { left: -70%; }
*/
/* slider CONTENIDO RELACIONADO
===================================================== */
.pane-cartel-section-header { background-color: @negro; }

#related_content_slider {
	position: static !important;
	width: 80%;
	margin: auto;

	.flex-viewport {
		overflow: visible !important;

		&::after,
		&::before {
			content: '';
			position: absolute;
			width: 50%;
			min-height: 100%;
			top: 0;
			bottom: 0;
			
			background-color: rgba(0,0,0,0.7);
			z-index: 2;
		}

		&::before { left: -50%; border-right: 6px solid white; }
		&::after { right: -50%; border-left: 6px solid white; }

		/* stilos desktop */
		@media (min-width: @screen-sm-min) {
			&::before,
			&::after {
				border: none; 
			}
		}
	}

	ul.slides {
		li {
			> .ds-2col {
				.transition(opacity 0.5s ease-in-out);
				position: relative;
				//overflow: hidden;
				margin: 10px 1%;
				border: 1px solid white;

				/* stilos desktop */
				@media (min-width: @screen-sm-min) {
					&::before {
						content:'';
						display: block;
						padding-top: 22.3%; /* aspect ratio */
					}
				}

				/* titulo */
				h2 { 
					margin: 0;
					padding:10px;
					line-height: 18px;
					font-size: 18px;
					color: white;
					a { color: white; } 
				} 

				/* fecha */
				.field-name-post-date {
					position: absolute;
					width: 80%;
					top: -30px;
					right: 0;
					color: white; 
				}

				/* icono, seccion */
				.field-name-field-seccion {
					position: relative;
					overflow: hidden;
					height: 35px;
					border-bottom: 1px solid white;

					line-height: 35px;

					.field-name-field-icono,
					.field-name-title {
						float: left;
						text-align: center;
					}

					.field-name-field-icono {
						width: 20%;
						background-color: white;
						font-size: 21px;
						color: @negro;
					}

					.field-name-title {
						width: 80%;

						/* nombre seccion */
						h3 {
							margin: 0;
							line-height: 36px;
							font-family: @strait;
							font-size: 18px;
							color: white;
						}
					}
				}

				/* stilos desktop */
				@media (min-width: @screen-sm-min) {
					float: left;
					width: 48%;
					opacity: 0.7;

					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}

	/* stilos desktop */
	@media (min-width: @screen-sm-min) {
		.ds-2col > .group-left {
			position: absolute;
			width: 60%;
			right: 0;
			top: 0;
			bottom: 0;
		}

		.ds-2col > .group-right {
			position: absolute;
			width: 40%;
			left: 0;
			top: 0;
			bottom: 0;
			border-right: 1px solid white;
		}
	}

	/* stilos moviles */
	@media (max-width: @screen-sm-min) {
		.ds-2col > .group-left,
		.ds-2col > .group-right {
			width: 100%;
		}

		.ds-2col > .group-left {
			border-bottom: 1px solid white;
		}
	}

	ul.flex-direction-nav {

		a { opacity: 1; }

	}
}

/* ajustes flechas ======================= */
#related_content_slider ul.flex-direction-nav a {
	width: 10%;
	&.flex-prev { 
		left: 0;
		&::before { top: -13%;}
	}

	&.flex-next { 
		right: 0;
		&::before {bottom:-20%;} 
	}
}

/* ajustes cartel tv ======================= */
body.page-carteltv .region-top ul.flex-direction-nav a {
	width: 13%;
}

/* ajustes flex submenu ========================== */
#flexslider-items-submenu ul.flex-direction-nav a {
	width: 16.333333%;
	overflow: hidden;
	position: absolute;
	z-index: 100;

	&.flex-prev::before {
		top: -90%;
		right: -60%;
	}
	&.flex-next::before {
		bottom: -90%;
		left: -60%;
	}
}

/* global moviles ---------------------------------- */
@media (max-width: @screen-sm-min) {
	ul.flex-direction-nav a.flex-next::before,
	ul.flex-direction-nav a.flex-prev::before { .hidden(); }
}


