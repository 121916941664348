/*DESTACADO PRINCIPAL*/
.view-sliderhome .attachment-before{
   .group-datos{
      display: none;
   }
}
/*Generales*/
.region.row{
   margin: 0 ;

}

.main-logo-home{
   top: 0px !important;
}
.block-cu-menu-general{
   height: 60px !important;
   margin: 0px !important;
   position: fixed !important;
   display: block;
   width: 100%;
}
#block-system-main-menu {
   top: 20px !important;
   z-index: 1000 !important;
}
.view-slider-home-mobile.view-display-id-block{
   padding: 15px;
}
.view-slider-home-mobile{
   .view-slider-home-mobile{
      .group-division .group-datos > div{
         padding: 5px 10px;
         display: inline-block;
         vertical-align: bottom;
      }
   }
   .more-link{
      display: none;
   }
   .views-row{
      position: relative;
      margin-bottom: 15px;
      -webkit-box-shadow: 9px 10px 19px -3px rgba(0,0,0,0.51);
      -moz-box-shadow: 9px 10px 19px -3px rgba(0,0,0,0.51);
      box-shadow: 9px 10px 19px -3px rgba(0,0,0,0.51);
      img{
         width: 100%;
         height: auto;
      }

      .group-division{
         //position: absolute;
         position: relative;
         bottom: 0px;
         width: 100%;
         div, span, a, p{
            color: #000000;
         }
         .field-name-field-icono{
            display: none;
         }
         .group-datos-destacados {
            & > div{
               display: inline-block;
               padding: 5px 10px;
               height: 24px;
               line-height: 13px;
               a, div, h3{
                  font-size: 13px;
                  margin: 0px;
               }
            }
         }
      }
      .group-datos > div{
         padding: 0px 10px;
      }
      .group-datos-destacados{
         display: none;
      }
      .group-slider-textos{
         background: rgba(255, 252, 252, 0.5);
         padding: 0px 10px 10px;
         .field-name-title{
            h2{
               margin-top: 0px;
            }
         }
      }
   }
}

.view-sliderhome{
   .group-datos-destacados > div{
      background: @color-musica;
   }
}
/*Especificación de colores por sección*/


.view-slider-home-mobile .taxonomy-term-historias{ //HISTORIAS
   .group-datos > div, .group-datos-destacados > div{
      background: @color-historias;
   }
}

.view-slider-home-mobile .taxonomy-term-noticias{ //NOTICIAS
   .group-datos > div, .group-datos-destacados > div{
      background: @color-noticias;
   }
}

.view-slider-home-mobile .taxonomy-term-música{ //MÚSICA
   .group-datos > div, .group-datos-destacados > div{
      background: @color-musica;
   }
}
.view-slider-home-mobile .taxonomy-term-420{ //420
   .group-datos > div, .group-datos-destacados > div{
      background: @color-420;
   }
}

.view-slider-home-mobile .taxonomy-term-moda{ //MODA
   .group-datos > div, .group-datos-destacados > div{
      background: @color-moda;
   }
}

.view-slider-home-mobile .taxonomy-term-arte{ //ARTE
   .group-datos > div, .group-datos-destacados > div{
      background: @color-arte;
   }
}

.view-slider-home-mobile .taxonomy-term-música{ //DEPORTES
   .group-datos > div, .group-datos-destacados > div{
      background: @color-deportes;
   }
}
.view-slider-home-mobile .taxonomy-term-sexo{//SEXO
   .group-datos > div, .group-datos-destacados > div{
      background: @color-sexo;
   }
}
.view-slider-home-mobile .taxonomy-term-opinion{//OPINIÓN
   .group-datos > div, .group-datos-destacados > div{
      background: @color-opinion;
   }
}
.view-slider-home-mobile .taxonomy-term-diseno{//DISEÑO
   .group-datos > div, .group-datos-destacados > div{
      background: @color-diseno;
   }
}

#node_article_destacados_home_general_group_hover > div.field.field-name-title.field-type-ds.field-label-hidden > div > div > h1{
   text-align:center;
   font-size: 30px;
}
#node_article_destacados_home_general_group_contenedor_fecha{
   display: none;
}