/* pautas ----------------------------------- */
.region-publicidad,
.publicidad {
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.not-front.page-node .field.field-name-publicidad-980{
	text-align: center;
	padding-bottom: 11px;
}

.not-front.page-node .field-name-publicidad-2 {
	margin-bottom: 12px;
	overflow: hidden;
	span{
		display: block;
		a{
			width: 100% !important;
			min-height: 120px;
			@media (max-width: 1366px){
				min-height: inherit;
			}
		}
	}
}
/*Banner Duplicado*/
.not-front.page-node .field-name-ads-before-comments span a{
	width: 100% !important;
}

/*Banner Interna Articulos*/
.not-front.page-node .field.field-name-publicidad-980 span a{
	width: 100% !important;
}

.page-node .region-top{
	.publicidad {
		position: relative;
		padding-top: 10%;
		@media (max-width: 1366px) {
			padding-top: 15%;
		}
		//background-color: #dfdfdf;
		a{ width: 100% !important;
			height: 100% !important;
		}
		img {
			width: auto; max-width: none;
			width: 100% !important;
			height: auto !important;
		}
	}

	.field-name-agenda-publicidad{
		margin-top: 5.5em;
	}

}

/*pauta node full region*/
.page-node .region-top,
.page-node .one-column-top{
	.publicidad img {
		width: auto !important;
		max-width: none;
		height: auto !important;
	}
	.field-name-agenda-publicidad{
		margin-top: 5.5em;
	}
}

.page-node .region-top{
	.publicidad > div {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		width: 100% !important;
	}
}

.page-node.node-type-gallery .region-right .publicidad {
	padding-top: inherit;
	margin-bottom: inherit;
	padding: 6em 0 2em 0;

}
.region-right {
	.publicidad {
		box-shadow: 5px 5px 20px 0 rgba(50,50,50,0.77);
		a{
			width: 100% !important;
			height: auto !important;
			img{
				width: 100%;
			}
		}
	}
}
/* Publicidad interna nodos arriba de comentarios */
.page-node- .field-name-ads-before-comments{
	height: auto;
	text-align: center;
	margin-bottom: 71px;
	display: block;
}


/*    pag eventos   */
.node-type-eventos{
	.region-right{
		.field-name-agenda-publicidad .publicidad{
			margin-top: 50px;
			padding: 0 !important;
		}
	}
}
/*   pag galeria    */
.page-galerias{
	.panel-pane.pane-cartel-section-publicidad{
		padding-top: 3em;
		padding-bottom: 0 !important;
		@media (max-width: 768px){
			padding-top: 4em;
			position: relative;
			top: 2.5em;
		}
		.publicidad a{
			width: 100% !important;
			height: auto !important;
			img{
				width: 100%;
				height: auto;
			}
		}
	}
	.field-name-dfp-728-new-gallery > .field-items{
		position: absolute;
		top: initial;
		left: initial;
		width: 53%;
		overflow: hidden;
		transform: translateY(-100px);
	}
}
/*VIDEO EN HOME*/
.field-name-publicidad-dfp {
	position: relative;
    top: 50%;
    transform: translate(0%, -50%);
}
/*PUBLICIDAD AGENDA*/
#dfp-ad-cartel_agenda_728x80 > div {
    text-align: center;
	margin-bottom: 15px;
}
.field-name-home-1-300x250{
	position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/* Moviles
=============================================================== */
@media (max-width: @screen-sm-min) {
	/* pautas
	------------------------------------------ */
	.region-publicidad,
	.publicidad {
		padding: 0 !important;
		object,embed { width: 100%; }
	}

	.page-taxonomy .pane-cartel-section-publicidad .publicidad > div {
		width: 100%;
	}
	.page-node .field-name-publicidad-2 {
		span{
			a{
				height: 60px !important;
			}
		}
	}


	//region-left internas
	.node-type-article{
		.region-left{
			.field-name-publicidad{
				margin: 1em 0;
				// width: 91%;
				margin: auto;
				.publicidad {
					width: 90%;
					margin: auto;
					box-shadow: 5px 5px 20px 0 rgba(50,50,50,0.77);
					> div{
						padding: 0rem;
						text-align: center;
						span{
							position: relative;
							display: block;
							text-align: center;
							a{
								width: 100% !important;
								height: auto !important;
								img{
									margin: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	/*Publicidad interna articulos*/
	.field-name-publicidad{
		div {
			text-align: center;
		}
	}
	.pane-dfp-pane{
		text-align: center;
	}		
}

/*Nuevas CLases Publcidad
------------------------------------*/

/*home banner horizontal superior*/
#dfp-ad-cartel_home_870x90 {
	div{
		text-align: center;
		margin-bottom: 15px;
		padding-bottom: 20px;
	}
}



/* Desktop
=============================================================== */
@media (min-width: @screen-sm-min) {}



.front{
	.node-publicidad{
		img{
			max-width: initial !important;
		}
	}
}