/* INTERNA EVENTOS
======================================== */
.node-type-eventos {

   /* icono evento - nombre oculto */
   .field-name-field-tipo-de-evento {
      .field-label,
      h2 { .hidden;
      }
   }

   .field-name-field-entradilla {
      font-size: 14px;
      text-align: left;
      font-family: "lato";
   }

   a.entradilla-sidebar-eventos {
      color: black;
   }
   .field-name-field-direccion {
      margin-bottom: 20px;
   }

   .field.field-name-block-fieldeventos-mas-populares{
      margin-top: 3em;
   }
   .group-titulo {
      position: relative;

      .field-name-field-tipo-de-evento {
         /* icono - tipo */
         .field-name-field-icono .field-items {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 6px;
            left: -70px;

            text-align: center;
            line-height: 50px;
            font-size: 34px;
            color: white;

            z-index: 2;

            &::after {
               content: "";
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               min-height: 100%;
               background-color: @azul;
               z-index: -1;

               .rotate(45deg);
            }
         }
      }

      /* estilos moviles ====================== */
      @media (max-width: @screen-sm-min) {
         .field-name-title {
            padding-left: 60px;
         }

         .field-name-field-seccion .field-name-field-icono .field-items {
            left: 0;
            top: 0;
            .scale(0.6);
         }
      }
   }

   /* Grupo imagen eventos
   ===================================================== */
   .group-imagen-evento {
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;

      @media (min-width: @screen-sm-min) { /* desktop */
         .group-info-evento {
            position: absolute;
            width: 235px;
            min-height: 100%;
            right: 10%;
            top: 0;

            text-align: center;
            font-family: @bebas;
            color: @negro;

            &::before {
               content:'';
               display: block;
               width: 100%;
               height: 60px;

               background: url(../img/top-interna-eventos.png) no-repeat center top;
               background-size: 100%;
            }

            &::after {
               content: '';
               display: block;
               width: 100%;
               min-height: 500px;
               background-color: white;
            }

            .field-name-field-fecha {
               background-color: @azul;
               line-height: 70px;
               font-size: 60px;
               color: white;
            }

            .field-name-field-donde,
            .field-name-field-icono,
            .field-name-field-precio { background-color: white; padding-top: 35px; }

            .field-name-field-donde {
               .field-label { font-weight: normal; }
               .field-item { font-size: 24px; line-height: 32px; }
            }

            .field-name-field-precio {
               &::before {
                  content:'CUÁNTO';
                  display: block;
               }

               .field-item {
                  font-size: 30px;
                  line-height: 21px;
               }
            }

            .field-name-field-icono {
               padding-top: 28px;
               height: 60px;
               font-size: 70px;
               line-height: 60px;
            }
         }
      }
   }

   @media (max-width: @screen-sm-min) { /* movil */
      .group-imagen-evento .group-info-evento .field-name-fecha-corta { .hidden; }
   }

   /* Desde, Hasta, Ciudad
   ================================================= */
   .field-name-field-fecha {
      font-family: @bebas;
      font-size: 30px;
      color: @negro;
   }

   .field-name-field-ciudad {
      border-bottom: 1px solid @negro;
      padding-bottom: 10px;
      margin-bottom: 20px;

      font-family: @bebas;
      font-size: 30px;
      color: @negro;
   }

   img { max-width: 100%; height: auto !important; }
}

/* estilos moviles */
@media (max-width: @screen-sm-min) { /* movil */
   .node-type-eventos .group-titulo .field-name-field-tipo-de-evento .field-name-field-icono .field-items,
   .page-eventos-eventos .group-titulo .field-name-field-tipo-de-evento .field-name-field-icono .field-items {
      top: 26px;
      left: -2px;
      .scale(0.7);
   }
}

@import "agenda/_interna.less";


#node_eventos_full_group_infe_evento{
   position: relative !important;
   margin-top: 160px;
}


.node-type-eventos .two-8-4-column .group-info-evento .field-name-title {
   color: #000 !important;
}

.node-type-eventos .two-8-4-column.cartel-panel .region-right.region-inner.col-sm-3 {
   margin-top: 0 !important;
   position: relative;
   bottom: 4px;
}













/********* Este mes - page **************/

.page-agenda-este-mes *,
.page-agenda-proximamente *{
	font-family: 'Alegreya',serif !important;  	
}
.page-agenda-este-mes .view-display-id-page_1 .view-content .views-row,
.page-agenda-proximamente .view-display-id-page_2 .view-content .views-row{
	width: 33.3%;
}
.page-agenda-este-mes .view-display-id-page_1 .view-content .views-row,
.page-agenda-proximamente .view-display-id-page_2 .view-content .views-row{
	width: 32%;
	display: inline-block;
	vertical-align:middle;
	padding: 20px;
}

.page-agenda-este-mes .view-display-id-page_1 .view-content .views-row .field-name-field-imagen-cuadrada img,
.page-agenda-proximamente .view-display-id-page_2 .view-content .views-row .field-name-field-imagen-cuadrada img{
	width: 400px !important;
	height: 300px !important;
}

#views-exposed-form-agenda-2020-page-1 > div > div > div{
	visibility: hidden !important;
}

@media only screen and (max-width: 600px) {

	.page-agenda-este-mes .view-display-id-page_1 .view-content .views-row,
	.page-agenda-proximamente .view-display-id-page_2 .view-content .views-row{
		width: 92% !important;
	}
	.page-agenda-este-mes .view-display-id-page_1 .view-content .views-row,
	.page-agenda-proximamente .view-display-id-page_2 .view-content .views-row{
		width: 92% !important;
		display: block !important;
		vertical-align:middle;
		padding: 0 !important;
		margin-left: 4%;
	}

	.page-agenda-este-mes .view-display-id-page_1 .view-content .views-row .field-name-field-imagen-cuadrada img,
	.page-agenda-proximamente .view-display-id-page_2 .view-content .views-row .field-name-field-imagen-cuadrada img{
		width: 100% !important;
		height: auto !important;
	}

	.view-header>div>span.s:after {
		display:none;
	}

}
