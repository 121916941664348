@media (max-width: 768px) {
   .node-type-gallery {

      .gallery-modal-unique{
         .close-gallery{/*btn close*/
            top: 12%;
            padding: 1px 9px;
            font-size: 19px;
         }/*/.close-gallery{*/

         ul.slides{/*lista de img's*/

            li{
               img{
                  max-height: 600px;
                  position: fixed;
                  left: 50%;
                  top: 50%;
                  max-width: 70%;
                  transform: translate(-50%, -50%);

               }
            }
         }/*/slides*/

         ol.flex-control-nav{/*nav bar gallery*/
            bottom: 0;
            padding: 0;
            li{
               display: inline-block;
               margin-right: 7px;
               cursor: pointer;
               a{
                  color: @blanco;
                  &.flex-active{
                     color: @blanco;
                     font-size: 16px;
                     border: 1px solid;
                     border-radius: 50%;
                     padding: 1px 7px 4px 8px;
                  }
               }
            }
         }/*/.flex-control-nav*/

         ul.flex-direction-nav{/*btn previus next*/
            li{
               a{
                  font-size: 0;
               }
               a.flex-prev{
                  &::before{
                  }
               }
               a.flex-next{
                  &::before{
                  }
               }
            }
         }/*/ul.flex-direction-nav*/

      }/*/ .gallery-modal-unique*/

   }/*/.node-type-gallery*/
}
