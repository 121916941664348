/* cometarios
================================================================ */
.group-comentarios {
	.clearfix();
	clear: both;
}
/* titulo principal ---------------------------------------- */
.group-comentarios > h2 {
	position: relative;
	padding-top: 50px;
	padding-bottom: 20px;

	span {
		display: block;
		padding: 5px;
		margin: auto;
		background-color: @azul;

		text-align: center;
		font-size: 32px; 
		color: white;
		}

	&::after {
		content: 'm';
		display: block;
		margin-bottom: 5px;
		text-align: center;
		line-height: 11px;
		font-family: "cartel-urbano";
		font-size: 24px;
		color: #07b9e3;
	}
}

/* tabs -------------------------------------------------- */
.group-comentarios .group-comentarios-fb > h3,
.group-comentarios .group-comentarios-drupal > h3 { 
	position: relative;
	cursor: pointer;
	margin: 0;

	&::after {
		content:'';
		position: absolute;
		display: block;
		width: 30px;
		height: 30px;
		right: 0;
		top: 0;
		background: url(../img/flecha-comments.png) no-repeat;
	}

	span { 
		display: block;
		padding: 10px;
		background-color: black;
		color: white; 
	}
}

.group-comentarios .group-comentarios-fb,
.group-comentarios .group-comentarios-drupal {
	.transition(all 0.6s ease-in-out);
	position: relative;
	overflow: hidden;
	background-color: #f3f3f4;

	&.active {
		height: auto;
	}

}

/* panels -------------------------------------------- */
.group-comentarios .field-name-comentarios {
	font-size: 13px;
	padding: 15px 10px;
	background-color: rgb(210, 210, 210);

	a {
		background-color: rgb(108, 108, 110);
		padding: 2px 10px;
		margin: 0 5px;
		text-transform: uppercase;
		font-family: @strait;
		color: white;
	}
}

.group-comentarios .field-name-comment3 {
	.comment {padding: 5px 10px;}
	.comment:nth-of-type(odd) { background-color: #F9F9F9;}
}

/* solo para split layout 
================================================================= */
.layout-split .group-comentarios {
	padding-bottom: 80px;

	> h2 { padding-bottom: 0; }

	.group-comentarios-fb, 
	.group-comentarios-drupal {
		height: 35px;

		&.active { height: auto; }
	}
}

.layout-split .group-comentarios h3 span { 
	font-size: 16px;
	height: 34px;
}

/* Moviles
=============================================================== */
@media (max-width: @screen-sm-min) {
	.group-comentarios .group-comentarios-fb,
	.group-comentarios .group-comentarios-drupal { height: 40px; }
	/* tabs -------------------------------------------------- */
	.group-comentarios h3 { font-size: 18px; }

	/* Login drupal ------------------------------- */
	.group-comentarios .field-name-comentarios { font-size: 11px; padding: 15px 2px; }
}

/* Desktop
=============================================================== */
@media (min-width: @screen-sm-min) {
	.group-comentarios .group-comentarios-fb,
	.group-comentarios .group-comentarios-drupal { height: 45px; }
	.group-comentarios h3 { font-size: 22px; }
}



#node_article_detalle_sidebar_group_comentarios_drupal, #node_eventos_full_group_comentarios_drupal {
  display: none;
}





