.block-cu-mas-popular,
.pane-cu-mas-popular-los-eventos-mas-popular{
   width: 100%;
   //height: 372px;/*una fila*/
   height: 760px;/*dos filas*/
   box-sizing: border-box;
   clear: both;
   //box-shadow: 5px 5px 20px 0 rgba(50,50,50,0.77);
   float: right;
   background-color: #f4f4f4;
   overflow: auto;
   /*@media (max-width: 1366px) {
      //height: 685px;
       height: 765px;
   }*/
   .pane-title, .block-title{
      background-color: #07b9e3;
      margin: 0;
      color: #fff;
      font-size: 35px;
      text-align: left;
      padding-left: 20px;
      &::before{
         content: " ";
         position: relative;
         display: inline-block;
         width: 40px;
         height: 40px;
         top: 5px;
         background: url(../img/icono-LoMasPopular.png);
         background-repeat: no-repeat;
         background-size: 55%;
         z-index: 999;
      }
   }
   .pane-content, .contenedor-popuar{
      padding: 10px;
      .popular-container{
         .popular-item{
            padding: 10px;
            text-align: center;
            //border-bottom: 1px dashed rgba(0, 0, 0, 0.46);
            width: 100%;
            display: inline-block;
            display:none;
            &:nth-child(0),
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5)
            {
               display: inline-block;
            }

            .popular-item-information{
               a{
                  img{
                     width: 31%;
                     float: left;
                     vertical-align: top;
                  }
                  .column-float{
                     width: 66%;
                     float: left;
                     color: #000;
                     margin-left: 3%;
                     vertical-align: top;
                     h4{
                        font-weight: bold;
                        text-align: left;
                        font-size: 22px;
                        letter-spacing: 1px;
                        margin-top: 0;
                     }
                     p{
                        text-align: left;
                        line-height: 14px;
                     }
                  }
               }
            }
            /*h2{
            margin: 0;
            font-size: 23px;
         }
            &:nth-of-type(7)   {
            border: none;
         }*/
         }
      }
   }
   &::-webkit-scrollbar{
      width: 6px;  /* for vertical scrollbars */
      height: 10px; /* for horizontal scrollbars */
   }

   &::-webkit-scrollbar-track{
      background: rgba(0, 0, 0, 0);
   }

   &::-webkit-scrollbar-thumb{
      background: rgba(7, 185, 227, 0.5);
      border-radius: 10px;
   }
}



.full .block-cu-mas-popular, .pane-cu-mas-popular-los-eventos-mas-popular {
   @media (max-width: 1366px) {
      width: 100%;
      height: 765px;
   }
}

@media (max-width: 767px) and (min-width: 320px) {
   .pane-cu-mas-popular-los-eventos-mas-popular, .block-cu-mas-popular{
      width: 100% !important;
      height: auto;
      margin: 0 auto;
      box-sizing: border-box;
      clear: both;
      float: right;
      margin: 13px;
      margin-right: 0;

      .pane-content, .contenedor-popuar{
         padding: 10px;
         .popular-container{
            .popular-item{
               margin-left: 0;
            }
            .popular-item:nth-child(6) {
               display: none;
            }
         }
      }
   }
}
