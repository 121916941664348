/* panels layouts */

.col-fluid { /* fix padding for full width */
	padding-left: 0;
	padding-right: 0;
}

/* bloques */

.cartel-block {
	img {
		.img-responsive;
		width: 100%;
	}
}