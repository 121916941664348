/*-----------------------------------------------------------------------*/
/*---------------------------------nodo----------------------------------*/
/*---------------------------------home-s---------------------------------*/
/*-----------------------------------------------------------------------*/
.region-galerias{
   .panel-pane{
      &.pane-nodequeue-5, .view-id-ver_mas_home{
         ul{
            li.view-mode-card_destacado_home{
               width: 100% !important;
               margin: 0 auto;
            }
            li.views-row{
               width: 100%;
               margin: 0 auto;
               margin-top: 10px;
            }/*fin li*/
         }/*fin ul*/
      }
   }
}



