@media (max-width: @screen-sm-min) {
    .view-sliderhome {
        margin-top: 0;
    }
    .view-sliderhome .view-content ul li {
        padding: 0;
        .ds-2col {
            display: block;

            &>.group-left {
                width: 100%;
                padding: 0;
            }

            &>.group-right{
                width: 100%;
            }

            h2 {
                font-size: 22px;
                height: auto;
                margin: 10px 0 5px;
            }

            #node_article_2018_home_main_card_group_2018_main_card_date_secc {
                text-align: center;
            }
        }
    }

    .home-panel {
        margin-top: 0;

        .region-galerias .item-list ul:not(.contextual-links) li {
            width: 100%;

            h2 {
                height: auto;
                margin-bottom: 20px;
                font-size: 22px
            }

            .field-type-taxonomy-term-reference a {
                font-size: 22px;
            }
        }

        .pane-nodequeue-10, 
        .home-panel .pane-2018-feed-ver-mas {
            margin: 0;
            .view-content {
                display: block;

                & >.views-row {
                    width: 100%;
                }
            }
        }
    }

    .home-panel .pane-nodequeue-10 .view, 
    .home-panel .pane-2018-feed-ver-mas .view {
        margin: 0;
    }

    .home-panel .pane-nodequeue-10 .view-content>.views-row, 
    .home-panel .pane-2018-feed-ver-mas .view-content>.views-row {
        flex-basis: 100%;
    }
}