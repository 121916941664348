.view-id-sliderhome * {
    font-family: 'Alegreya', serif;
}
.region-galerias {
    margin-top: 0;
}
.home-panel {
    margin-top: 0px;
    margin-bottom: 20px;
    color: black;
    * {
        font-family: 'Alegreya', serif;
    }
    .row {
        margin: 0;
    }
}

.view-sliderhome .view-content ul li {
    width: 100% !important;
    padding: 0 10px;
    .ds-2col{
        display: flex;
        align-items: center;
        & > .group-left {
            width: 66.6%;
            float: none;
            padding-right: 10px;
        }

        .field-name-field-imagen-cuadrada:before {
            display: none;
        }

        & > .group-right {
            width: 33.3%;
            float: none;
            padding: 0 20px;

        }

        h2 {
            text-align: left;
            font-size: 36px;
            margin-bottom: 40px;
        }

        img {
            width: 100%;
            height: 100%;
        }
        
    } 
}

#node_article_2018_home_main_card_group_2018_main_card_date_secc > div{
    display: inline-block;

    &:nth-child(2){
        font-weight: bold;
        font-size: 18px;
        margin-left: 8px;
        padding-left: 8px;
        border-left: 1px solid black;
        line-height: 1;
        a {
            color: black;
        }
    }
}


#node_article_2018_home_feed_card_group_hover .field-name-node-link a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
}

.node-article
.field-type-image {
    overflow: hidden;
    position: relative;
    overflow: hidden;
    img {
        transition: 7s all ease;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}


.home-panel {

    img {
        width:100%;
        height: auto;
    }
    .region-agenda {
        display: none;
    }
    .region-galerias {
        .item-list ul:not(.contextual-links) {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 35px;
            li {
                width: 33.3%;
                padding: 10px;
                h2 {
                    font-size: 22px;
                    padding: 0 20px;
                    text-align: center;
                    line-height: 1.3;
                    font-weight: normal;
                    color: #545454;
                    height: 90px;
                    margin-top: 15px;
                    overflow: hidden;
                    a {
                        color: #666666;
                    }
                }

                .field-type-taxonomy-term-reference {
                    text-align: center;
                    font-weight: bold;
                    font-size: 22px;
                    margin-bottom: 8px;
                    
                    &:after {
                        content: "";
                        display: block;
                        border-bottom: 1px solid black;
                        width: 120px;
                        margin: 0 auto;
                        margin-top: 8px;
                    }
                    
                    a {
                        color: black;
                    }
                }
                .field-name-post-date {
                    text-align: center;
                }
            }
        }
    }


    .pane-nodequeue-10,
    .pane-2018-feed-ver-mas {
        margin: 0 auto;
        max-width: 1250px;
        .view {
            border-top: 1px solid #000;
            padding-top: 40px;
            margin: 0 50px;
        }

        .view-content {
            display: flex;
            flex-wrap: wrap;
            & > .views-row {
                flex-basis: 33.3%;
                padding: 10px;
                margin-bottom: 20px;
                /*
                &.views-row-7 {
                    flex-basis: 66.6%;
                }
                */
            }
            
        }
    }
    .pane-nodequeue-10{
        .view-content>.views-row.views-row-7 {
            flex-basis: 66.6%;
        }
    }

}

.more-link {
    text-align: center;
    font-weight: 500;
    margin: 30px 0;
    position: relative;
    a {
        color: black;
        text-transform: uppercase;
        background: white;
        display: inline-block;
        padding: 0 10px;
        &:after {
            content: "";
            display: block;
            width: 30px;
            margin: 0 auto;
            border-width: 20px 20px 20px 20px;
            border-color: black transparent transparent transparent;
            border-style: solid;
            margin-top: 5px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: black;
        top: 10px;
        left: 0;
        z-index: -1;
    }
}

.view-mode-2018_home_feed_card {
    #node_article_2018_home_feed_card_group_texto {
        position: relative;
        overflow: hidden;
        img {
            transition: 7s all ease;
            width: 100%;
            height: auto;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
    #node_article_2018_home_feed_card_group_hover {
        position: absolute;
        top: 15px;
        left: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        border: 1px solid #989898;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .field-type-taxonomy-term-reference {
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 8px;
        a {
            color: white;
        }
        &:after {
            content: "";
            display: block;
            border-bottom: 1px solid #e0e0e0;
            width: 120px;
            margin: 0 auto;
            margin-top: 8px;
        }
    }
    .field-name-post-date {
        color: white;
        text-align: center;
        font-size: 17px;
    }

    h2 {
        font-size: 24px;
        margin: 20px 0;
    }
    .field-name-field-subtitulo {
        font-size: 18px;
        color: #545454;
    }
}

.field-name-post-date {
    letter-spacing: 1px;
}

.view-nodequeue-10
.views-row-7 {
    flex-basis: 66.6%;
    .view-mode-2018_home_double_card {
        .field-name-field-imagen-apaisada {
            width: 100%;
            float: none;
            padding-right: 0;
        }
    
        .field-group-format-wrapper {
            width: 100%;
            float: none;
            padding: 0 15px;
        }
    
        .field-name-title h2 {
            font-size: 24px;
            line-height: 1;
        }
    
        .field-name-field-subtitulo {
            font-size: 18px;
            color: #545454;
        }
    
    
    
        .group-2018-date-seccion {
            text-align: center;
            margin-top: 8px;
            & > div {
                display: inline-block;
            }
            .field-type-taxonomy-term-reference {
                font-weight: bold;
                font-size: 18px;
                margin-left: 8px;
                padding-left: 8px;
                border-left: 1px solid black;
                line-height: 1;
                display: inline-block;
                a {
                    color: black;
                }
            }
        }
    } 
}
