/* Commons - Social share Facebook, twitter, pinterest
====================================================================================== */



.field-name-social-network {
    position: absolute;
    width: 80px;

    ul,li { list-style: none; padding: 0; margin: 0;
        a::after{
            content: " ";
            position: absolute;
            top: 0;bottom: 0;left: -44px;right: 0;
        }
    }
    ul{
        width: 30px;
        li:nth-child(2), li:nth-child(3){
            .count-share-content{
                background-color: transparent;
                &:after{
                    border:none;
                }
            }
        }
    }
    .msj-share{
        font-size: 16px;
        margin-bottom: 20px;
        font-family: @bebas;
        text-align: center;
    }
}
.field-name-fblikebutton-ds-field{
    top: 335px;
    position: absolute;
    width: 80px;
    left: -80px;
}

@media (max-width: 768px){
    .group-redes-sociales{
        position: static;
        .field-name-social-network{
            position: static;
            width: 100%;
            .redes_sociales{
                display: block;
                width: 100%;
                .msj-share{
                    font-size: 16px;
                    margin-bottom: 20px;
                    font-family: 'bebas_neueregular',Arial-narrow;
                    text-align: center;
                    width: 100%;
                }
                ul{
                    width: 100%;
                    li{
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        ul{
                          text-align: center;
                          position: relative;
                          left: -19px;
                            li{
                                display: inline-block;
                                width: auto;
                                margin-right: 16%;
                                &:last-child {
                                  margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .field-name-fblikebutton-ds-field iframe{
            height: 21px !important;
        }
    }
}


@media (max-width: 1275px){
    .node-type-gallery .group-redes-sociales{
        position: static;
        .field-name-social-network{
            position: static;
            width: 100%;
            .redes_sociales{
                .msj-share{
                    font-size: 16px;
                    margin-bottom: 20px;
                    font-family: 'bebas_neueregular',Arial-narrow;
                    text-align: center;
                    width: 100%;
                }
                ul{
                    width: 100%;
                    li{
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        ul{
                            text-align: center;
                            margin-left: -20px;
                            li{
                                display: inline-block;
                                width: auto;
                                margin-right: 16%;
                                &:last-child {
                                  margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .field-name-fblikebutton-ds-field iframe{
            height: 21px !important;
        }
    }
}


.field-name-social-network .compartido_facebook,
.field-name-social-network .compartido_twitter,
.field-name-social-network .compartido_pinterest {
    width: 50px;
    position: relative;
    margin-bottom: 25px;

    div.count-share-content {
        position: relative;
        width: 55px;
        height: 15px;
        border-radius: 4px;
        text-align: center;
        line-height: 15px;
        font-size: 12px;
        color: white;
        top: 7px;
/*
        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 30px;
            top: 15px;
            right: -25px;
            border-bottom: 1px solid #4168b2;
        }
*/    }

    .compartido_icono {
        position: absolute;
        top: -1px;
        right: -28px;
        font-size: 24px;
    }
}

.field-name-social-network .compartido_facebook {
    div.count-share-content { background-color: #4168b2; }
    .compartido_icono a { 
        display: block;
        width: 30px;
        height: 30px;
        background: url(../img/facebook.svg) no-repeat center center;
        background-size: 100%;
        color: rgba(0,0,0,0);
    }
}

.field-name-social-network .compartido_twitter {
    div.count-share-content { background-color: #5ac3ef; &::after { border-color: #5ac3ef; } }
    .compartido_icono a { 
        display: block;
        width: 30px;
        height: 30px;
        background: url(../img/twitter.svg) no-repeat center center;
        background-size: 100%;
        color: rgba(0,0,0,0);
    }
}

.field-name-social-network .compartido_pinterest {
    div.count-share-content { background-color: #e6464a; &::after { border-color: #e6464a; right: -15px; } }
    .compartido_icono a {
        display: block;
        width: 30px;
        height: 30px;
        background: url(../img/pinterest.svg) no-repeat center center;
        background-size: 100%;
        color: rgba(0,0,0,0);
    }
}

/* Landing de seccion - social share
====================================================================================== */
.page-taxonomy .field-name-social-network,
.page-carteltv .field-name-social-network,
.page-galerias .field-name-social-network,
.page-search .field-name-social-network {
    top: 10px;
    left: 10px;
    z-index: 105;

    &.active {
        .compartido_facebook,
        .compartido_twitter,
        .compartido_pinterest {
            .translate3d(0,0,0);
        }
    }

    span.count_total {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        border-radius: 30px;

        text-align: center;
        line-height: 30px;
        font-size: 12px;
        color: white;

        background-color: @azul;
        cursor: pointer;
    }

    .compartido_facebook,
    .compartido_twitter,
    .compartido_pinterest {
        margin-bottom: 5px;
        .translate3d(-100px,0,0);
    }

    .compartido_facebook { .transition(transform 0.3s ease-in-out); }
    .compartido_twitter { .transition(transform 0.5s ease-in-out); }
    .compartido_pinterest { .transition(transform 0.7s ease-in-out); }
}


/* Pagina interna de ventos y articulos - Social share Facebook, twitter, pinterest
====================================================================================== */
.page-node .field-name-social-network, .page-eventos .field-name-social-network { top: 140px; left: -80px; }

.page-eventos .field-name-social-network {left: -85px; }

.page-node .field-name-social-network span.count_total,
.page-eventos .field-name-social-network span.count_total { .hidden; }

.page-node .field-name-social-network .compartido_facebook,
.page-eventos .field-name-social-network .compartido_facebook {}

.page-node .field-name-social-network .compartido_twitter,
.page-eventos .field-name-social-network .compartido_twitter {}

.page-node .field-name-social-network .compartido_pinterest,
.page-eventos .field-name-social-network .compartido_pinterest {}
