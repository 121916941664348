.form-control,.form-number {
   outline: none !important;
   border: none;
   margin-top: 10px;
   margin-bottom: 10px;
   border-radius: 0;

   font-family: @bebas;
   font-size: 17px;
   color: white;

   &:focus { outline: none; }
}

.form-number, .form-email{
   .form-control;
}

.buscador form { 
   border-bottom: 1px solid white;

   .form-control {
      border-bottom: 1px solid white;
   }
}

.form-email{
   outline: none !important;
   border: none;
   margin-top: 10px;
   margin-bottom: 10px;
   border-radius: 0;

   font-family: @bebas;
   font-size: 17px;
   color: white;
   background-color: #E8E8EA;

   &:focus { outline: none; }
}

.region-left form{
   padding: 30px 100px;
   background: #f4f4f4;
   font-family: @bebas;
   margin-bottom: 40px;
   input{
      background-color: #E8E8EA;
      color: #4B4C4E;
   }
   .webform-component-radios{
      label{
         color: #4c4d4d;
      }
      .form-radios{
         margin-left: 20px;
         label{
            margin-left: 0px;
            padding-left: 5px;
         }
      }
   }
   button.webform-submit{
      display: block;
      margin: 0 auto;
      font-family: @bebas;
      padding: 2px 20px;
      border-radius: 0px;
      font-size: 15px;
      background: black;
      border: none;
   }
   .captcha{
      text-align: center;
      img,.form-type-textfield{
         width: auto !important;
      }
      img{
         margin-bottom: -8px;
      }
      .form-type-textfield{
         margin: 0 auto;
      }
   }
}
