/* comunes, nuevo diseño
----------------------------------------------- */
.node-type-eventos .two-8-4-column {/* layout con sidebar */

	.region-top .field-name-field-imagen {
		overflow: hidden;
		max-height: 550px;

		&::before {
		content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			background-color: rgba(0,0,0,0.4);
			background-image: url(../img/pattern-slider.png);
			background-repeat: repeat;
		}
	}

	.region-top img {
		width: 100%;
		height: auto;
	}

	.group-info-evento {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		.translate(-50%,-50%);

		.field-name-title {
			position: relative;
			margin-bottom: 30px;
			border-bottom: 3px solid @azul;
			text-align: center;
			color: white;

			&::after {
				content:'';
				position: absolute;
				display: block;
				width: 0;
				height: 0;
				left: 50%;
				bottom: -25px;

				.translate(-50%, -50%);

				border-top: 15px solid @azul;
				border-left: 30px solid transparent;
				border-right: 30px solid transparent;
			}
		}

		.field-name-field-entradilla {
			padding-top: 30px;
			text-shadow: 0 0 5px black;
			text-align: center;
			font-weight: 700;
			color: white;
		}
	}
}

/* descripcion evento (donde, cuando, cuanto)
---------------------------------------------- */
.node-type-eventos .two-8-4-column .group-descripcion-evento {
	.clearfix();
	overflow: hidden;
	border-radius: 5px;
	background-color: white;

	> div.field {
		float: left;
		width: 33.33%;

		text-align: center;
	}

	div.field-label,
	div.field-items {
		font-weight: normal;
		font-family: @bebas;
	}

	div.field-label {
		background-color: #1ac8fa;
		color: #00566e;
	}

	div.field-name-field-fecha div.field-label { /* coliuman central */
		background-color: #62d9fb;
		color: #036986;
	}

	div.field-items { 
		position: relative;
		background-color: #ffffff;
		color: @negro;

		&::before {
			content:'';
			display: block;
			position: absolute;
			width: 1px;
			height: 40px;
			top: 0;
			left: 0;
			background-color: #eeeeee;
		}
	}

	div.field-name-field-donde div.field-items {  }
}

/* ---------------------------------------------- 
			estilos unicos moviles
----------------------------------------------- */
@media (max-width: @screen-sm-min) {

	.node-type-eventos .two-8-4-column #block-cu-breadcrumb-block-breadcrumb {
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: 20px;
		padding-left: 10px;
		padding-right: 10px;
		border-bottom: none;
		background-color: rgb(239, 239, 239);
		font-size: 0;

		span,
		a { font-size: 11px; }
	}

	.node-type-eventos .two-8-4-column .region-top .field-name-field-imagen {
		min-height: 400px;

		img { 
			height: 100% !important; 
			width: auto !important; 
			max-width: none; 
		}
	}

	.node-type-eventos .two-8-4-column {

		.group-info-evento { padding: 0 15px; }

		.group-descripcion-evento {

			div.field-label,
			div.field-items {
				font-size: 15px;
			}
		}
	}
}

/* ---------------------------------------------- 
			estilos unicos desktop
----------------------------------------------- */
@media (min-width: @screen-sm-min) {
	/* breadcrumb agenda interna ---------------------- */
	.node-type-eventos .two-8-4-column #block-cu-breadcrumb-block-breadcrumb {
		width: 100%;
		margin: auto;
		padding-top: 20px;
		margin-bottom: 12px;
	}

	.node-type-eventos .two-8-4-column {/* layout con sidebar */

		.group-info-evento {
			width: 750px;
		}

		/* descripcion evento (donde, cuando, cuanto)
		---------------------------------------------- */
		.group-descripcion-evento {

			div.field-label,
			div.field-items {
				font-size: 24px;
			}

			div.field-label { padding: 4px 0; }
			div.field-items { padding: 10px 0; }
		}
	}
}

/*
Seccion lateral derecha para pantallas grandes
=============================================================*/
@media (min-width: 1356px) {
	.node-type-eventos	.two-8-4-column.cartel-panel{
		.region-right.region-inner.col-sm-3 {
			width: 338px;
			margin-left: 26px;
		}
	}
}


#dfp-ad-cartel_agenda_728x90-wrapper{
text-align:center;
margin-bottom: 30px;
}
