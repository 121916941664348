/*----------------------------------------------*/
/*----------------nodo publicidad---------------*/
/*----------------------------------------------*/
.node-publicidad{
    position: relative;
    .publicidad{
        height: 373px;
        a{
            position: absolute;
            top: 50%;
            left: 50%;
            transform:translate(-50%, -50%);
        }
        #eplAdDivright_banner2{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            a{
                display: block;
                img{
                    margin: 0 auto;
                }
            }
        }
    }
    .field-name-texto-publicidad{
        display: inline-block;
        position: absolute;
        background-color: @gris-oscuro;
        color: #fff;
        bottom: 10px;
        text-align: center;
        padding: 5px 15px 5px 15px;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%,-5%);
        font-family: 'bebas_neueregular',Arial-narrow;
        font-size: 13px;

    }
}
.full .node-publicidad{/*menu retraido*/
    .publicidad{
        @media (max-width: 1366px) {
            width: 100%;
            height: 365px ;
        }
    }
}


/*-----------------------------------------------------------------------*/
/*----------------------------nodo dos col-------------------------------*/
/*-----------------------------------------------------------------------*/
.node.view-mode-card_destacado_home {
    // width: 62%;
    vertical-align: top;
    position: relative;
    overflow: hidden;

    .group-contenedor{
        > .field-name-clone-seccion{ /*remplazo*/
            position: absolute;
            transition: all 0.3s ease-in-out;
            opacity: 1;
            //background-color: #D9330F;
            color: #fff;
            opacity: 1;
            left: 0;
            top: 33px;
            font-family: 'bebas_neueregular',Arial-narrow;
            font-size: 22px;
            line-height: 20px;
            padding: 5px 12px 5px 40px;

            h3{
                font-size: 22px;
                margin: 0;
            }

            .field-name-field-icono{
                position: absolute;
                left: 5px;
                top:50%;
                transform: translate(0, -50%);
            }
        }
    }

    .field-name-field-imagen{
        position: relative;
        box-shadow: 5px 5px 20px 0px rgba(50, 50, 50, 0.77);/**/
        a::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 100;
        }
        img{
            width: 100%;
            height: auto;
            transition: all 10s ease-in-out;
            transform: scale(1);
        }
        &::after{
            content: "";
            transition: all 0.3s ease-in-out;
            transform: scale(0.2);
            opacity: 0;
            position: absolute;
            left: 15px;
            right: 15px;
            top: 15px;
            bottom: 55px;
            border: 2px solid white;
            background-color: rgba(0,0,0,0.5);
            margin: auto;
        }
    }

    .group-texto-subtitulo{
        transition: all 0.3s ease-in-out;
        width: 100%;
        position: absolute;
        //background-color: #fff;
        background-color: rgba(0, 0, 0, 0.59);
        z-index: 9;
        bottom: 0;
        padding: 10px 20px;
        padding-bottom: 80px;

        .field-name-title {/*remplazo*/
            transition: all 0.3s ease-in-out;
            width: 99%;
            margin: auto;
            .field-items{
                .field-item{
                    h1, a{
                        text-align: left;
                        font-size: 28px;
                        line-height: 28px;
                        color: @blanco
                    }
                }
            }

        }
        .field-name-field-subtitulo{
            transition: all 0.3s ease-in-out;
            width: 99%;
            margin: auto;
            .field-items{
                .field-item{
                    text-align: left;
                    color: @blanco;
                }
            }
        }
        /*fin fecha bottom*/
        .group-contenido-texto{
            text-align: center;
            /*fecha articulo*/
            .group-contenedor-fecha{
                transition: all 0.3s ease-in-out;
                position: absolute;
                color: #383838;
                text-align: center;
                padding: 5px 0 15px;
                bottom: 0;
                left: 0;
                right: 0;
                font-size: 18px;
                font-family: @bebas;
                text-transform: uppercase;
                background-color: white;

                &::after{
                    content: "";
                    position: absolute;
                    width: 40px;
                    height: 2px;
                    background-color: #383838;
                    bottom: 9px;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                .field-name-post-date{
                    font-family: @bebas;
                }

            }

        }/*fin fecha bottom*/

    }/*fin bloke fecha*/

    .group-hover{
        transition: all 0.4s ease-in-out;
        position: absolute;
        width: 90%;
        max-width: 450px;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        z-index: 10;
        padding: 15px;

        h1{
            position: relative;
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 20px;
            &::after{
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform:translate(-50%, 0);
                width: 80%;
                height: 2px;
                //background-color: blueviolet;
            }

            a{
                color: white;
            }
        }

        .group-texto-fecha{
            height: 30px;
            position: absolute;
            display: inline-block;
            padding: 0 3px;
            color: white;
            font-family: @bebas;
            line-height: 30px;

            left: 50%;
            transform: translate(-50%);
            &::after, &::before{
                content: "";
                position: absolute;
                display: block;
                border-bottom: 30px solid transparent;
                top: 0;
            }
            &::after{
                right: -20px;
                //border-left: 20px solid blueviolet;
            }
            &::before{
                left: -20px;
                //border-right: 20px solid blueviolet;
            }

            >div{
                display: inline-block;
                vertical-align: top;
            }
            .field-name-fecha-corta{
                position: relative;
                margin-right: 15px;
                padding-right: 15px;
                &::after{
                    content: "";
                    position: absolute;
                    height: 20px;
                    top: 50%;
                    right: 0;
                    z-index: 11;
                    transform: translate(0, -50%);
                    border-left: 1px solid white;
                }
            }
        }
    }

}



/*-----------------------------------------------------------------------*/
/*----------------------------nodo una col-------------------------------*/
/*-----------------------------------------------------------------------*/


.node.view-mode-destacados_home_general {
    //width: 31%;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    @media(max-width: 768px){
        margin-bottom: 20px;
    }
    .group-contenedor{ //#node_article_destacados_home_general_group_contenedor
        /*
        Contiene el nombre, la sección, un frupo hover y un grupo texto
         */
        > .field-name-clone-seccion{ /*No se toca*/
            position: absolute;
            transition: all 0.3s ease-in-out;
            opacity: 1;
            color: #fff;
            opacity: 1;
            left: 0;
            top: 33px;
            font-family: 'bebas_neueregular',Arial-narrow;
            font-size: 22px;
            line-height: 20px;
            padding: 5px 12px 5px 40px;

            h3{
                font-size: 22px;
                margin: 0;
            }

            .field-name-field-icono{
                position: absolute;
                left: 5px;
                top:50%;
                transform: translate(0, -50%);
            }
        }
    }

    .field-name-field-imagen-cuadrada{
        .image-field-caption{
            display: none;
        }
        position: relative;
        a::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 100;
        }
        img{
            width: 100%;
            height: auto;
            transition: all 10s ease-in-out;
            transform: scale(1);
        }
        &::after{
            content: "";
            transition: all 0.3s ease-in-out;
            transform: scale(0.2);
            opacity: 0;
            position: absolute;
            left: 15px;
            right: 15px;
            top: 15px;
            bottom: 55px;
            border: 2px solid white;
            background-color: rgba(0,0,0,0.5);
            margin: auto;
        }
    }

    .group-texto-subtitulo{
        transition: all 0.3s ease-in-out;
        width: 100%;
        position: absolute;
        background-color: #fff;
        z-index: 9;
        bottom: 0;
        padding: 10px 20px;
        padding-bottom: 80px;
        min-height: 166px;
        @media(max-width:1024px){
            padding-bottom: 0px;
        }
        .field-name-title {/*remplazo*/
            transition: all 0.3s ease-in-out;
            width: 99%;
            margin: auto;
            .field-items{
                .field-item{
                    h1, a{
                        text-align: left;
                        font-size: 28px;
                        line-height: 28px;
                    }
                }
            }
        }
        .field-name-field-subtitulo{
            transition: all 0.3s ease-in-out;
            width: 99%;
            margin: auto;
            .field-items{
                .field-item{
                    text-align: left;
                    color: black;
                }
            }
        }
        /*fin fecha bottom*/
        .group-contenido-texto{
            text-align: center;
            /*fecha articulo*/
            .group-contenedor-fecha{
                transition: all 0.3s ease-in-out;
                position: absolute;
                color: #383838;
                text-align: center;
                padding: 5px 0 15px;
                bottom: 0;
                left: 0;
                right: 0;
                font-size: 18px;
                font-family: @bebas;
                text-transform: uppercase;
                background-color: white;

                &::after{
                    content: "";
                    position: absolute;
                    width: 40px;
                    height: 2px;
                    background-color: #383838;
                    bottom: 9px;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                .field-name-post-date{
                    font-family: @bebas;
                }

            }

        }/*fin fecha bottom*/

    }/*fin bloke fecha*/

    .group-hover{
        transition: all 0.4s ease-in-out;
        position: absolute !important;
        width: 90%;
        max-width: 450px;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        z-index: 10;
        padding: 15px;

        h1{
            position: relative;
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 20px;
            text-align: center;
            &::after{
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform:translate(-50%, 0);
                width: 80%;
                height: 2px;
                //background-color: blueviolet;
            }

            a{
                color: white;
            }
        }

        .group-texto-fecha{
            height: 30px;
            position: absolute;
            display: table;
            padding: 0 3px;
            color: white;
            font-family: @bebas;
            line-height: 30px;

            left: 50%;
            transform: translate(-50%);
            &::after, &::before{
                content: "";
                position: absolute;
                display: block;
                border-bottom: 30px solid transparent;
                top: 0;
            }
            &::after{
                right: -20px;
            }
            &::before{
                left: -20px;
            }

            >div{
                display: inline-block;
                vertical-align: top;
            }
            .field-name-fecha-corta{
                position: relative;
                margin-right: 15px;
                padding-right: 15px;
                &::after{
                    content: "";
                    position: absolute;
                    height: 20px;
                    top: 50%;
                    right: 0;
                    z-index: 11;
                    transform: translate(0, -50%);
                    border-left: 1px solid white;
                }
            }
        }
    }
}





/*-----------------------------------------------------------------------*/
/*------------------------------nodo video-------------------------------*/
/*-----------------------------------------------------------------------*/

.node.node-video {
    .group-contenedor{
        > .field-name-clone-seccion{ /*remplazo*/
            display: none;
        }
    }
    .field-name-field-imagen-cuadrada{
        .image-field-caption{
            display: none;
        }
        img{
            transform: scale(1);
        }
        &::after{
            transform: scale(1);
            opacity: 1;
            background-color: transparent;
        }
    }
    .group-texto-subtitulo{
        background-color: transparent;
        .field-name-title {/*remplazo*/
            opacity: 0;
        }
        .field-name-field-subtitulo{
            opacity: 0;
        }
        /*fin fecha bottom*/
        .group-contenido-texto{
            text-align: center;
            /*fecha articulo*/
            .group-contenedor-fecha{
                background-color: #1a1918 !important;
                color: white !important;
                &::after{
                    background-color: white;
                }
            }
        }/*fin fecha bottom*/
    }/*fin bloke fecha*/

    .group-hover{
        transform: translate(-50%, -76%) scale(1);
        opacity: 1;
        &::before{/*triangulo play*/
            transition: all 0.3s ease-in-out;
            //transform: scale(1);
            content: " ";
            position: relative;
            display: block;
            margin: 0 auto;
            top: -6px;
            //left: 50%;
            width: 0;
            height: 0;
            border-top: 30px solid transparent;
            border-left: 50px solid #FFFBFB;
            border-bottom: 30px solid transparent;
            transform: rotateX(0deg) ;
        }
    }
}/*/node-video*/


/*-----------------------------------------------------------------------*/
/*------------------------------nodo galeria-------------------------------*/
/*-----------------------------------------------------------------------*/

.node.node-gallery {
    .group-contenedor{
        > .field-name-clone-seccion{ /*remplazo*/
            display: none;
        }
    }
    .field-name-field-imagen-cuadrada{
        .image-field-caption{
            display: none;
        }
        img{
            transform: scale(1);
        }
        &::after{
            transform: scale(1);
            opacity: 1;
            background-color: transparent;
        }
    }
    .group-texto-subtitulo{
        background-color: transparent;
        .field-name-title {/*remplazo*/
            opacity: 0;
        }
        .field-name-field-subtitulo{
            opacity: 0;
        }
        /*fin fecha bottom*/
        .group-contenido-texto{
            text-align: center;
            /*fecha articulo*/
            .group-contenedor-fecha{
                background-color: #1a1918 !important;
                color: white !important;
                &::after{
                    background-color: white;
                }
            }
        }/*fin fecha bottom*/
    }/*fin bloke fecha*/

    .group-hover{
        transform: translate(-50%, -90%) scale(1);
        opacity: 1;
        h1{
            position: relative;
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 20px;
            &::after{
                transform: all linear 0.4sg;
                content: "";
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform:translate(-50%, 0);
                width: 80%;
                height: 2px;
                //background-color: blueviolet;
            }

            a{
                color: white;
            }
        }

    }
}/*/node-galeria*/

/*-----------------------------------------------------------------------*/
/*--------------------------nodo dos cargados----------------------------*/
/*-----------------------------------------------------------------------*/
.eventos-cargados{
    li.views-row.nodo-destacado-home{
        width: 63.5% !important;
    }
}




/*-----------------------------------------------------------------------*/
/*-----------------------nodo dos col galeria----------------------------*/
/*-----------------------------------------------------------------------*/
/*
.node.view-mode-card_destacado_home.node-gallery {

.field-name-field-imagen{
position: relative;
box-shadow: 5px 5px 20px 0px rgba(50, 50, 50, 0.77);
a::after{
content: "";
display: block;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 100;
}
img{
width: 100%;
height: auto;
transition: all 10s ease-in-out;
transform: scale(1);
}
&::after{
content: "";
transition: all 0.3s ease-in-out;
transform: scale(0.2);
opacity: 0;
position: absolute;
left: 15px;
right: 15px;
top: 15px;
bottom: 55px;
border: 2px solid white;
background-color: rgba(0,0,0,0.5);
margin: auto;
}
}
}
*/
