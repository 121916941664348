/* INTERNA CARTEL TV
============================================== */
.node-type-video {

	.one-column-top > .region {
		background-color: @negro;
	}

	.region-top {
		margin: auto;
		width: 90%;
		padding-bottom: 50px;
	}

	/* grupo titulo - icono, titulo */
	.group-titulo {
		position: relative;

		.field-name-field-seccion .field-name-title { .hidden; }

		.field-name-field-seccion {
			/* icono - tipo */
			.field-name-field-icono .field-items {
				position: absolute;
				width: 50px;
				height: 50px;
				top: 6px;
				left: -70px;
				
				text-align: center;
				line-height: 50px;
				font-size: 34px;
				color: white;

				z-index: 2;

				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					min-height: 100%;
					background-color: @azul;
					z-index: -1;

					.rotate(45deg);
				}
			}
		}

		/* estilos moviles ====================== */
		@media (max-width: @screen-sm-min) {
			.field-name-title {
				padding-left: 60px;
			}

			.field-name-field-seccion .field-name-field-icono .field-items {
				left: 0;
				top: 0;
				.scale(0.6);
			}
		}
	}

	/* titulo cartel */
	.field-name-title-cartel-tv {
		font-size: 60px;
		border-bottom: 1px solid white;

		text-align: center;
		font-family: @bebas;
		color: white;

		p { margin: 0; }
	}

	/* titulos */
	.field-name-title {
		h2, h1 { color: white; }
	}

	/* video */
	.media-youtube-video,
	.media-vimeo-video {
		position: relative;
		margin: auto;
		margin-bottom: 20px;

		&::before {
			content:'';
			display: block;
			padding-top: 56.25%; /* aspect ratio */
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	/* grupo info video ====================== */
	.group-info-video {
		position: relative;
		min-height: 120px;
		margin-left: 90px;
		padding-left: 30px;
		border-left: 1px solid @gris;

		.field-name-social-network {
			top: 0px;
			left: -92px;
		}
	}

	/* resumen */
	.field-name-summary-body,
	.field-name-post-date {
		color: white;
	}

	.field-name-post-date { color: @gris; }


	/* BODY */
	.one-column-main { padding-top: 50px; }

	/* estilos desktop ====================== */
	@media (min-width: @screen-sm-min) {
		.region-top {
			width: 60%;
			float: none;
		}
	} /* end desktop styles */
}


/* ver mas --------------------------- */
.node-type-video .view-cartel-tv .pager-load-more li > a {
	background-color: white;
	color: black;
}

.node-type-video .view-cartel-tv .pager-load-more::after {border-bottom: 1px solid black;}





/*CAMBIOS MAYO 30 2017 REDBULL*/
body.page-carteltv #block-system-main{
	    margin-top: 2.5em;
}

.container-tv{
	padding-bottom: 100px;
	h2{
		margin-top: 0 !important;
	}
	img{
		position: absolute;
		width: 100%;
		z-index: -1;

	}
	.video {
		width: 60%;
		margin: 0 auto;
	}  
	@media(max-width: 768px){
		padding-bottom: 10px;
		.video {
			width: 90%;
		}
	}
}

/* tablas forms*/
.contenedor-horarios {
	background-color: #000;
	max-width: 50em;
	margin: 50px auto;
	ul{
		background: white;
		padding: 0;
		list-style: none;
		text-transform: uppercase;
		font-family: 'bebas_neueregular',Arial-narrow;
		font-size: 20px;
		display: flex;
		justify-content: space-around;	
		margin-bottom: 0;
	}
	a{
		font-size: 20px;
    	color: #4c4d4d;
		padding: 5px 15px;
		cursor: pointer;
		&:hover{
			color: white;
			background-color: #07b9e3;
		}
	}
	a.active{
		color: white;
		background-color: #07b9e3;
	}


	&>div{
		padding: 20px;
		color: #fff;
		h2{
			text-align: center;
			margin-bottom: 30px;
			margin-top: 0;
		}
		&>div{
			display: flex;
			justify-content: space-around;
		}
		table{
			flex-basis: 40%;
		}
		table td{
			padding: 5px 10px;
			&:nth-child(1){
				font-weight: 700;
			}
		}
		table tr {
			border-bottom: #545454;
		}
	}
}

