body.page-taxonomy-term-168{

    /*nodo destacado*/
    #node_article_destacado_seccion_nuevo_group_container_all{
        .group-texto-inside-fecha{
            display: inline-block;
            padding: 2px 10px;
            position: relative;
            background-color: rgb(244, 64, 94);
            &::before,
            &::after{
                content: '';
                display: block;
                height: 0px;
                width: 0px;
                border-bottom: solid 30px transparent;
                position: absolute;
                top: 0px;


            }
            &::before{
                left: -20px;
                border-right: 20px solid rgb(244, 64, 94);
            }
            &::after{
                right: -20px;
                border-left: 20px solid rgb(244, 64, 94);
            }
        }
    }

    /*nodos*/
    .pane-block.pane-cu-list-content-section-block-list-content-section{
        .group-contenedor{
            .field-name-clone-seccion{
                background-color: rgb(244, 64, 94);
            }
            .group-hover{
                .group-texto-fecha{
                    display: inline-block;
                    padding: 2px 10px;
                    position: relative;
                    background-color: rgb(244, 64, 94);
                    &::before,
                    &::after{
                        content: '';
                        display: block;
                        height: 0px;
                        width: 0px;
                        border-bottom: solid 30px transparent;
                        position: absolute;
                        top: 0px;


                    }
                    &::before{
                        left: -20px;
                        border-right: 20px solid rgb(244, 64, 94);
                    }
                    &::after{
                        right: -20px;
                        border-left: 20px solid rgb(244, 64, 94);
                    }
                }
            }
        }
    }

}
