/* Cartel tv Slider
============================================================ */
body.page-carteltv { 
	background-color: black; 

   #block-system-main{
      margin-top: 2.5em;
   }
	h2.pane-title {
		width: 83.33333333%;
		margin: 10px auto;
		border-bottom: 1px solid @gris;

		text-align: center;
		font-size: 70px;
		color: white;
	}

	.region-top .pane-content {
		position: relative;
		overflow: hidden;
	}
}

/* flexslider loop */
#flexslider .node-video {

	/* nombre de seccion oculto en moviles */
	.field-name-body,
	.field-name-field-subtitulo,
	.field-name-field-seccion { display: none; visibility: hidden; }

	.field-name-field-video {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	p,
	.field-name-title h2 a { color: white; }
}

/* stilos desktop */
@media (min-width: @screen-sm-min) {

	#flexslider	.node-video {

		position: relative;

		.field-name-body,
		.field-name-field-subtitulo {
			.mostrar;
			min-height: 93px;
			padding-top: 30px;
			color: white;
		}

		.group-video-slider { position: relative; }

		.group-textos-video {
			position: relative;
			background-color: @negro;

			.field-name-title h2 {
				font-size: 25px;
				margin-top: 0;
				margin-bottom: 0;
				margin-left: 25%;
				padding-top: 20px;
				padding-bottom: 20px;

				a { color: white; }
			}

			.group-textos-video-izq {
				position: absolute;
				width: 120px;
				height: 40px;
				top: 50%;
				left: 35px;
				margin-top: -20px;
				border: 1px solid @azul;

				text-align: center;
				text-transform: uppercase;
				font-size: 13px;
				line-height: 13px;
				color: white;

				.field-name-field-tipo,
				.field-name-field-seccion,
				.field-name-post-date {
					width: 78px;
					float: left;
				}

				.field-name-field-tipo {
					width: 40px;
				}

				.field-name-field-seccion {
					display: block;
					visibility: visible;
					margin-top: 6px;
				}

				/* icono - tipo */
				.field-name-field-icono {
					width: 40px;
					height: 40px;
					top: -1px;
					left: -40px;
					margin-left: 0;
					
					text-align: center;
					line-height: 39px;
					font-size: 26px;

					z-index: 2;

					&::after {
						.rotate(0deg);
					}

					.field-items { position: relative; z-index: 1; }

					.field-items::after {
						content: '';
						position: absolute;
						display: block;
						width: 100%;
						min-height: 100%;
						top: 0;
						left: 0;
						z-index: -1;
						background-color: @azul;
					}
				}
			}
		}
	}
}

/* cartel filtro
============================================================ */
.page-carteltv .view-filters {
	#edit-field-seccion-tid-wrapper {
		.clearfix();
		float: none;
		padding: 0;
		background-color: white;

		> label,
		div.views-widget { 
			float: left;
			text-transform: uppercase;
			font-family: @bebas;
			font-weight: normal;
			font-size: 24px;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		> label { 
			width: ~"calc(20% - 15px)";
			padding-left: 15px;
			padding-top: 5px;
			margin: 0;
			font-size: 26px;
		}

		div.views-widget {
			width: 80%;
			text-align: center;

			/* fix bug */
			#edit-field-seccion-tid-all { .hidden; }

			.bef-select-as-radios {
				.form-radio {
    				display: none;
				}

				.form-type-radio {
					position: relative;
					display: inline-block;
					padding: 5px 15px;
					z-index: 1;

					&:hover {
						color: white;

						&::before { background-color: @azul;}
						
					}

					&::before {
						content: '';
						position: absolute;
						width: 100%;
						min-height: 110%;
						top: -5%;
						left: 0;
						background-color: transparent;
						z-index: -1;
					}

					label { padding-left: 0; }
				}
			}
		}
	}
}

/* Cartel tv grid nodes
============================================================ */
.view-cartel-tv {

	.clearfix();
	border-top: 1px solid white;
	margin-top: 50px;
	padding-top: 30px;

	.view-content {
		.clearfix();
		margin-left: -15px;
		margin-right: -15px;
	}

	/* ver mas videos */
	.pager-load-more {
		position: relative;
		z-index: 1;
		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			bottom: 70px;
			border-bottom: 1px solid white;

			z-index: -1;
		}
	}

	.pager-load-more li > a {
		border-radius: 0;
		background-color: black;
		border: none;
		padding: 5px 50px;

		font-family: @strait;
		font-size: 12px;
		letter-spacing: 5px;
		color: white;

		&::after {
			content: 'm';
			display: block;
			font-family: "cartel-urbano";
			font-size: 73px;
			line-height: 70px;
		}
	}

	.cartel-block {
		.node {
			position: relative;
			overflow: hidden;
			min-height: 333px;

			.field-name-field-video {
				padding: 5px 5px 0;
				background-color: #2d2d2e;

				/* gradietnt */
				&::before {
					.transition(all 0.10s linear);
					content: '';
					position: absolute;
					left: 15px;
					top: 15px;
					right: 15px;
					bottom: 80px;
					opacity: 0;
					z-index: 1;

					-webkit-transform: scale(0.5) rotate(-30deg) translate3d(-30px, -30px,0);
					-moz-transform: scale(0.5) rotate(-30deg) translate3d(-30px, -30px,0);
					-o-transform: scale(0.5) rotate(-30deg) translate3d(-30px, -30px,0);
					transform: scale(0.5) rotate(-30deg) translate3d(-30px, -30px,0);

					.gradient-hover;
				}

				a { 
					display: block;
					/* arrow */
					&::after {
						.transition(transform 0.10s linear);
						content: 'v';
						position: absolute;
						width: 70px;
						height: 70px;
						top: 0;
						left: 50%;
						margin-left: -35px;
						z-index: 3;

						.translate3d(0,-200px,0);

						text-align: center;
						line-height: 70px;
						font-family: "cartel-urbano";
						font-size: 80px;
						color: white;
					}
				}
			}

			/* grupo hover */
			.field-name-summary-body,
			.field-name-field-subtitulo {
				
			}

			.group-textos-articulo-video {
				position: relative;
				width: 100%;
				z-index: 2;

				.field-name-title {
					h2 {
						min-height: 70px;
						margin: 0;
						padding: 15px;

						background-color: #2d2d2e;

						font-size: 18px;

						a {
							color: white;
						}
					}
				}
			}
		}
	}
}

/* ============================================================
	Mobile
============================================================== */
@media (max-width: @screen-sm-min) {

	/* header slider ------------------------------------------ */
	body.page-carteltv h2.pane-title {
		font-size: 36px;
		padding: 10px;
		margin: 29px auto;
	}

	body.page-carteltv #flexslider { width: 75%; }

	body.page-carteltv #flexslider ul.flex-direction-nav a.flex-next, 
	body.page-carteltv #flexslider ul.flex-direction-nav a.flex-prev {
		width: 12.5%;
	}

	body.page-carteltv #flexslider .flex-viewport::before,
	body.page-carteltv #flexslider .flex-viewport::after { border-color: black !important; }

	body.page-carteltv .flex-direction-nav a { background-color: black; }

	body.page-carteltv #flexslider .node-video .field-name-title h2 a {
		line-height: 21px;
		font-size: 21px;
		padding: 0 10px;
		display: block;
	}

	body.page-carteltv #flexslider .node-video .field-name-post-date { padding-left: 10px; }

	/* oculta icono -------------------- */
	body.page-carteltv #flexslider .field-name-field-tipo { .hidden(); }

	.view-cartel-tv .cartel-block .node { min-height: 0; }

	/* Filtro Cartel TV ------------------------------------------ */
	.page-carteltv .view-filters #edit-field-seccion-tid-wrapper > label {
		width: 100%;
		text-align: center;
		font-size: 18px;
	}

	.page-carteltv .view-filters #edit-field-seccion-tid-wrapper div.views-widget {
		width: 100%;
	}

	/* grid ------------------------------------------ */
	.view-cartel-tv .cartel-block .node { overflow: visible; }

	.view-cartel-tv .cartel-block .node .group-textos-articulo-video .field-name-title h2 { min-height: 0; }

	.view-cartel-tv .cartel-block .node .field-name-summary-body, 
	.view-cartel-tv .cartel-block .node .field-name-field-subtitulo {
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
		background-color: #2d2d2e;
		color: rgb(207, 207, 207);

		p { margin: 0; }
	}

	.page-carteltv .view-filters #edit-field-seccion-tid-wrapper div.views-widget .bef-select-as-radios .form-type-radio {

		padding: 0px 8px;
		font-size: 18px;
		color: rgb(148, 148, 148);

	}

	/* oculta player --------------------- */
	.view-cartel-tv .cartel-block .node:hover .field-name-field-video::before,
	.view-cartel-tv .cartel-block .node .field-name-field-video a::after { .hidden(); }

	/* relacionados ----------------------- */
	body.page-carteltv .one-column-bottom .pane-cartel-section-header .pane-title { top: -152px; }

	#related_content_slider .flex-viewport::before,
	#related_content_slider .flex-viewport::after { border-color: rgba(0,0,0,0.7); }
}

/* ============================================================
			estilos para desktop
======================================================== */
@media (min-width: @screen-sm-min) {
	.view-cartel-tv .cartel-block .node .field-name-summary-body, 
	.view-cartel-tv .cartel-block .node .field-name-field-subtitulo {
		position: absolute;
		overflow: hidden;
		width: 80%;
		height: 35px;
		left: 50%;
		margin-left: -40%;
		bottom: 0;
		opacity: 0;
		
		text-shadow: 0 1px 1px rgba(0,0,0,0.95);
		line-height: 16px;
		text-align: center;
		font-size: 14px;
		color: white;

		z-index: -1;

		.translate3d(0,50px,0);

		p { margin: 0; }
	}

	.page-carteltv .cartel-block,
	.node-type-video .cartel-block {
		.node {
			position: relative;
			overflow: hidden;
			min-height: 333px;

			&:hover {
				.field-name-summary-body,
				.field-name-field-subtitulo {
					.translate3d(0,-80px,0);
					opacity: 1;
				}

				.field-name-field-video {

					&::before { 
						opacity: .7;
						-webkit-transform: scale(1) rotate(0deg) translate3d(0,0,0);
						-moz-transform: scale(1) rotate(0deg) translate3d(0,0,0);
						-o-transform: scale(1) rotate(0deg) translate3d(0,0,0);
						transform: scale(1) rotate(0deg) translate3d(0,0,0);
					}

					a::after { .translate3d(0,90%,0); }
				}
			}
		}
	}

	.node-type-video .view-cartel-tv {
		padding-top: 0;
  		margin-top: 20px;
	}
}/* end media */

