// Fuentes
@import "_fonts";

// Bootstrap
@import "_bootstrap";

// Variables & Mixins
@import "_variables";
@import "_mixins";

//animaciones
@import "_animaciones";
@import "../css/animate.css";

// Layouts
@import "_layouts";
@import "slick";

/*---------------------------------------------------------*/
/*---------------------------nodos-------------------------*/
/*---------------------------------------------------------*/
@import "nodos/_nodo-view-mode-carta";
@import "nodos/_nodos-color-seccion";
@import "nodos/_nodo-view-mode-carta-pantallasmenosmuypequenas";
@import "nodos/_eventos-horizontales";

/*----------------------------home-------------------------*/
//@import "home/_destacados-home";
//@import "home/_layout_listado_nodos _home";
/*----------------------interna-galerias--------------------*/
//@import "_interna-galerias";
/*--------------------edicion impresa interna--------------*/
@import "home/interna-edicion-impresa/_interna-edicion-impresa";
/*--------------------lo mas pupular-----------------------*/
@import "home/_lo-mas-popular-nodos";
/*--------------------lo mas pupular agenda-----------------------*/
@import "nodos/_lo-mas-popular-nodos-agenda";
/*--------------------publicidad-----------------------*/
@import "home/_publicidad-home";
/*--------------------------mobile-------------------------*/
@import "home/mobile";
/*--------------------------pag interna galerias-------------------------*/
@import "_galery-pag-interna";
/*--------------------------cargador del sitio-------------------------*/
@import "_cargador-sitio";
/*--------------------------secciones cartel-------------------------*/
@import "_secciones";
/*--------------------------_contenidos-relacionados-------------------------*/
@import "_contenidos-relacionados";
/* --------------------------boeltin -------------------------------*/
@import "_boletin.less";


// Home Styles
/*@import "home/_common";
@import "home/_destacado";
@import "home/_agenda";
@import "home/_galerias";
@import "home/_secciones";
@import "home/_videos";
@import "home/_opinion";
@import "home/_fullgente";
@import "home/_destacados-home";
@import "home/_destacados-home-right";*/

// Cartel Componentes 
@import "_menu";
@import "_footer";
@import "_landing";
@import "_verpor";
@import "_pages";
@import "_carteltv";
@import "_carteltv-interna";
@import "_agenda";
@import "_agenda-interna";
@import "_busqueda";
@import "_user";
@import "_comentarios";
@import "_publicidad";
@import "_formularios";
@import "_relacionados";
@import "_global"; // Base styling
@import "_lenovo"; // pag lenovo


// Flexslider Custom
@import "flexslider/_custom";

// perfect scroll
@import "../vendor/scroll/_perfect-scrollbar";

// Extras - colores, social
@import "_social";
@import "colors/_colores";
@import "colors/_secciones"; 
@import "colors/_submenu";

// Menu Movile
@import "_menu-movile";

//Ediciones impresas
@import "_ediciones-impresas";
//proteccion de datos
@import "_proteccion-de-datos";

//movil general
@import "movil/movil-general";


// home 2018
@import "home2018/_destacados";
@import "home2018/_m-destacados";

@import "single2018/_single2018";
@import "_footer-2018";
@import "_header-2018";

@import "section2018/_section2018";

// mobiles
@media (max-width: @screen-sm-min) {
    @import "single2018/_m-single2018.less";
    @import "section2018/_m-section2018.less";
}