@import "agenda/_slider";
@import "agenda/_popular";
@import "agenda/_filtro";
@import "agenda/_listado-eventos";

/* commons
---------------------------------- */
@media (max-width: @screen-sm-min) { /* Mobile */
	.agenda-breadcrumb { .hidden(); }
}

/******** 2020 *********/
/** Header **/

/** Override **/
#agenda-2020 > div > div > div:nth-child(2) > div > div > div.view-filters{
	display:none !important;
	visibility: hidden !important;
}

/** Recomended **/

#agenda-2020 > div > div > div.panel-pane.pane-views.pane-nodequeue-12{
	margin-top: -10px;
}

#agenda-2020 > div > div > div.panel-pane.pane-views.pane-nodequeue-12 > div > div > div.view-header{
	margin-bottom: 100px;
}
#agenda-2020 > div > div > div.panel-pane.pane-views.pane-nodequeue-12 > div > div > div.view-header > div > span.s:after {
	content: " - ";
	background: url(https://cartelurbano.com/sites/default/files/barritaarriba.jpg) repeat-x;
	background-repeat: repeat-x;
	vertical-align: bottom;
	color: transparent;
	width: 72%;
	margin-top: 12px;
	position: absolute;
	margin-left: 30px;
}

#agenda-2020 *{
	font-family: 'Alegreya',serif !important;  	
}

#agenda-2020 > div > div > div.panel-pane.pane-views.pane-nodequeue-12 > div > div > div.view-content > div{
	display: inline-block !important;
	vertical-align: middle;
}

#agenda-2020 > div > div > div.panel-pane.pane-views.pane-nodequeue-12 > div > div > div.view-content > div > div > div.field.field-name-field-imagen-cuadrada.field-type-image.field-label-hidden > div > div > img{
	width: 400px;
	height: 300px
}

#agenda-2020 > div > div > div.panel-pane.pane-views.pane-nodequeue-12 > div > div > div.view-content{
	text-align: center;
}

#agenda-2020 > div > div > div:nth-child(2) > div > div > div.view-content,
#agenda-2020 > div > div > div:nth-child(3) > div > div > div.view-content,
#agenda-2020 > div > div > div:nth-child(4) > div > div > div.view-content{
	width: 100% !important;
	min-width: 100%;
	text-align: left;
	margin-left: 2px;
	margin-top: -75px;
}

#agenda-2020 > div > div > div > div > div > div.view-content > div.views-row{
	display: inline-block !important;
	vertical-align: middle;
	padding: 12px;
	margin-bottom: 27px;
	width: 31.3%;
}

#agenda-2020 > div > div > div > div > div > div.view-content > div.views-row > div > div.field.field-name-field-imagen-cuadrada.field-type-image.field-label-hidden > div > div > img,
#agenda-2020 > div > div > div.panel-pane.pane-views > div > div > div.view-content > div > div > div.field.field-name-field-imagen-cuadrada.field-type-image.field-label-hidden > div > div > a > img{
	width: 400px;
	height: 300px
}

.view-mode-agenda_2020 .field-name-title h2{
	font-size: 22px;
}


.agenda2020-section-title{
	font-family: 'Alegreya',serif;
	font-weight: bold;
	margin-left: 29px;
	margin-right: 5%;
	color:#000;
}

.agenda2020-section-title .f{
	font-size: 30px;
	vertical-align: middle;
}

.agenda2020-section-title .s{
	font-size: 29px;
	letter-spacing: 5px;
}

.agenda2020-section-title .t{
	font-size: 0px;
	display: none;
}

.pane-nodequeue-12 .field-name-field-fecha{

	border-bottom: solid 1px;
	padding: 5px;
	width: 50%;
	text-align: center;
	margin-left: 27%;
}


.pane-nodequeue-12 .field-name-field-clasificacion-evento h2 a{
	font-size: 30px !important;
}

.pane-nodequeue-12 .field-name-title{
	text-align: center;
}

/** Filters **/

.agenda-2020-filters ul{
	width: 100%;
	cursor:pointer !important;
}

.agenda-2020-filters ul li {
	display: inline-block;
	vertical-align: middle;
	border: solid 1px #000;
	width: 24%;
	text-align: center;
	padding-top: 36px;
	padding-bottom: 80px;
	font-size: 32px;
	color: #fff;
	font-weight: bold;
	margin: 0;
	position: relative;
	height: 180px;
}

.agenda-2020-filters ul li.filter-active:before {
	content: " ";
	/* border-color: #00000057; */
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-style: solid;
	border: 21px solid #0000008f;
}

.agenda-2020-filters ul li#op1{
	background-color: #00c0ee;
}
.agenda-2020-filters ul li#op2{
	background-color: #f10088;
}
.agenda-2020-filters ul li#op3{
	background-color: #898989;
}
.agenda-2020-filters ul li#op4{
	background-color: #ff52ff;
}

.vocabulary-eventos-clasificaci-n-general h2{
	font-size: 20px;
}

.view-id-agenda_2020 .vocabulary-eventos-clasificaci-n-general{
	display: inline-block;
}

#taxonomy-term-188 h2 a{
	font-size: 20px;
	color: #f10088;
	padding-left: 8px;
}

#taxonomy-term-189 h2 a{
	font-size: 20px;
	color: #f10088;
	padding-left: 8px;
}
#taxonomy-term-190 h2 a{
	font-size: 20px;
	color: #ff52ff;
	padding-left: 8px;
}

.agenda-2020-filters ul li .up{
	text-align:center;
	border-bottom: solid 2px #fff;
	width: 50%;
	margin-left: 25%;
}

.agenda2020-que-busca{
	text-align:center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 18px;
	color:#000;
	padding-bottom:24px;
}

.agenda2020-que-busca:before{
	content: " - ";
	background: url(https://cartelurbano.com/sites/default/files/barritaabajo.jpg) repeat-x;
	background-repeat: repeat-x;
	vertical-align: bottom;
	color: transparent;
	width: 37%;
	margin-top: 3px;
	position: absolute;
	right: 55%;
}

.agenda2020-que-busca:after{
	content: " - ";
	background: url(https://cartelurbano.com/sites/default/files/barritaabajo.jpg) repeat-x;
	background-repeat: repeat-x;
	vertical-align: bottom;
	color: transparent;
	width: 37%;
	margin-top: 3px;
	position: absolute;
	left: 55%;
}

/** This week **/

#agenda-2020 > div > div > div:nth-child(2) > div > div > div.view-header > div.agenda2020-section-title {
	margin-top: 25px;
}

#agenda-2020 > div > div > div:nth-child(2) > div > div > div.view-header > div.agenda2020-section-title > span.s:after {
	
	content: " - ";
	background: url(https://cartelurbano.com/sites/default/files/barritaabajo.jpg) repeat-x;
	background-repeat: repeat-x;
	vertical-align: bottom;
	color: transparent;
	width: 61%;
	margin-top: 12px;
	position: absolute;
	margin-left: 30px;

}

#agenda-2020 > div > div > div:nth-child(4) > div > div > div.view-header > div > span.s:after {
	
	content: " - ";
	background: url(https://cartelurbano.com/sites/default/files/barritaabajo.jpg) repeat-x;
	background-repeat: repeat-x;
	vertical-align: bottom;
	color: transparent;
	width: 52%;
	margin-top: 12px;
	position: absolute;
	margin-left: 30px;
}


#agenda-2020 div.field.field-name-title.field-type-ds.field-label-hidden > div > div > h2 {
	white-space: normal !important;
	max-width: 400px;
	word-wrap: break-word !important;
}


.view-agenda-2020 #node_eventos_agenda_2020_group_agenda_2020{
	width: 100%;
	text-align: center;
}

.view-agenda-2020 #node_eventos_agenda_2020_group_agenda_2020 > div{
	display: inline-block;
}

.view-agenda-2020 #node_eventos_agenda_2020_group_agenda_2020 .field-name-field-clasificacion-evento .field-items .field-item:before{
	content:"|  ";
	display: inline-block;
}
.view-agenda-2020 #node_eventos_agenda_2020_group_agenda_2020 .field-name-field-clasificacion-evento{
	padding-left: 5px;
}

/** Soon **/


.agenda2020-section-title .link{
	position: relative;
	left: 92%;
	vertical-align: top;
	font-size: 13px;
	top: -60px;
	letter-spacing: 4px;
}

.view-display-id-block_2 .agenda2020-section-title .link{
	position: relative;
	left: 92% !important;
	vertical-align: top;
	font-size: 13px;
	top: -59px;
}

.view-content{
	text-align:center !important;
}

#agenda-2020 > div > div > div:nth-child(4) > div > div > div.view-header{
	margin-bottom: 45px;
}

/**** Mobile ***/



@media only screen and (max-width: 600px) {


	#agenda-2020{
		width: 95;
		padding-left: 20px;
		padding-right: 20px;
	}

	.agenda2020-section-title {
		font-family: 'Alegreya',serif;
		font-weight: bold;
		margin-left: 5%;
		margin-right: 5%;
		color: #000;
	}


	.view-header{
		text-align:center !important;
	}
	.view-header>div>span.s:after {
		content: " - ";
		background: url(https://cartelurbano.com/sites/default/files/barritaarriba.jpg) repeat-x;
		background-repeat: repeat-x;
		vertical-align: bottom;
		color: transparent;
		margin-top: 12px  !important;
		position: relative;
		margin-left: 0px !important;
		margin-right: 50% !important;
		left: 15%;
	}

	#node_eventos_agenda_2020_group_agenda_2020{
		margin-left: 0 !important;
		margin-right: 0%;
	}

	
	.view-mode-agenda_2020 .field-name-title{
		margin-left: 0 !important;
		margin-right: 0%;
	}


	.agenda2020-que-busca:before{
		display:none !important;
	}
	.agenda2020-que-busca .s:after{
		display:none !important;
	}
	.agenda2020-que-busca:after{
		display:none !important;
	}

	.agenda2020-que-busca .s{
		text-align:center;
	}

	.agenda-2020-filters ul{
		padding-right: 8% !important;
		text-align:center !important;
	}
	.agenda-2020-filters ul li {
		width: 95% !important;
		display:block !important;
		margin-right: 5% !important;
		text-align:center !important;
	}



	.view-agenda-2020 .agenda2020-section-title *{
		display: block;
	}
	#agenda-2020>div>div>div:nth-child(2)>div>div>div.view-header>div.agenda2020-section-title>span.s:after {
		content: " - ";
		background: url(https://cartelurbano.com/sites/default/files/barritaabajo.jpg) repeat-x;
		background-repeat: repeat-x;
		vertical-align: bottom;
		color: transparent;
		width: 61%;
		margin-top: 71px !important;
		position: absolute;
		margin-left: 20px !important;
	}

	.agenda2020-section-title .link {
		position: relative;
		left: 0% !important;
		bottom: -21% !important;
		margin-top: 87px;
	}

	#agenda-2020>div>div>div:nth-child(4)>div>div>div.view-header>div>span.s:after {
		margin-top: 53px !important;
	}

	.view-display-id-block_2 .agenda2020-section-title .link {
		left: 0% !important;
		left: 0 !important;
	}

	#agenda-2020>div>div>div:nth-child(4)>div>div>div.view-header>div>span.s:after {
		margin-left: 30px !important;
	}

	#agenda-2020>div>div>div>div>div>div.view-content>div.views-row {
		width: 100% !important;
	}

	.view-agenda-2020 .view-mode-agenda_2020 .field-name-title {
		margin-left: 0 !important; 
		margin-right: 0 !important; 
	}
	#agenda-2020>div>div>div.panel-pane.pane-views>div>div>div.view-content>div>div>div.field.field-name-field-imagen-cuadrada.field-type-image.field-label-hidden>div>div>a>img {
		width: 100% !important;
		height: auto !important;
	}
	#agenda-2020 > div > div > div:nth-child(4) > div > div > div.view-header > div > span.s{
		position: relative;
    	left: -13px;
	}
}

