/* Slider Agenda
============================================================ */
.page-eventos .region-top {
	overflow: hidden;

	.view-mode-slider_home {
		overflow: hidden;
		position: relative;
	}

	.group-slider-textos {
		position: absolute;
		width: 50%;
		max-width: 800px;
		bottom: 50px;
		left: 15%;

		.field-name-field-fecha,
		.field-name-field-ciudad {
			display: inline;

			div, h2 { 
				display: inline;
				font-family: @strait;
				font-size: 16px;
				text-transform: uppercase;

				color: white;

				a { color: white; }
			}
		}

		.field-name-field-ciudad::before { 
			content: '  -  '; 
			color: white; 
			font-size: 16px;
			letter-spacing: 4px;
		}

		.field-name-title {
			h2 {
				margin-top: 0;
				text-shadow: 3px 3px 4px rgba(0,0,0,0.7);
				color: white;
				a { color: white; }
			}
		}

		.field-name-body {
			position: relative;
			min-height: 120px;
			padding: 15px 15px 15px 0;
			background-color: rgba(0,0,0,0.7);
			color: white;

			&::before {
				content:'';
				position: absolute;
				width: 100%;
				min-height: 100%;
				left: -100%;
				top: 0;
				background-color: rgba(0,0,0,0.7);
			}

			p {
				margin: 0;
			}
		}

		.field-name-field-tipo-de-evento {
			h2 { .hidden; }

			.field-name-field-icono {
				position: absolute;
				width: 60px;
				height: 60px;
				top: 5px;
				left: -77px;

				z-index: 2;

				text-align: center;
				line-height: 60px;
				font-size: 40px;
				color: white;

				.field-label { .hidden; }

				.field-items::after {
					content: '';
					position: absolute;
					width: 100%;
					min-height: 100%;
					left: 0;
					top: 0;
					background-color: @azul;
					z-index: -1;
					.rotate(45deg);
				}
			}
		}
	}
}

.agenda-top{
	.flexslider .field-name-field-imagen a { display: block; }
	.flexslider .field-name-field-imagen a::before{
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.4);
		background-image: url(../img/pattern-slider.png);
		background-repeat: repeat;
	}
}

/* grupo textos slider
----------------------------------------------------- */
.group-textos-eventos {
	position: absolute;
	a, a:hover {color: white;}
}

/* ---------------------------------------------- 
			estilos unicos moviles
----------------------------------------------- */
@media (max-width: @screen-sm-min) {
	.group-textos-eventos {
		top: 10px;
		left: 0;
		right: 0;
		color: white;

		.field-name-title h2 { 
			margin: 0 20px;
			border-bottom: 2px solid @azul;
			font-size: 22px;
		}

		.group-fecha-lugar {
			position: relative;
			width: 70%;
			margin-top: 10px;
			padding-left: 20px;

			background-color: rgba(7, 185, 227, 0.9);
			font-family: @strait;
			font-size: 14px;

			&::after {
				content:'';
				position: absolute;
				display: block;
				width: 0;
				height: 0;
				right: -20px;
				top: 0;
				border-top: 40px solid rgba(7, 185, 227, 0.9);
				border-right: 20px solid transparent;
			}

			.field-label { color: @negro; }

			.field-label,
			.field-items,
			.field-items .field-item { display: inline; float: none; }
		}
	}
}


/* ---------------------------------------------- 
			estilos unicos desktop
----------------------------------------------- */
@media (min-width: @screen-sm-min) {

	.group-textos-eventos {
		width: 775px;
		top: 50%;
  		left: 50%;

  		.translate(-50%, -50%);
  		z-index: 100;

  		text-align: center;
  		color: white;

  		.field-name-title h2 {
  			border-bottom: 3px solid @azul;
  			text-align: center;
  			font-size: 50px;
  		}

  		.field-name-field-entradilla { padding-top: 40px; }

  		.group-fecha-lugar {
			position: relative;
			display: inline-block;
			padding: 5px 25px;

			background-color: rgba(7, 185, 227, 1);
			text-transform: uppercase;
			font-family: @strait;
			font-size: 24px;

			&::after,
			&::before {
				content:'';
				position: absolute;
				display: block;
				width: 0;
				height: 0;
				top: 0;
				border-top: 45px solid rgba(7, 185, 227, 1);
			}

			&::after {
				right: -29px;
				border-right: 30px solid transparent;
			}

			&::before {
				left: -29px;
				border-left: 30px solid transparent;
			}

			.field-label { color: @negro; }

			.field-name-field-fecha,
			.field-name-field-donde,
			.field-label,
			.field-items,
			.field-items .field-item { display: inline; float: none; }

			.field-name-field-donde { 
				padding-left: 10px;
				&::before,
				&::after { .hidden(); }
			}
		}
	}

}/* end media */



















