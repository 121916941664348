
/* baner superior pages
=============================================================== */
.page-taxonomy-term{
   .region-top{
      .cabezote-seccion{
         position: relative;
	width:100%;
         &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 200px;
            background: #000000; /* Old browsers */
            background: linear-gradient(to bottom, #000000 0%,rgba(0,0,0,0) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000',             endColorstr='rgba(0,0,0,0)',GradientType=0 );
            z-index: 99;
         }
         &:after{
            display: block;
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.2);
         }
         img{
            transform: none !important;
         }
         .titulo-seccion{
            z-index: 1;
            h1{
               display: inline-block;
               position: relative;
               font-size: 47px;
               /*  &:after{
               content: '';
               display: block;
               position: absolute;
               bottom: -10px;
               left: 5%;
               width: 90%;
               height: 3px;
               border-bottom: solid 3px @color-musica;
               padding-bottom: 10px;
            }*/
            }
            span{display: none !important;}
         }/*/titulo-seccion*/
         .background-titulo-seccion{
            z-index: 1;
            // background: none;
            display: inline-block;
            padding: 0px 10px;
            margin-top: -74px;

            // position: relative;

            //background: #d6005f;
            left: 50%;
            transform: translate(-50%);
            width: auto;
            max-width: 68%;
            height: auto;
            &::before{
               content: '';
               display: block;
               height: 0px;
               width: 0px;
               border-bottom: solid 26px transparent;
               position: absolute;
               top: 0px;
               left: -20px;

               //border-right: solid 20px #d6005f;
            }
            &::after{
               content: '';
               display: block;
               height: 0px;
               width: 0px;
               border-bottom: solid 26px transparent;
               position: absolute;
               top: 0px;
               right: -20px;

               //border-left: solid 20px #d6005f;
            }
            .texto-entradilla-seccion{
               width: auto;
               height: auto;
               p{
                  font-family: 'bebas_neueregular';
                  font-size: 20px;
                  line-height: 26px;
               }
               &::before{
                  content: " ";
                  display: block;
                  position: absolute;
                  width: 125%;
                  height: 3px;
                  //background-color: red;
                  top: -13px;
                  left: 50%;
                  transform: translate(-50%);
               }
            }/*/texto-entradilla-seccion*/
         }
      }/*/cabezote-seccion*/
   }
}/*/.page-taxonomy-term*/



/*nodo destacado
========================================*/
.page-taxonomy-term{
   .one-column-main.cartel-panel{
      .region-main > .panel-separator:nth-of-type(5) {
         display: none !important;
      }
      .panel-pane.pane-views.pane-nodequeue-1{/*nodo*/
         .group-container-all{
            display: inline-block;
            position: relative;
            width: auto;
            height: auto;
            width: 66%;
            height: auto;
            vertical-align: middle;
            .field-name-field-imagen-cuadrada.field-type-image{/*img con*/

               &::before{
                  content: " ";
                  position: absolute;
                  left: 30px;
                  right: 30px;
                  top: 30px;
                  bottom: 30px;
                  border: 2px solid white;
                  background-color: rgba(0,0,0,0.5);
                  margin: auto;
                  z-index: 1;
               }
            }
            .group-texto{/*info*/
               z-index: 2;
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
               .field-name-title{/*titulo*/
                  h2{
                     font-size: 45px;
                     text-align: center;
                     a{
                        color: #fff;
                     }
                  }
               }/*field-name-title */
               .group-texto-fecha{/*fecha*/
                  //background-color: #d6005f;
                  position: relative;
                  z-index: 1;
                  display: inline-block;
                  padding: 0px 10px;
                  margin-top: -74px;
                  left: 50%;
                  transform: translate(-50%);
                  width: auto;
                  height: auto;

                  &::before{
                     content: '';
                     display: block;
                     height: 0px;
                     width: 0px;
                     border-bottom: solid 26px transparent;
                     position: absolute;
                     top: 0px;
                     left: -20px;

                     //border-right: solid 20px #d6005f;
                  }
                  &::after{
                     content: '';
                     display: block;
                     height: 0px;
                     width: 0px;
                     border-bottom: solid 26px transparent;
                     position: absolute;
                     top: 0px;
                     right: -20px;

                     // border-left: solid 20px #d6005f;
                  }
                  .field-name-post-date, .field-name-field-seccion{
                     display: inline-block;
                     font-family: 'bebas_neueregular';
                     font-size: 20px;
                     line-height: 26px;
                     color: @blanco;
                     margin-right: 5px;
                  }
                  .field-name-post-date{
                     &::after{
                        content: " ";
                        width: 2px;
                        height: 100%;
                        background-color: @blanco;
                     }
                  }
               }/*group-texto-fecha*/
            }/*/.group-texto*/
         }
      }/*/nodo*/
      .field-name-summary-body{
         display: inline-block;
         width: 32%;
         margin-left: 2%;
         vertical-align: middle;
      }
      .pane-cartel-section-publicidad{/*publicidad*/
         display: none;
      }
      .panel-separator {
         display: inline-block;
      }
      .pane-dfp-pane {
         display: inline-block;
         float: right;
         padding-right: 20px;
         margin-top: 8%;
         @media(max-width: 768px){
             float: none;
         }
      }
      .panel-pane.pane-block.pane-cu-list-content-section-block-list-content-section {
         clear: both;
      }
   }

   /*publicidad Lateral*/
   .one-column-main .region-main{

   }
}


.front{
   .view-ver-mas-home.view-id-ver_mas_home{
      .view-content.hidden{
         //display: block !important;
         ul{
            width: 100%;
            li.views-row{
               width: auto;
               height: 375px;
            }
         }
      }
   }
}

@media (min-width:1025px) {
	.view-id-causas_historias .view-content .views-row{
	    width: 30%;
	    display: inline-grid;
	}
}

