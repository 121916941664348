.field-name-eventos-mas-populares{
    width: 100%;
    //height: 372px;/*una fila*/
    height: auto;/*dos dilas*/
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    //background: none !important;
    @media (max-width: 1366px) {
        height: auto;
    }
}


/*
Eventos recomenados horizontal (02 septiembre 2016)
========================================================================*/
/*titulo*/
.group-eventos-recomendados {
    h3 {
        background-color: #07b9e3;
        padding: 5px 10px;
        font-size: 30px;
        span {
            color: #fff;
            &:before {
                content: 'H';
                position: relative;
                font-family: "cartel-urbano" !important;
                margin-right: 10px;
                top: 3px;
            }
        }
    }
    @media(max-width:768px){
        padding: 0 30px;        
    }
}

.field-name-eventos-horizontales {
        position: relative;
        img.img-temp {
            display: none;
        }
        .node-eventos{
        padding-bottom: 10px;
        width: 32%;
        display: inline-block;
        vertical-align: top;
        @media(max-width: 768px){
            width: 100%;
        }
        &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(6){
            margin-left: 1.6%;
            @media (max-width: 1375px) {
                margin-left: 1.5%;
            }
            @media (max-width: 1116px) {
                margin-left: 1.4%;
            }
            @media (max-width: 944px) {
                margin-left: 1.3%;
            }
        }
        .group-arriba-galeria-popular{
            position: relative;
            .field-item {
                img {
                    margin: 0;
                }
            }
            .field-name-gane-entradas {
              background: #D6005F;
              position: absolute;
              z-index: 9;
              right: 0;
              top: 0;
              font-size: 19px;
              font-family: 'bebas_neueregular',Arial-narrow;
              padding: 0px 17px 0px 5px;
              &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 14px;
                  height: 27px;
                  border-width: 0px 21px 27px 0;
                  border-color: transparent #D6005F transparent transparent;
                  border-style: solid;
                  transform: translateX(-100%);
              }
              p {
                margin: 0;
              }
              a {
                color: #fff;

              }
            }
            .field-name-field-fecha{
                position: absolute;
                bottom: 1px;
                left: 0;
                span{
                    background-color: @azul;
                    padding: 1px 9px;
                    position: relative;
                    display: block;
                    font-size: 15px;
                    top: 1px;
                    &::after{
                        content: "";
                        position: absolute;
                        top: -0px;
                        right: -23px;
                        width: 0;
                        height: 0;
                        border-bottom: 29px solid @azul;
                        border-right: 24px solid transparent;
                    }
                    a{
                        position: relative;
                        left: 4px;
                        top: 1px;
                        font-size: 19px;
                        color: @blanco;
                        font-family: 'bebas_neueregular',Arial-narrow;
                    }
                }
            }
        }

        .group-abajo-galeria-popular{
            position: relative;
            padding: 0 1rem;
            &::after{
              content: "";
              position: relative;
              width: 100%;
              height: 1px;
              background-color: #d8d7d7;
              bottom: 0px;
              clear: both;
              display: block;
            }
            .field-name-title{
                h2{
                    margin-bottom: 0;
                    text-align: left;
                    letter-spacing: 1px;
                    font-size: 19px;
                    font-weight: 500;
                    a{
                        color: #696969;
                    }
                }
            }
            .field-name-field-precio {
                text-align: right;
                float: right;
                a{

                    font-size: 15px;
                    color: #b1afaf;
                    font-family: 'bebas_neueregular',Arial-narrow;
                }
            }
            .field-name-field-tipo-de-evento {
                text-align: right;
                float: left;
                color: @azul;
                font-size: 15px;
                font-family: 'bebas_neueregular',Arial-narrow;
            }
        }
    }
    .recomendados-ver-todos {
        font-family: 'bebas_neueregular',Arial-narrow;
        margin: 0 auto 1rem;
        display: block;
        text-align: center;
        font-size: 20px;
        &:hover:after, &:hover:before{
            background: #1e6c7c;;            
        }
        &:after{
            width: 43%;
            height: 2px;
            position: absolute;
            background: #07B9E3;
            bottom: 13px;
            content: "";
            left: 15px;
            z-index: -1;
        }
        &:before{
            width: 43%;
            height: 2px;
            position: absolute;
            background: #07B9E3;
            bottom: 13px;
            content: "";
            right: 15px;
            z-index: -1;
        }
        @media (max-width: 1200px) {
            &:after, &:before {
                width: 40%;
            }
        }
        @media (max-width: 768px) {
            &:after, &:before {
                width: 30%;
            }
        }   
    }
}

/*Fin eventos recomendados*/


/*
Nuevo diseño lo mas popular (24 mayo 2016)
=======================================================================0*/
section.block-cu-mas-popular,
.field-name-lo-mas-popular-block {
    background: none !important;
    .block-title{
        background-color: #07b9e3;
        margin: 0;
        color: #fff;
        font-size: 35px;
        text-align: left;
        padding-left: 20px;
        &::before{
          position: relative;
          display: inline-block;
          width: 40px;
          height: 40px;
          top: 5px;
          z-index: 999;
          content: "H";
          font-family: cartel-urbano;
          background: none !important;
          font-size: 31px;
        }
    }

    .carga-imagen{
        .contenedor-popuar{
            padding: 0 !important;
        }
        .popular-item{
            padding: 10px;
            padding-left: 0 !important;
            text-align: center;
            width: 100%;
            display: inline-block;
            .popular-item-information{
                a {
                    font-size: 0;
                    img{
                        display: inline-block;
                        vertical-align: top;
                    }
                    .column-float{
                        display: inline-block;
                        color: #000;
                        vertical-align: top;
                        padding-left: 10px;
                        margin-left: 0 !important;
                        text-align: left;
                        &>div{
                            display: inline-block;
                        }
                        h4{
                            text-align: left;
                            letter-spacing: 0.5px;
                            margin-top: 5px !important;
                            color: #696969 !important;
                        }
                        .field-name-clone-seccion{
                            .field-name-field-seccion{
                                text-align: left;
                                .taxonomy-term{
                                    padding: 0 5px;
                                }
                                /*colore*/
                                .taxonomy-term-Música{ background-color: @color-musica;}
                                .taxonomy-term-Deportes{ background-color: @color-deportes;}
                                .taxonomy-term-Arte{ background-color: @color-arte;}
                                .taxonomy-term-Noticias{background-color: @color-noticias;}
                                .taxonomy-term-Sexo{ background-color: @color-sexo;}
                                .taxonomy-term-Opinión{ background-color: @color-opinion;}
                                .taxonomy-term-Diseño{   background-color: @color-diseno;}
                                .taxonomy-term-Agenda{background-color: @color-agenda;}
                                .taxonomy-term-Historias{background-color: @color-historias;}
                                .taxonomy-term-Moda{ background-color: @color-moda;}
                                .taxonomy-term-420{ background-color: @color-420;}

                                .field-name-field-icono {
                                    display: inline-block;
                                    &> div{
                                        margin-right: 7px;
                                        font-size: 17px;
                                        color: white;
                                    }
                                }
                                .field-name-title{
                                    display: inline-block;
                                    h3{
                                        margin: 0;
                                        font-size: 16px;
                                        color: white;

                                        position: relative;
                                        top: -2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*lo mas popular home*/
section.block-cu-mas-popular{
    .carga-imagen{
        .popular-item{
            .popular-item-information{
                a {
                    img{
                        width: 40% !important;
                    }
                    .column-float{
                        width: 60% !important;
                        h4{
                            font-size: 20px !important;
                        }
                    }
                }
            }
        }
    }
}

/*eventos recomentdados horizontales interna boeltines respuesta newsletter (23 sep 2016)*/
.field-name-eventos-horizontales.evento-boletin {
    height: auto;
    background-color: #fff; 
    .pane-content {
        padding: 10px 0;
    }
    .node-eventos {
        margin-bottom: 15px !important;
        .group-arriba-galeria-popular .field-item img{
            width: 100%;
            height: auto;
        }
        .group-abajo-galeria-popular{
            padding: 0 0rem 0 1rem;
        }
    }
    .recomendados-ver-todos {
        display: none;
    }
}

/*lo mas pupular interno*/
.field-name-lo-mas-popular-block{
    box-shadow: 5px 5px 20px 0 rgba(50,50,50,0.77);
    .block-title{
        &::before{
            background: url(../img/icon/logos-02.svg) !important;
            left: -7px;
            height: 37px !important;
            top: 2px!important;
        }
    }
    .carga-imagen{
        .popular-item{
            .popular-item-information{
                a {
                    img{
                        width: 40% !important;
                    }
                    .column-float{
                        width: 60% !important;
                        h4{
                            font-size: 19px !important;
                        }

                    }
                }
            }
        }
    }
}
.field-name-block-fieldeventos-mas-populares {
    box-shadow: 5px 5px 20px 0 rgba(50,50,50,0.77);
}


@media (max-width: 767px) and (min-width: 320px) {
    //.field-name-lo-mas-popular-block,
    .pane-cu-mas-popular-los-eventos-mas-popular,
    .block-cu-mas-popular{
        width: 100% !important;
        height: auto;
        margin: 0 auto;
        box-sizing: border-box;
        clear: both;
        //float: right;
        //margin: 13px;
        margin-right: 0;

        .pane-content, .contenedor-popuar{
            // padding: 10px;
            padding: 0 !important;
            .popular-container{
                .popular-item{
                    margin-left: 0;
                }
                .popular-item:nth-child(6) {
                    display: none;
                }
            }
        }
    }
}


/*
lo mas reciente
=====================================================================*/
.field-name-lo-mas-reciente,
#block-cu-mas-popular-las-secciones-mas-populares,
.field-name-lo-mas-popular-block,
.field-name-recent-content{
    padding-bottom: 0;
    background: none !important;
    box-shadow: 5px 5px 20px 0 rgba(50,50,50,0.77);
    .view-header{
        .block-title2{
            &::before{
                background: url(../img/icon/logos-03.svg) !important;
                left: -7px;
                height: 36px !important;
            }
        }
    }
    .view-content, .carga-imagen{
        .views-row, .view-mode-articulos_recomendados{
            padding-left: 0 !important;
            font-size: 0;
            width: 100%;
            clear: both;
            padding: 10px;
            .field-name-field-imagen-cuadrada, .field-name-field-imagen{
                width: 40% !important;
                display: inline-block;
                vertical-align: top;
                .field-item{
                    blockquote{
                        display: none;
                    }
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
            .group-flotado, #node_article_articulos_recomendados_group_elemento_flotado{
                width: 60%;
                display: inline-block;
                vertical-align: top;
                padding-left: 10px;
                text-align: left;
                .field-name-field-seccion.field-type-taxonomy-term-reference{
                    & > div{

                    }
                    .field-items{
                        margin: 0;
                    }
                    .taxonomy-term{
                        display: inline-block;
                        /*padding: 0 5px;*/
                        /*colores*/
                        &.taxonomy-term-música{ background-color: @color-musica;}
                        &.taxonomy-term-deportes{ background-color: @color-deportes;}
                        &.taxonomy-term-arte{ background-color: @color-arte;}
                        &.taxonomy-term-noticias{background-color: @color-noticias;}
                        &.taxonomy-term-sexo{ background-color: @color-sexo;}
                        &.taxonomy-term-opinión{ background-color: @color-opinion;}
                        &.taxonomy-term-diseño{   background-color: @color-diseno;}
                        &.taxonomy-term-agenda{background-color: @color-agenda;}
                        &.taxonomy-term-historias{background-color: @color-historias;}
                        &.taxonomy-term-moda{ background-color: @color-moda;}
                        &.taxonomy-term-420{ background-color: @color-420;}
                        .field-name-field-icono{
                            display: inline-block;
                            a{
                                margin-right: 7px;
                                font-size: 17px;
                                color: white;
                            }
                        }
                        .field-name-title{
                            display: inline-block;
                            h3{
                                margin: 0;
                                font-size: 0;
                                a{
                                    margin: 0;
                                    font-size: 16px ;
                                   // color: white !important;
                                    position: relative;
                                    top: -2px;
                                    font-weight: inherit;
                                }
                            }
                        }
                    }
                }
                .field-name-title{
                    margin-top: 5px;
                    div {
                        margin: 0;
                        h2 {line-height: 0;}
                        a{
                            text-align: left;
                            font-size: 19px !important;
                            letter-spacing: 1px;
                            margin-top: 5px !important;
                            color: #696969 !important;
                            font-family: 'bebas_neueregular',Arial-narrow;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
}


/*
Galerias mas recientes
=====================================================================*/
.field-name-galerias-recientes, #node_article_detalle_sidebar_group_galerias_horizontales_rec{
    box-shadow: 4px 3px 11px rgba(0,0,0,0.7);
    .view-header{
        .block-title2 {
            background-color: @azul;
            margin: 0;
            color: @blanco;
            font-size: 30px;
            text-align: left;
            padding: 0 0 10px 10px;
            &::before {
                content: " ";
                position: relative;
                display: inline-block;
                width: 40px;
                height: 40px;
                top: 5px;
                left: -4px;
                background: url(../img/icon/logos-01.svg);
                background-repeat: no-repeat;
                background-size: contain;
                z-index: 999;
            }
            @media (max-width: 1279px) and (min-width: 767px){
                padding-bottom: 5px;
                font-size: 20px;
                padding-left: 10px;
                &:before{
                    width: 25px;
                    height: 26px;
                } 
            }
        }
    }

    .views-row{
        .view-mode-galeria_popular{
            .group-arriba-galeria-popular{
                position: relative;
                .field-name-field-imagen{
                    img{
                        width: 100%;
                        height: auto;
                    }
                    blockquote{
                        display: none !important;
                    }
                }
                .field-name-field-seccion{
                    position: absolute;
                    left: 2rem;
                    bottom: 0;
                    .field-items{
                        margin: 0;
                        .vocabulary-sections{
                            padding: 0 10px;
                            /*colore*/
                            &.taxonomy-term-música{ background-color: @color-musica;}
                            &.taxonomy-term-deportes{ background-color: @color-deportes;}
                            &.taxonomy-term-arte{ background-color: @color-arte;}
                            &.taxonomy-term-noticias{background-color: @color-noticias;}
                            &.taxonomy-term-sexo{ background-color: @color-sexo;}
                            &.taxonomy-term-opinión{ background-color: @color-opinion;}
                            &.taxonomy-term-diseño{   background-color: @color-diseno;}
                            &.taxonomy-term-agenda{background-color: @color-agenda;}
                            &.taxonomy-term-historias{background-color: @color-historias;}
                            &.taxonomy-term-moda{ background-color: @color-moda;}
                            &.taxonomy-term-420{ background-color: @color-420;}
                            .field-name-field-icono{
                                display: inline-block;
                                a{
                                    position: relative;
                                    top: 1px;
                                    margin-right: 2px;
                                    font-size: 17px;
                                    color: white;
                                }
                            }
                            .field-name-title{
                                display: inline-block;
                                h3{
                                    margin: 0;
                                    font-size: 16px;
                                    a{
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .group-abajo-galeria-popular{
                padding: 0 2rem;
                h2{
                    font-weight: bold;
                    text-align: left;
                    letter-spacing: 1px;
                    font-size: 19px;
                    font-weight: 500;
                    a{
                        color: #696969;
                    }
                }
            }
        }
    }
}
/*
Galerias Horizontal
=====================================================================*/
#node_article_detalle_sidebar_group_galerias_horizontales_rec {
    box-shadow: none;
    .view-header{
        background-color: #07b9e3;
        padding: 0px 10px;
        font-size: 30px;
        @media (max-width: 1279px) and (min-width: 767px){
            padding: 4px 10px;
        }
        .block-title2{
            color: #fff;
            position: relative;
            top: -4px;
            background-color: transparent;
            padding: 0;
            @media (max-width: 1279px) and (min-width: 767px){
                font-size: 30px; 
                &:before{
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
    .views-row{
        padding-bottom: 10px;
        width: 32%;
        display: inline-block;
        vertical-align: top;
        &:nth-child(2), &:nth-child(3),
        &:nth-child(5), &:nth-child(6){ 
            margin-left: 1.5%; 
            @media (max-width: 1116px){
                margin-left: 1.4%;
            }
            @media (max-width: 944px){
                margin-left: 1.3%;
            }
        }
        .view-mode-galeria_popular{
             .group-arriba-galeria-popular{
                .field-name-field-imagen img {
                    margin: 10px 0 0;
                }
                .field-name-field-seccion {
                    left: 1rem;
                }   
            }
            .group-abajo-galeria-popular {
                padding: 0 1rem;
            }
        }
    }
}

/*
new letter
=====================================================================*/
.field-name-newsletter-block{
    background-color: @azul;
    form{
        padding: 1em;
        p{
            margin-bottom: 15px;
            font-family: 'bebas_neueregular',Arial-narrow;
            color: @blanco;
            font-size: 3.5rem;
            line-height: 1;
            text-align: center;
        }
        input{
            background-color: @blanco;
            color: @gris;
            width: 85%;
            margin: 0 auto;
            &::-webkit-input-placeholder {padding: 0;  color: @gris;  text-align: center; font-size: 2.2rem; }
            &::-moz-placeholder {padding: 0;  color: @gris; text-align: center; font-size: 2.2rem; } /* firefox 19+ */
            &:-ms-input-placeholder {padding: 0;  color: @gris; text-align: center; font-size: 2.2rem; } /* ie */
            &:-moz-placeholder {padding: 0;  color: @gris; text-align: center; font-size: 2.2rem; }
        }
        button{
            background-color: @negro;
            border: none;
            border-radius: 0;
            position: relative;
            left: 50%;
            transform: translate(-50%);
            font-family: 'bebas_neueregular',Arial-narrow;
            font-size: 2.5rem;
            color: @blanco;
            &:hover{
                background-color: @negro;
            }
            &::after {
                content: " ";
                position: relative;
                display: inline-block;
                width: 25px;
                height: 26px;
                top: 3px;
                margin-left: 5px;
                background: url(../img/icon/logos-04.svg);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}
/*pag galerias*/
.node-type-gallery{
    .field-name-newsletter-block{
        box-shadow: 4px 3px 11px rgba(0,0,0,0.7);
        background: none;
        border: 2px solid @azul;
        p{
            color: @negro;
            text-align: center;
        }
        input{
            border: 1px solid @negro;
        }
        button{
            background-color: @azul;
            color: @blanco;
            &::after{
                color: @blanco;
            }
        }
    }
}


/*
eventos recomendados
=====================================================================*/
.field-name-block-fieldeventos-mas-populares{
    @media (max-width: 767px) and (min-width: 320px) {}
    h2.block-title{
        @media (max-width: 1280px){
            padding-bottom: 5px;
            font-size: 32px;
        }
        @media (max-width: 1280px) {
            padding-bottom: 8px;
            font-size: 32px;
        }
    }
    width: 100%;
    box-shadow: 4px 3px 11px rgba(0,0,0,0.7);
    h2.block-title{
        font-size: 30px;
        padding-bottom: 5px;
    }
    .node-eventos{
        padding-bottom: 10px;
        .group-arriba-galeria-popular{
            position: relative;
            .field-name-gane-entradas {
              background: #D6005F;
              position: absolute;
              z-index: 9;
              right: 0;
              top: 0;
              font-size: 19px;
              font-family: 'bebas_neueregular',Arial-narrow;
              padding: 0px 17px 0px 5px;
              &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 14px;
                  height: 27px;
                  border-width: 0px 21px 27px 0;
                  border-color: transparent #D6005F transparent transparent;
                  border-style: solid;
                  transform: translateX(-100%);
              }
              p {
                margin: 0;
              }
              a {
                color: #fff;

              }
            }
            .field-name-field-fecha{
                position: absolute;
                bottom: 1px;
                left: 0;
                span{
                    background-color: @azul;
                    padding: 1px 9px;
                    position: relative;
                    display: block;
                    font-size: 15px;
                    top: 1px;
                    &::after{
                        content: "";
                        position: absolute;
                        top: -0px;
                        right: -23px;
                        width: 0;
                        height: 0;
                        border-bottom: 29px solid @azul;
                        border-right: 24px solid transparent;
                    }
                    a{
                        position: relative;
                        left: 4px;
                        top: 1px;
                        font-size: 19px;
                        color: @blanco;
                        font-family: 'bebas_neueregular',Arial-narrow;
                    }
                }
            }
        }

        .group-abajo-galeria-popular{
            position: relative;
            padding: 0 2rem;
            &::after{
              content: "";
              position: relative;
              width: 100%;
              height: 1px;
              background-color: #d8d7d7;
              bottom: 0px;
              clear: both;
              display: block;
            }
            .field-name-title{
                h2{
                    margin-bottom: 0;
                    text-align: left;
                    letter-spacing: 1px;
                    font-size: 19px;
                    font-weight: 500;
                    a{
                        color: #696969;
                    }
                }
            }
            .field-name-field-precio {
                text-align: right;
                float: right;
                a{

                    font-size: 15px;
                    color: #b1afaf;
                    font-family: 'bebas_neueregular',Arial-narrow;
                }
            }
            .field-name-field-tipo-de-evento {
                text-align: right;
                float: left;
                color: @azul;
                font-size: 15px;
                font-family: 'bebas_neueregular',Arial-narrow;
            }
        }
    }


}
@media (min-width: 767px) and (max-width: 1279px) {
    .page-node {
        .field-name-block-fieldeventos-mas-populares, .field-name-lo-mas-popular-block,
        .field-name-lo-mas-reciente .field-items,
        .field-name-recent-content .field-items{
            h2.block-title, h2.block-title2{
                padding-bottom: 5px;
                font-size: 20px;
                padding-left: 10px;
                &:before {
                    //background: transparent no-repeat 0 0/16px !important;
                    width: 25px;
                    height: 26px;
                }
            }
        }
        .field-name-block-fieldeventos-mas-populares h2.block-title:before {
          content: "H";
          font-family: cartel-urbano;
          background: none !important;
          font-size: 22px;
        }
        .field-name-lo-mas-popular-block h2.block-title:before {
            background: url(../img/icon/logos-02-small.svg) !important;
            height: 22px !important;
        }
        .field-name-lo-mas-reciente .field-items .block-title2:before,
        .field-name-recent-content  .field-items .block-title2:before{
            background: url(../img/icon/logos-03-small.svg) !important;
            height: 24px !important;
        }
    }
}

@media (min-width: 1280px) {
  .page-node {
    .field-name-lo-mas-popular-block {
      h2.block-title {
        margin-top: -7px;
        &:before {
          top: 6px !important;
        }
      }
    }
    .field-name-lo-mas-reciente,
    .field-name-recent-content{
      h2.block-title2 {
        line-height: 1.42;
        padding: 0 !important;
        margin: 0 !important;
        &:before {
          top: 6px !important;
          left: 1px;
        }
      }
    }
    .field-name-block-fieldeventos-mas-populares, .field-name-lo-mas-popular-block, .field-name-lo-mas-reciente, .field-name-recent-content {
        h2.block-title, h2.block-title2 {
          padding: 5px 0;
          font-size: 30px;
          padding-left: 10px;
          margin-top: -11px;
            &:before {
              top: 10px;
            }
        }
    }
  }
}
/*=======================================================================================================================*/

/*margen right*/
.page-node .block-system .region{
    .region-right{
        >.field-name-publicidad{
            margin: 0 auto 34px!important;
            @media(max-width: 768px){
                  margin: 2em auto !important;
            }
        }
        >div{
            margin: 0 auto 22px !important;
            box-shadow: none;
            width: 300px;
            margin: 0 auto;
            @media(max-width:768px){
                margin: 15px auto 22px !important;
                display: block;
            }
            &.field-name-lo-mas-reciente,
            &.field-name-recent-content{
                .field-items{ margin-bottom: 0;}
            }
            &:nth-child(1){
                margin-top: 0 !important;
                text-align: center;
                @media(max-width: 768px){
                    margin: 0 auto !important;
                    width: 100%;
                    text-align: center;
                }                
            }
            &.field-name-lo-mas-popular-block{
                margin-top: 2em !important;
                box-shadow: none;
                width: 300px;
                @media(max-width: 768px){
                    margin: 2em auto !important;
                }
                #container-popular-paginas{
                    border-top: 0;
                }
            }
            &.field-name-galerias-recientes{
                margin-bottom: 20px !important;
                margin: 20px auto !important;

            }
            #container-popular-events, .view-recent-content {
              a.recomendados-ver-todos {
                display: flex;
                justify-content: center;
                padding: .25rem 0;
                color: #07b9e3;
                font-size: 21px;
                font-family: 'bebas_neueregular',Arial-narrow;
                letter-spacing: 1px;
              }
            }
        }
    }
}



/*nodo publicidad pro
======================================0*/
/*ocultar publicidad*/

.field.field-name-field-imagen-728-arriba.field-type-image.field-label-hidden{
    img{
        margin: 0 !important;  
    }   
}
.field.field-name-field-imagen-banner-abajo.field-type-image.field-label-hidden,
.field.field-name-publicidad.field-type-ds.field-label-hidden{
    text-align: center !important;
}


#node_article_contenido_reciente_group_flotado > div.field.field-name-field-seccion.field-type-taxonomy-term-reference.field-label-hidden > div > div > div > div.field.field-name-title.field-type-ds.field-label-hidden > div > div > h3{
    margin: 0 !important;
    font-size: 21px !important;
    position: relative !important;
    top: -2px !important;
    font-weight: inherit !important;
}