
@media (max-width: 767px) and (min-width: 320px) {
   .page-proteccion-de-datos{
      .agenda-top{
         position: relative;
         .texto-politicas {
            font-size: 26px;
            width: 100%;
         }
      }
      .region-content{
         margin-top: 82px;
         .tabs-processed{/*tabs*/
            border: none;
            .ui-tabs-nav{/*ul*/
               width: 100%;
               margin-top: -24px;
               .ui-state-default{
                  width: 100%;
                  background-color: @gris;
                  a{
                     color: @blanco;
                     font-size: 15px;
                     border-top: 1px solid @blanco;
                  }
               }
            }
            .ui-tabs-panel{
               width: 90%;
            }
         }
      }
   }
}
