/*-----------------------------------------------------------------------*/
/*---------------------------------hover---------------------------------*/
/*-----------------------------------------------------------------------*/

@media screen and (min-width: 480px) {

   /*-----------------------------------------------------------------------*/
   /*----------------------------nodo una col--------------------------------*/
   .node.view-mode-destacados_home_general {
      .group-hover,
      .field-name-field-imagen-cuadrada::after {
         opacity: 1;
      }
      .group-hover {
         transform: translate(-50%, -50%) scale(1);
      }
      .field-name-field-imagen-cuadrada::after {
         transform: scale(1);
      }
      .field-name-field-imagen-cuadrada img {
         //transform: scale(1.3);
      }
      .group-texto-subtitulo {
         background: transparent !important;
      }
      .group-texto-subtitulo .field-name-title,
      .group-texto-subtitulo .field-name-field-subtitulo {
         transform: translate(0, 200%);
         opacity: 0;
      }
      .group-contenedor-fecha {
         background-color: #1a1918 !important;
         color: white !important;
         &::after {
            background-color: white;
         }
      }
      /* .group-contenedor > .field-name-clone-seccion{
      transform: translate(-100%, 0);
   }*/
   }

   /*fin hover*/
   .node.view-mode-destacados_home_general:hover {
      .field-name-field-imagen-cuadrada::after {
         transform: scale(1);
      }
      .field-name-field-imagen-cuadrada img {
         transform: scale(1.3);
      }
   }

   /*-----------------------------------------------------------------------*/
   /*---------------------------hover nodos---------------------------------*/
   .node.view-mode-card_destacado_home:hover {
      .group-hover,
      .field-name-field-imagen::after {
         opacity: 1;
      }
      .group-hover {
         transform: translate(-50%, -50%) scale(1);
      }
      .field-name-field-imagen::after {
         transform: scale(1);
      }
      .field-name-field-imagen img {
         transform: scale(1.3);
      }
      .group-texto-subtitulo {
         background: transparent !important;
      }
      .group-texto-subtitulo .field-name-title,
      .group-texto-subtitulo .field-name-field-subtitulo {
         transform: translate(0, 200%);
         opacity: 0;
      }
      .group-contenedor-fecha {
         background-color: #1a1918 !important;
         color: white !important;
         &::after {
            background-color: white;
         }
      }
      /* .group-contenedor > .field-name-clone-seccion{
      transform: translate(-100%, 0);
   }*/
   }

   /*fin hoveeer*/
   /*-----------------------------------------------------------------------*/
   /*---------------------------hover video---------------------------------*/
   .node.node-video:hover {

      .field-name-field-imagen-cuadrada {
         &::after {
            background-color: rgba(0, 0, 0, 0.5);
         }
      }
      .group-hover {
         &::before {
            top: -20px;
            transform: rotateX(150deg);
         }
      }
   }

   /*fin hoveeer*/
   /*-----------------------------------------------------------------------*/
   /*---------------------------hover galeria---------------------------------*/
   .node.node-gallery:hover {
      .field-name-field-imagen-cuadrada {
         &::after {
            background-color: rgba(0, 0, 0, 0.5);
         }
      }
   }

   /*fin hoveeer*/
   .view-mode-card_destacado_home.node-gallery:hover {
      .field-name-field-imagen {
         &::after {
            background-color: rgba(0, 0, 0, 0.5);
         }
      }
   }

   /*fin hoveeer*/

}

/*media-Q*/

/*hide entradilla cont relacionado*/
.page-node {
   #node_article_destacados_home_general_group_texto_subtitulo {
      min-height: 0;
   }
}
