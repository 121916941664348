/*
eventos populares
===================================================0*/
.region-left .group-eventos-populares{

	//titulo
	h3{
		background-color: @azul;
		margin: 0;
		padding: 5px 10px;
		vertical-align: middle;
		font-size: 30px;
		text-align: left;
		margin-bottom: 0.5em;
		&::before{
			content: " ";
			position: relative;
			display: inline-block;
			width: 40px;
			height: 40px;
			top: 0;
			left: -4px;
			background: url(../img/icon/logos-01.svg);
			background-repeat: no-repeat;
			background-size: contain;
			z-index: 999;
			vertical-align: middle;
		}
		span{
			color: @blanco;
		}
	}


	.field-name-eventos-horizontales{
		.carga-nodos{
			font-size: 0;
			.node{
				display: inline-block;
				width: calc(33.33% - 5.5px);
				padding: 0;
				vertical-align: top;
				//margin: 0 5px;
				margin-bottom: 20px;
				border-bottom: 1px solid #ccc;
				font-size: 12px;

				&:nth-of-type(2),
				&:nth-of-type(5){
					margin: 0 8px;
				}

				> div{
					position: relative;

					&:nth-of-type(1){
						.field-type-image{ //img
							img{
								margin: 0;
							}
						}
						.field-type-datetime{
							position: absolute;
							left: 0; bottom: 0;
							.field-item{
								span{
									background-color: @azul;
									padding: 1px 9px;
									position: relative;
									display: block;
									font-size: 15px;
									top: 0;
									&::after{
										content: "";
										position: absolute;
										top: 0;
										right: -23px;
										width: 0;
										height: 0;
										border-bottom: 24px solid @azul;
										border-right: 24px solid transparent;
									}
									a{
										position: relative;
										left: 4px;
										top: 1px;
										font-size: 15px;
										color: @blanco;
										font-family: 'bebas_neueregular',Arial-narrow;
									}
								}
							}
						}
					}

					&:nth-of-type(2){
						padding: 0 1rem;

						.field-name-title{
							h2{
								margin-bottom: 0;
								text-align: left;
								letter-spacing: 1px;
								font-size: 17px;
								font-weight: 500;
								a{
									color: #696969;
								}
							}
						}
						.field-name-field-precio{
							text-align: right;
							float: right;
							a{
								font-size: 13px;
								color: #b1afaf;
								font-family: 'bebas_neueregular',Arial-narrow;
							}
						}
						.field-name-field-tipo-de-evento{
							text-align: right;
							float: left;
							div{
								color: #07b9e3;
								font-size: 13px;
								font-family: 'bebas_neueregular',Arial-narrow;
							}
						}
					}

				}
			}
		}
	}
}


@media (max-width: 768px) {

	.region-left .group-eventos-populares{

		.field-name-eventos-horizontales{
			.carga-nodos{
				.node{
					display: block;
					width: 100%;
					&:nth-of-type(2),
					&:nth-of-type(5){
						margin: 0 0;
					}
				}
			}
		}
	}

}
