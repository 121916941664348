.page-taxonomy-term{

   .region-top{
      .cabezote-seccion{
         &:before{
            background: none;
         }
         &:after{
            background: none;
         }
      }/*/cabezote-seccion*/
   }
   .one-column-main.cartel-panel{
      .panel-pane.pane-views.pane-nodequeue-1{/*nodo*/
         background-image: none !important;
         &::after{
            display: none;
         }
         .group-container-all{
            display: block;
            width: 100%;
            .field-name-field-imagen-cuadrada.field-type-image{/*img con*/
               &::before{
                  left: 10px;
                  right: 10px;
                  top: 10px;
                  bottom: 10px;
               }
            }
            .group-texto{/*info*/
               .field-name-title{/*titulo*/
                  h2{
                     font-size: 26px;
                  }
               }/*field-name-title */
               .group-texto-fecha{/*fecha*/
                  position: relative;
                  transform: translate(-50%);
                  .field-name-post-date, .field-name-field-seccion{
                     font-size: 15px;
                  }
                  .field-name-post-date{
                     font-size: 16px;
                  }
               }/*group-texto-fecha*/
            }/*/.group-texto*/
         }
      }/*/nodo*/
      .field-name-summary-body{
         display: block;
         width: 100%;
         margin-left: 0;
      }
      .pane-cartel-section-publicidad{/*publicidad*/
         display: block;
      }
   }

}
