.group-contenido-relacionado{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 5rem;
    >h3{
        background-color: @azul;
        padding: 5px 10px;
        font-size: 30px;
        span{
            color: @blanco;
        }

    }
    .field-name-relacionado{
        #related_content_slider {
            width: 100%;
            ul{
                padding: 0;
                li{
                    list-style: none;
vertical-align: middle;
    display: inline;
                    .node{
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                        width: 32%;
                        overflow-x: hidden;
                        box-shadow: 4px 3px 13px -1px rgba(0,0,0,0.7);
                        &:nth-child(3){
                            margin: 0 6px;
                        }

                        &.view-mode-relacionado_principal{
			    margin-top: 15px;
                            width: 100%;
                            font-size: 0;
                            margin-bottom: 10px;
                            box-shadow: none;
                            .group-left,
                            .group-right{
                                float: none;
                                display: inline-block;
                                vertical-align: top;
                                position: relative !important;
                            }
                            .group-left{
                                width: 65%;
                                &:hover #node_article_relacionado_principal_group_hover {
                                    //transform: scale(1);
                                    //opacity: 1;
                                }
                                .field-name-field-imagen-apaisada{
                                    &::after{
                                        content: none;
                                    }
                                }
                            }
                            .group-right{
                                border-right: none !important;
                                width: 34%;
                                padding: 0 15px;
                                .taxonomy-term{
                                    display: inline-block;
                                    padding: 0px 10px;
                                    /*colores*/
                                    &.taxonomy-term-música{ background-color: @color-musica;}
                                    &.taxonomy-term-deportes{ background-color: @color-deportes;}
                                    &.taxonomy-term-arte{ background-color: @color-arte;}
                                    &.taxonomy-term-noticias{background-color: @color-noticias;}
                                    &.taxonomy-term-sexo{ background-color: @color-sexo;}
                                    &.taxonomy-term-opinión{ background-color: @color-opinion;}
                                    &.taxonomy-term-diseño{   background-color: @color-diseno;}
                                    &.taxonomy-term-agenda{background-color: @color-agenda;}
                                    &.taxonomy-term-historias{background-color: @color-historias;}
                                    &.taxonomy-term-moda{ background-color: @color-moda;}
                                    &.taxonomy-term-420{ background-color: @color-420;}
                                }
                                .field-name-field-seccion{
                                    .field-name-field-icono{
                                        display: inline-block;
                                        a{
                                            color: @blanco;
                                            font-size: 20px;
                                        }
                                    }
                                    .field-name-title{
                                        display: inline-block;
                                        margin-left: 5px;
                                        width: inherit;
                                        h3{
                                            margin: 0;
                                            padding: 0 0 3px 0;
                                            font-size: 18px;
                                            a{
                                                color: @blanco;
                                            }
                                        }
                                    }
                                }
                                .field-name-title{
                                    width: 80%;
                                    h2{
                                        font-size: 28px;
                                        a{
                                        }
                                    }
                                }
                            }
                        }

                        .field-name-field-imagen-apaisada{
                            overflow: hidden;
                            a{
                                &::before{
                                    content: "";
                                    position: absolute;
                                    z-index: 9;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                }
                                img{
                                    width: 100% !important;
                                    height: auto !important;
                                    margin: 0 !important;
                                    transition: all 10s ease-in-out;
                                    transform: scale(1);
                                    padding-bottom: 2em;
                                    background-color: #000;
                                }
                            }
                            &::after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                bottom: 2em;
                                background: linear-gradient(to bottom, rgba(248,80,50,0) 62%, rgba(0,0,0,1) 100%);
                            }
                            @media screen and (min-width: 480px){
                                /* &:hover{
                                img{
                                transform: scale(1.3);
                            }
                            }*/
                            }
                        }
                        #node_article_relacionado_principal_group_hover {
                            display: none;
                            font-size: initial;
                            position: absolute;
                            z-index: 8;
                            width: calc(100% - 2rem);
                            height: calc(100% - 2rem);
                            top: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            border: 2px solid #07B9E3;
                            margin: 1rem;
                            background: rgba(0, 0, 0, 0.27);
                            font-family: 'bebas_neueregular',Arial-narrow;
                            transform: scale(.25);
                            opacity: 0;
                            transition: all linear 250ms;
                            .field-name-clone-titulo {
                                text-align: center;
                                a {
                                    position: relative;
                                    font-size: 28px;
                                    line-height: 28px;
                                    margin-bottom: 20px;
                                    color: #fff;
                                    &:after {
                                        content: '';
                                        width: 80%;
                                        height: 2px;
                                        background: #07B9E3;
                                        display: block;
                                        margin: 0 auto;
                                    }
                                }
                            }
                            /*color hover segun categoria*/
                            &.Música{
                                border-color: @color-musica;
                                a:after{
                                    background: @color-musica;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-musica !important;
                                    &::before, &::after {
                                        border-color: @color-musica transparent !important;
                                    }
                                }
                            }

                            &.Deportes{
                                border-color: @color-deportes;
                                a:after{
                                    background: @color-deportes;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-deportes !important;
                                    &::before, &::after {
                                        border-color: @color-deportes transparent !important;
                                    }
                                }
                            }

                            &.Arte{
                                border-color: @color-arte;
                                a:after{
                                    background: @color-arte;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-arte !important;
                                    &::before, &::after {
                                        border-color: @color-arte transparent !important;
                                    }
                                }
                            }

                            &.Noticias{
                                border-color: @color-noticias;
                                a:after{
                                    background: @color-noticias;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-noticias !important;
                                    &::before, &::after {
                                        border-color: @color-noticias transparent !important;
                                    }
                                }
                            }

                            &.Sexo{
                                border-color: @color-sexo;
                                a:after{
                                    background: @color-sexo;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-sexo !important;
                                    &::before, &::after {
                                        border-color: @color-sexo transparent !important;
                                    }
                                }
                            }

                            &.Opinión{
                                border-color: @color-opinion;
                                a:after{
                                    background: @color-opinion;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-opinion !important;
                                    &::before, &::after {
                                        border-color: @color-opinion transparent !important;
                                    }
                                }
                            }

                            &.Diseño{
                                border-color: @color-diseno;
                                a:after{
                                    background: @color-diseno;
                                }
                               .field-name-fecha-corta .field-item{
                                    background: @color-diseno !important;
                                    &::before, &::after {
                                        border-color: @color-diseno transparent !important;
                                    }
                                }
                            }

                            &.Agenda{
                                border-color: @color-agenda;
                                a:after{
                                    background: @color-agenda;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-agenda !important;
                                   &::before, &::after {
                                        border-color: @color-agenda transparent !important;
                                    }
                                }
                            }

                            &.Historias{
                                border-color: @color-historias;
                                a:after{
                                    background: @color-historias;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-historias !important;
                                    &::before, &::after {
                                        border-color: @color-historias transparent !important;
                                    }
                                }
                            }


                            &.Moda{
                                border-color: @color-moda;
                                a:after{
                                    background: @color-moda;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-moda !important;
                                    &::before, &::after {
                                        border-color: @color-moda transparent !important;
                                    }
                                }
                            }

                            &.420{
                                border-color: @color-420;
                                a:after{
                                    background: @color-420;
                                }
                                .field-name-fecha-corta .field-item{
                                    background: @color-420 !important;
                                    &::before, &::after {
                                        border-color: @color-420 transparent !important;
                                    }
                                }
                            }

                            #node_article_relacionado_principal_group_texto_fecha {
                                margin-top: 1rem;
                                position: relative;
                                .field-name-fecha-corta {
                                    .field-item {
                                        background: #07B9E3;
                                        color: #fff;
                                        &:before, &:after {
                                            content: '';
                                            display: block;
                                            position: absolute;
                                            height: 100%;
                                            width: 15px;
                                            border-color: #07B9E3 transparent;
                                            border-style: solid;
                                            top: 0;
                                        }
                                        &:before {
                                            left: 0;
                                            transform: translateX(-100%);
                                            border-width: 22px 0px 0px 15px;
                                        }
                                        &:after {
                                            right: 0;
                                            transform: translateX(100%);
                                            border-width: 22px 15px 0px 0px;
                                        }
                                    }
                                }
                            }
                        }
                        .datos-relacionado{
                            position: absolute;
                            left: 50%;
                            transform: translate(-50%);
                            bottom: 0px;
                            width: 90%;
                            .field-name-field-seccion{
                                .taxonomy-term{
                                    display: inline-block;
                                    padding: 2px 7px;
                                    /*colores*/
                                    &.taxonomy-term-música{ background-color: @color-musica;}
                                    &.taxonomy-term-deportes{ background-color: @color-deportes;}
                                    &.taxonomy-term-arte{ background-color: @color-arte;}
                                    &.taxonomy-term-noticias{background-color: @color-noticias;}
                                    &.taxonomy-term-sexo{ background-color: @color-sexo;}
                                    &.taxonomy-term-opinión{ background-color: @color-opinion;}
                                    &.taxonomy-term-diseño{   background-color: @color-diseno;}
                                    &.taxonomy-term-agenda{background-color: @color-agenda;}
                                    &.taxonomy-term-historias{background-color: @color-historias;}
                                    &.taxonomy-term-moda{ background-color: @color-moda;}
                                    &.taxonomy-term-420{ background-color: @color-420;}


                                    .field-name-field-icono{
                                        display: inline-block;
                                        .field-item{
                                            color: @blanco;
                                        }
                                    }
                                    .field-name-title{
                                        display: inline-block;
                                        h3{
                                            margin: 0;
                                            font-size: 17px;
                                            color: @blanco;
                                        }
                                    }
                                }

                            }
                            .field-name-title{
                                h2{
                                    font-size: 15px;
                                    a{
                                        color: @blanco;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }//
}
.one-column-bottom {
    min-width: 1124px;
    max-width: 1300px;
    margin: auto;
    padding: 0 2em;
    @media (min-width: 768px) {
        width: 83.33333333%;
    }
    @media (max-width: @screen-sm-min) {
        min-width: inherit;
        padding: 0 5px;
    }
}
/*
CONTENIDOS RELACIONADOS
=====================================================================*/
.group-comentarios {
    margin-bottom: 5rem;
    @media (max-width: 768px){
        margin-bottom: 1rem;
    }
}
.region-bottom{

    @media (min-width: 768px){
        #related_content_slider .ds-2col>.group-left,
        #related_content_slider .ds-2col>.group-right {
            position: relative;
        }
    }
}
    /*nodos */
    .group-sponsored-content{
        width: 81%;
        margin: auto;
        margin-top: 2em;
        >h3{
            position: relative;
            color: @negro;
            font-size: 30px;
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                left: 0;
                bottom: 0;
                background-color: @negro;
            }
        }
        .field-name-field-sponsored-content{
            >.field-items{
                /*
                @media (max-width: 1366px) {
                     min-height: 258px;
                }
                */
                /*min-height: 362px;*/
                margin-bottom: 5rem;
                &>div{
                    display: inline-block;
                    vertical-align: top;
                    width: 24%;
                    box-shadow: 4px 3px 13px -1px rgba(0,0,0,0.7);
                    &:nth-child(2),
                    &:nth-child(3){
                        margin: 0 1%
                    }
                    .node-article{
                        .field-name-field-imagen-cuadrada{
                            &::after{
                                bottom: 15px;
                            }
                        }
                        .field-name-clone-seccion{
                            padding: 4px 6px 5px 31px;
                            h3{
                                font-size: 18px;
                            }
                        }
                        .group-texto-subtitulo{
                            padding-bottom: 0;
                            padding: 0 10px;
                            height: 14vh;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            @media (max-width: 1370px) {
                                height:18vh;
                            }

                            .field-name-title{
                                margin: 0;
                                h1 {
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                    line-height: 0.8;
                                    a{
                                        font-size: 20px;
                                        line-height: 0;
                                    }
                                }
                            }
                            .field-name-field-subtitulo{
                                margin: 8px 0 10px;
                                font-family: 'Lato',sans-serif;
                                font-weight: normal;
                                font-size: 14px;
                                color: #333;
                                .field-item{
                                    padding-bottom: 5px;
                                    font-size: 14px;
                                    line-height: 1;

                                }
                            }
                            .group-contenido-texto{
                                display: none;
                            }
                        }
                    }
                }
                /*Hover Contenido Patrocinado interna articulos*/
                @media(max-width: 1315px){
                    .node.view-mode-destacados_home_general .group-hover{
                        h1 {
                            font-size: 20px;
                            line-height: 20px;
                        }
                    }
                    /*Cuadro categoria*/
                    div .node-article{
                        .field-name-clone-seccion, .field-name-clone-seccion h3{
                            font-size: 14px;
                         }
                    }
                    .group-texto-fecha {
                        font-size: 13px;
                        .field-name-fecha-corta{
                            margin-right: 7px;
                            padding-right: 7px;
                        }
                    }
                }
            }
        }
    }

.region-left {

    .group-sponsored-content{
        width: 100%;
        margin-bottom: 5rem;
        @media (max-width:768px){
            padding: 0 30px;
            margin-bottom: 0rem;
        }
        .field-name-field-sponsored-content{
            &>.field-items>div{
                width: 32%;
            }
            img {
                margin: 0;
            }
        }
    }
}


/*mobile*/
@media (max-width: @screen-sm-min) {

    .field-name-contenido-relacionado{
        .field-name-post-date{padding: 0 !important;}
    }
    .group-contenido-relacionado{
        width: 100%;
        padding: 30px;
        .field-name-relacionado{
            #related_content_slider {
                width: 100%;
                ul{
                    li{
                        .node.node-article{
                            width: 100%;
                            margin: 0.5em 0;
                            &.view-mode-relacionado_principal{
                                border-bottom: 2px solid @negro;
                                .group-left{
                                    width: 100%;
                                }
                                .group-right{

                                    width: 100%;
                                    padding: 0;
                                    .taxonomy-term {
                                        width: 100%;
                                        text-align: center;
                                    }
                                    .field-name-title{
                                        width: 80%;
                                    }
                                }
                            }
                            &:nth-child(3) {
                                margin: 0 0%;
                            }
                        }
                    }
                }
            }
        }
    }

    /*nodos */
    .group-sponsored-content{
        // width: 95%;
        width: 100%;
        .field-name-field-sponsored-content{
            >.field-items{
                min-height: inherit;
                &>div{
                    width: 100%;
                    margin: 1em 0 !important;
                    &:nth-child(2),
                    &:nth-child(3){
                        margin: 0 0%
                    }
                }
            }
        }
    }
}

/*Contenido Patrocinado Ene 2017*/
div#node_article_detalle_sidebar_group_sponsored_content {
    .field-items {
        .item-list ul {
            li {
                width: 32%;
                display: inline-block;
                margin-right: 5px;
                  #node_article_contenido_patrocinado_group_texto_subtitulo .field-name-title,
                  #node_article_contenido_patrocinado_group_texto_subtitulo .field-name-field-subtitulo,
                  .field-name-clone-seccion{
                      display: none;
                  }
                  #node_article_contenido_patrocinado_group_texto_subtitulo{
                      background-color: transparent !important;
                  }
                .view-mode-contenido_patrocinado{
                    .field-name-field-imagen-cuadrada{
                        img{ margin-top: 0; }
                        &::after{
                                content: "";
                                opacity: 1;
                                transform: scale(1);
                                left: 10px;
                                right: 10px;
                                top: 10px;
                                bottom: 40px;
                                color: rgba(0, 0, 0, 0.6);
                        }
                    }
                    .field-name-field-patrocinado-por- {
                        position: absolute;
                        z-index: 999;
                        left: 50%;
                        transform: translateX(-50%);
                        color: #fff;
                        width: 100%;
                        text-align: center;
                        top: 15px;
                        font-weight: 900;
                        div{
                            float: none;
                            display: inline-block;
                        }
                        .field-label{
                            font-weight: 100;
                            text-transform: capitalize;
                        }
                        .field-items{
                            text-transform: uppercase;
                            font-size: 14px;
                        }
                       h1{
                          font-family: 'Lato',sans-serif;
                          font-size: 14px;
                          text-transform: uppercase;
                          line-height: 14px;
                          margin-bottom: 13px;
                          font-weight: 900;
                          width: 70%;
                          margin-left: 15%;
                           a{
                              color: #fff;
                           }
                       }
                    }

                    .group-contenedor-fecha{
                        background-color: #1a1918 !important;
                        padding-bottom: 5px;
                        .field-name-post-date{
                           font-family: 'Lato',sans-serif;
                           font-size: 12px;
                           color: white !important;
                           padding-top: 3px;
                           padding-left: 0;
                        }
                        &::after{
                            display: none;
                        }
                    }

                    .group-hover {
                        transform: translate(-50%, -50%) scale(1);
                        opacity: 1;
                        padding: 5px;
                        h1,
                        .group-texto-first h1{
                            font-family: 'Lato',sans-serif;
                            font-size: 14px;
                            text-transform: uppercase;
                            line-height: 14px;
                            margin-bottom: 13px;
                            font-weight: 900;
                            &:after{
                                height: 1px;
                            }
                        }
                        .group-texto-fecha{
                            line-height: 20px;
                            height: 25px;
                            position: relative;
                            &::before, &::after{
                                border-bottom: 25px solid transparent;
                            }
                            .field-name-fecha-corta{
                                display: none;
                            }
                            .field-type-taxonomy-term-reference{
                                font-family: 'Lato',sans-serif;
                                font-size: 14px;
                                text-transform: uppercase;
                                &:before{
                                    content: "A";
                                    font-family: "cartel-urbano" !important;
                                    display: inline-block;
                                    position: relative;
                                    top: 2px;
                                    padding: 0 5px 0 0;
                                }
                                div{display: inline-block}
                            }
                        }
                    }

                }


               // Border Color and Before by category
               .node.taxonomy-term-noticias{
                    .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before {
                       content: "C";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-noticias;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-noticias;
                   }
               }
               .node.taxonomy-term-música{
                    .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "n";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-musica;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-musica;
                   }
                }
               .node.taxonomy-term-deportes{
                   .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "F";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-deportes;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-deportes;
                   }
               }
               .node.taxonomy-term-arte{
                   .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "B";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-arte;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-arte;
                   }
                }
               .node.taxonomy-term-sexo{
                   .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "G";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-sexo;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-sexo;
                   }
                }
               .node.taxonomy-term-opinión{
                   .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "M";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-opinion;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-opinion;
                   }
                }
               .node.taxonomy-term-diseño{
                   .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "M";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-diseno;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-diseno;
                   }
                }
               .node.taxonomy-term-agenda{
                   .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "C";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-agenda;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-agenda;
                   }
                }
               .node.taxonomy-term-historias{
                     .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                        content: "H";
                    }
                    .field-name-field-imagen-cuadrada::after{
                        border-color: @color-historias;
                    }
                    .field-name-field-patrocinado-por- .field-label{
                        color: @color-historias;
                   }
                }
               .node.taxonomy-term-moda{
                   .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "o";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-moda;
                   }
                    .field-name-field-patrocinado-por- .field-label{
                        color: @color-moda;
                   }
                }
               .node.taxonomy-term-420{
                   .group-hover .group-texto-fecha .field-type-taxonomy-term-reference:before{
                       content: "M";
                   }
                   .field-name-field-imagen-cuadrada::after{
                       border-color: @color-420;
                   }
                   .field-name-field-patrocinado-por- .field-label{
                        color: @color-420;
                   }
                }


                @media(max-width: 768px){
                    width: 100%;
                }
            }
        }
    }
}
