/*galeria interna galeria
===============================================*/
.node-type-gallery {
   .gallery-modal-unique{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.57);
      z-index: 999 !important;
      width: 100%;
      height: 100%;

      .close-gallery{/*btn close*/
         position: absolute;
         top: 3%;
         right: 2%;
         padding: 1px 18px;
         border: solid 2px;
         border-radius: 76%;
         cursor: pointer;
         color: #BABABB;
         font-size: 45px;
         transition: transform linear 0.5s;
         &:hover{
            transform: rotate(180deg);
         }
      }/*/.close-gallery{*/

      ul.slides{/*lista de img's*/

         li{
            img{
               max-height: 600px;
               position: fixed;
               left: 50%;
               top: 50%;
               max-width: 70%;
               transform: translate(-50%, -50%);
               width: 100%;
            }
         }
      }/*/slides*/

      ol.flex-control-nav{/*nav bar gallery*/
         position: absolute;
         bottom: 20px;
         left: 50%;
         transform: translate(-50%);
         @media (max-width: 1366px) {
            bottom: -4px;
         }
         li{
            display: inline-block;
            margin-right: 7px;
            cursor: pointer;
            a{
               color: @blanco;
               &.flex-active{
                  font-size: 0;
                  &::before{
                     content: 'Q';
                     font-family: "cartel-urbano";
                     font-size: 34px;
                     top: 9px;
                     position: relative;
                  }
               }
            }
         }
      }/*/.flex-control-nav*/

      ul.flex-direction-nav{/*btn previus next*/
         position: absolute;
         left: 47%;
         top: 25%;
         transform: translate(-50%, -50%);
         width: 90%;
         @media (max-width: 1366px) {
            left: 45%;
         }
         li{
            list-style: none;
            cursor: pointer;
            &:nth-child(1) {  }
            &:nth-child(2) {float: right; }
            a{
               font-size: 0;
               transition: all linear 0.5s;
               &:hover{
                  transform: scale(1.1);
               }
               &::before{
                  content: " ";
                  display: inline-block;
                  width: 97px;
                  height: 231px;
                  width: 104px;
                  height: 294px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  position: absolute;
                  transform: scale(1);
               }
            }
            a.flex-prev{
               &::before{
                  background-image: url(../img/left_arrow.png);
               }
            }
            a.flex-next{
               &::before{
                  background-image: url(../img/right_arrow.png);
               }
            }
         }
      }/*/ul.flex-direction-nav*/

   }/*/ .gallery-modal-unique*/

   /*custom cursor
   ===========================================*/
    .layout-split {
      .region-right {
         img {
            cursor: url(../img/ojo.png), pointer;
         }
      }
   }/*/.layout-split*/


}/*/.node-type-gallery*/

// Nuevas Galerias Ene 31 2017
#node_gallery_full_group_titulo_cabecera{
    margin-left: 8.33333333%; 
    //width: 1224px;


.container-gallery-new{
  position: relative;
  margin: 15px 20px;
  width: 60.33333333%;
  display: inline-block;
  img{
    width: 100%;
  }
  button[type="button"]{
    position: absolute;
    bottom: -3px;
    display: block;
    background-color: transparent;
    border: 0;
    color: transparent;
    outline: 0;
    font-size: 2rem;
    padding: 5px;    
    &:focus{
      outline: 0;
    }    
    &.slick-next{
      left: 63px;
      z-index: 9;
      &:before{
        content: ">";
        color: #fff;
      }
    }
    &.slick-prev{
      left: 10px;
      z-index: 9;
      &:before{
        content: "<";
        color: #fff;
      }  
    }
  }
  .paginador{
    font-size: 14px;
    top: 10px;
    left: 19px;
    position: absolute;
  }
  .item-gallery-new{
    p{
      position: absolute;
      width: 100%;
      text-align: right;
      background-color: rgb(49, 49, 49);
      color: #fff;
      padding: 5px 15px;
      bottom: 0;
      margin: 0;
    }
    p.desc-new{
      min-height: 29px;
    }
    @media(max-width: 768px){
      p.desc-new{
        min-height: 0px;
      }
    }
  }
}
.container-navigation-new{
  margin: 15px 20px;
  vertical-align: top;
  display: inline-block;
  width: 300px;
  img{
    width: 100%;
  }  
  .item-gallery-new{
    max-height: 120px;
    overflow: hidden;
    p {display: none;}
  }
  button[type="button"]{
    position: absolute;
    z-index: 9;
    width: 100%;
    border: 0;
    /* background-color: rgba(0,0,0,0.69); */
    color: #fff;
    border: 10px solid rgba(0, 0, 0, 0.72);
    padding: 0;
    border-bottom-color: transparent;
    line-height: 0;
    border-width: 25px 149px;
    color: transparent;
    background-color: transparent;
    &:focus{
      outline: 0;
    }
    &:before{
        content: "";
        background-image: url(../img/top.svg);
        width: 12px;
        height: 12px;
        -webkit-background-size: contain;
        background-size: contain;
        position: absolute;
        background-repeat: no-repeat;
        top: -20px;
        left: calc(50% - 5px);
    }
    &.slick-next{
      border-bottom-color: rgba(0, 0, 0, 0.72);
      border-top-color: transparent;
      bottom: 0;
      &:before{
        background-image: url('../img/bottom.svg');
        top: auto;
        bottom: -20px;
      }
    }  
  }

  }
  @media(max-width: 767px){
    margin-left: 0;
    .container-navigation-new{
      display: none;
    }
    .container-gallery-new.slick-initialized.slick-slider{
      width: 100%;
      margin: 0;
      .item-gallery-new p{
        position: relative;
      }
    }
  }
}

// publicidad debajo de galeria
.field.field-name-dfp-728-new-gallery.field-type-ds.field-label-hidden{
    //margin-top: -82px;
    width: 63%;
    text-align: center;
    margin-bottom: 10px;
    overflow: hidden;
}


.slick-vertical .slick-slide{
  width: 50% !important;
  display: inline-block !important;
}



/**
  slide gallerify
*/
.two-8-4-column.cartel-panel.panel-display{
  clear: both;
}
.newSlider-two{
  overflow: visible;
  img{width: 100%;}
  div.loader {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    width: 500px;
    height: 502px; /* This should be set to be at least the height of the largest image in the slideshow */
  }

  div.slideshow span.image-wrapper{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .container-slider{
    position: relative;
    display: inline-block;
    float: left;
    height: 500px;
    overflow: hidden;
    //.col-sm-7
    width: 58.33333333%;
    margin-left: 8.33333333%;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .slideshow-container{
    #slideshow{
      position: relative;
      overflow: visible;
      height: 500px;      
      a{
        font-size: 0;
      }
      .image-wrapper{
        // object-fit: cover;
        // left: 50%;
        // transform: translateX(-50%);
        // width: 100%;
        height: 100%;
        a{
          width: 100%;
          height: 100%;
          overflow: visible;
          display: block;
          object-fit: contain;
          position: relative;
        }
        img{
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .caption{
    padding-left: 90px;
    position: relative;
    top: 4px;
    p{
      position: absolute;
      right: 5px;
      font-size: 1.1rem;
      margin: 0;
      opacity: 0;
      width: calc(100% - 90px);
      top: -4px;
      }
  }
  .image-caption.current p{
      opacity: 1;
      transition: .6s ease opacity;
  }
    .caption-container{
      position: absolute;
      width: 100%;
      text-align: right;
      background-color: #313131;
      color: #fff;
      padding: 5px 15px;
      bottom: 0;
      margin: 0;  
      right: 0;   
      z-index: 9;
      height: 25px;
    }
    .controls{
      position: absolute;
      width: 90px;
      text-align: center;
      background-color: #313131;
      color: #fff;
      padding: 5px 0;
     
      bottom: 0;
      margin: 0;     
      z-index: 10;  
      height: 25px; 
       a{
          font-size: 2rem;
          color: #fff;
       }  
       .nav-controls{
         position: relative;
          top: -7px;
       }
      .pagin-gallery{
        font-size: 1rem;
        display: inline-block;
        margin: 0 3px;
        position: relative;
        vertical-align: middle;
        top: -2px;
      }
    }

    @media(min-width: 767px){

      #thumbs{
        list-style: none;
        position: relative;
        // .col-sm-3
        width: 25% !important;
        position: relative;
        min-height: 1px;
        left: 10px;
        // padding-left: 15px;
        // padding-right: 15px;     
        .thumbs{
          width: 300px;
          margin: 0 auto;
        }   
        
        ul{
          height: 610px;
          padding: 0 !important;
          overflow: hidden;
        }
        li{
          margin:0 0 5px;
          display: inline-block;
          width: 48%;
          clear: both;
          height: 99px;
          object-fit: cover;      
          &:nth-child(even){
            float: right;
          }
          img{
            height: 99px;
          }
        }

        .pagination{
          width: 300px;
          margin: 0 auto;
          position: absolute;
          z-index: 9;
          border: 0;
          color: #fff;
          border: 10px solid rgba(0,0,0,0.72);
          padding: 0;
          border-bottom-color: transparent;
          line-height: 0;
          border-width: 25px 149px;
          color: transparent;
          background-color: transparent;      
          display: block;
          margin: 0;
          left: 50%;
          transform: translateX(-50%);
          a{
            color: transparent;
            font-size: 0;
          }
          a:before{
              content: "";
              background-image: url(../img/top.svg);
              width: 12px;
              height: 12px;
              -webkit-background-size: contain;
              background-size: contain;
              position: absolute;
              background-repeat: no-repeat;
              top: -20px;
              left: calc(50% - 5px);        
          }
        }    
        .bottom.pagination{
          border-bottom-color: rgba(0,0,0,0.72);
          border-top-color: transparent;
          bottom: 0px;
          
          a:before{
            background-image: url(../img/bottom.svg);
            top: auto;
            bottom: -20px;
          }
        }
      }
    }
    @media(max-width: 1310px){
      #thumbs{
        left: 20px;
        .pagination{
          left: 0;
          transform: translateX(0);
        }
      }
   }
}
@media (max-width: 1024px){
    .newSlider-two{
      .container-slider{
        width: 56%;
      }
    }
}

@media (max-width: 768px){
    .newSlider-two{
      margin-left: 0;
      .container-slider{
        display: none !important;
      }
    }
    #thumbs{
      width: 100%;
      .thumbs{
        padding-left: 0;
      }
      button{
          position: absolute;
          width: 50%;
          text-align: center;
          background-color: #313131;
          color: #fff;
          padding: 0;
          bottom: 0;
          margin: 0;
          z-index: 10;
          height: 25px;
          border: 0;
          display: inline-block;
          color: transparent;   
          &:before{
            content: "<";
            color: #fff;
            display: block;
          }  
          &:focus{
            outline: none;
          }
      }
      button.slick-next{
          right: 0;
          left: auto;
          &:before{
            content: ">";
          }            
      }
    }
}