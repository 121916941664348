/*-----------------------------------------------------------------------*/
/*----------------------------nodo dos col-------------------------------*/
/*-----------------------------------------------------------------------*/
.node.view-mode-card_destacado_home, {
    .group-texto-subtitulo{
        position: relative;
        background-color: @blanco;
        .field-name-title {/*remplazo*/
            .field-items{
                .field-item{
                    h1, a{
                        color: @negro;
                    }
                }
            }
        }
        .field-name-field-subtitulo{
            div{
                color: @negro !important;
            }
        }
        .group-contenido-texto{

        }
    }/*fin bloke fecha*/
}

.attachment #node_article_slider_home_mobile_destacado_group_datos {
    display: none;
}

/*nodo galeria*/
.node.view-mode-card_destacado_home{
    .taxonomy-term-arte{
        .group-texto-subtitulo{
            background: none;
        }
    }
}
/*-----------------------------------------------------------------------*/
/*----------------------------nodo una col-------------------------------*/
/*-----------------------------------------------------------------------*/
.node.view-mode-destacados_home_general {
    .group-texto-subtitulo{
        position: relative;
        padding-bottom: 45px;
        .field-name-title {/*remplazo*/
            .field-items{
            }
        }
    }/*fin bloke fecha*/
}


/*-----------------------------------------------------------------------*/
/*-------------------------nodo galeria video----------------------------*/
/*----------------------------------------------------------------------*/
.node.node-gallery, .node.node-video {
    .group-texto-subtitulo{
        position: absolute;

    }/*fin bloke fecha*/

}/*-----------------------------------------------------------------------*/
/*------------------------nodo destacado galeria video--------------------*/
/*-----------------------------------------------------------------------*/
.node.node-gallery, .node.node-video.view-mode-card_destacado_home {
    .group-texto-subtitulo{
        position: absolute;

    }/*fin bloke fecha*/
}


.node.view-mode-destacados_home_general{
    .field.field-name-field-imagen-cuadrada{
        &::after{
            background-color: rgba(0, 0, 0, 0.44);
        }
    }
    .group-hover {
        &::before{
            display: none;
        }
    }
}

/*---------------------------------hover---------------------------------*/
/*.node:hover{
.group-hover,
.field-name-field-imagen::after {
opacity: 1;
}
.group-hover{
transform: translate(-50%, -50%) scale(1);
}
.field-name-field-imagen::after{
transform: scale(1);
}
.field-name-field-imagen img{
transform: scale(1.3);
}
.group-texto-subtitulo {
background: transparent !important;
}
.group-texto-subtitulo .field-name-title,
.group-texto-subtitulo .field-name-field-subtitulo {
transform: translate(0,200%);
opacity: 0;
}
.group-contenedor-fecha {
background-color: #1a1918 !important;
color: white !important;
&::after{
background-color: white;
}
}
}*/

.region-galerias .pane-ver-mas-home{
    left: 0;
}
