.page-search #block-system-main {
	.clearfix();
	margin-left: -15px;
	margin-right: -15px;
	@media (min-width: @screen-sm-min) {
		width: 83.33333333%;
		margin-left: 8.33333333%;
        margin-top: 40px;
	}

	form.search-form { .hidden; }

	.text-center {
		float: left;
		width: 100%;
	}
}

.page-search .region-content{
  text-align:center;
}

.page-search .region-content .node-article{
  width: 30%;
  vertical-align:top;
  display:inline-block;
  
}
.form-type-select.form-item-custom-search-types.form-item.form-group{
display:none;
}
