.page-ediciones-impresas .col-sm-12{
    padding: 0px;
}


/*---------CABEZOTE-----------
-----------------------------*/
.view-lista-ediciones-impresas .view-header{
    position: relative;
    font-family: @bebas;
    .rtecenter {
        img{
            width: 100% !important;
            height: auto !important;
        } 
    }
    .title-edicion-impresa,
    .teaser-edicion{
        position: absolute;
        left: 50%;
        -ms-transform: translate( -50%, 0); /* IE 9 */
        -webkit-transform: translate( -50%, 0); /* Chrome, Safari, Opera */
        transform: translate( -50%, 0);
        color: white;
        text-align: center;
    }
    .title-edicion-impresa{
        top: 35%;
        font-size: 80px;
       @media (max-width: 1366px) {
        font-size: 70px;
       }
        border-bottom: solid 2px @azul;
        padding: 0 5px;
        width: 65%;
        height: 55px;
    }
    .teaser-edicion{
        font-size: 24px;
        font-family: 'Lato',sans-serif;
        font-weight: normal;
        top: 60%;
        text-transform: uppercase;
        width: 60%;
        line-height: 35px !important;
    }

}

/*-----VISTA EDICIONES-------
-----------------------------*/

.view-lista-ediciones-impresas .view-content{
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    .views-row{
        display: inline-block;
        max-width: 28%;
        margin: 2.5%;
        position: relative;
        -webkit-box-shadow: 0px 6px 30px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 6px 30px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 6px 30px -4px rgba(0,0,0,0.75);
        img{
            width: 100%;
            height: auto;
        }
        .field-name-field-numero-edicion{
            background: white;
            position: absolute;
            bottom: 0px;
            padding: 15px;
            width: 100%;
            text-align: center;
            & > div{
                display: inline-block;
                float: none;
            }
            div, a{
                font-size: 18px;
                color: black;
            }
            .field-label{
                font-weight: normal;
            }
            .field-items a{
                margin-left: 20px;
                position: relative;
                font-weight: bold;
                &:before{
                    content: 'N°';
                    position: absolute;
                    display: block;
                    left: -22px;
                    font-weight: bold;
                }
            }
        }
        &:hover{
            .field-name-field-numero-edicion{
                background: @azul;
                div, p, a{
                    color: white;
                }
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 767px){
    .view-lista-ediciones-impresas .view-header .title-edicion-impresa{
        font-size: 28px;
        line-height: 26px !important;
    }
    .teaser-edicion{
        display: none;
    }
    .view-lista-ediciones-impresas .view-content{
        width: 100%;
        .views-row{
            max-width: 80%;
            width: 80%;
        }   
    }
}
