#block-header-menu-2018-header & > div{
    * {
        font-family: 'Alegreya', serif;
    }
    & > .first {
        background-color: #000;
        padding: 8px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
            color: white;
            font-weight: 600;
        }
        .center {
            flex-basis: 130px;
            svg {
                width: 130px;
                fill: white;
                margin-top: -7px;
            }
        }
        .right {
            flex-basis: 300px;

            .buscador {
                &:before {
                    display: none;
                }
                .element-invisible {
                    margin: 0;
                }
                form {
                    border: 0;
                }
                button {
                    font-size: 0;
                    background: transparent;
                    border: 0;
                    &:before {
                        content: "h";
                        position: relative;
                        font-family: "cartel-urbano" !important;
                        font-style: normal !important;
                        font-weight: normal !important;
                        font-variant: normal !important;
                        text-transform: none !important;
                        line-height: 1;
                        z-index: 2;
                        display: block;
                        font-size: 18px;
                        color: white;
                    }
                }
                input {
                    padding: 0;
                    margin: 0;
                    height: 26px;
                    border-bottom-color: #c7c7c7;
                    padding: 0; 
                }
            }
        }
    }
    .second {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        .menu {
            li {
                display: inline-block;
                border-right: 1px solid black;
                &.last {
                    border-right:0;
                }
                a {
                    color: black;
                    font-weight: 500;
                    padding: 5px 15px;
                    &::first-letter {
                        text-transform: uppercase;
                    }
                    &:hover,
                    &:focus {
                        color: white;
                        background-color: #666;
                    }
                }
                &.active-trail{
                    background-color: #666;
                    a {
                        color: white;
                    }
                } 
            }
        }
        .right {
            ul {
                padding: 0;
                list-style: none;
                li {
                    display: inline-block;
                    vertical-align: bottom;
                    &.manif,
                    &.team,
                    &.fb,
                    &.inst,
                    &.tw,
                    &.mail {
                        font-size: 0;
                        a {
                            display: block;
                            width: 30px;
                            height: 30px;
                            background-size: calc(100% - 6px);
                            background-position: 50%;
                            background-repeat: no-repeat;
                            margin: 0;
                        }
                    }

                    &.manif a {
                        background-image: url('../img/logo-manifest.png');
                    }

                    &.team a {
                        background-image: url('../img/logo-equipo.png');
                    }

                    &.fb a {
                        background-image: url('../img/logo-facebook.png');
                    }

                    &.inst a {
                        background-image: url('../img/logo-instagram.png');
                    }

                    &.tw a {
                        background-image: url('../img/logo-twitter.png');
                    }

                    &.mail a {
                        background-image: url('../img/logo-mail.png');
                    }

                    &.agenda {
                        background: black;
                        padding: 4px 18px;
                        font-weight: 500;
                        margin-right: -15px;
                        margin-left: 8px;
                        a {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
.icon-cartel-ico-menu {
    display: none;
}


@media (max-width: 767px) and (min-width: 320px){
    #block-header-menu-2018-header & > div{ 
        padding-top: 50px;
        & > .first { 
            padding: 0;
            display: block;
            background-color: white;
            .left {
                display: none;
            }
            .center {
                display: block;
                width: 100%;
                height: 45px;
                padding: 8px 15px;
                background-color: black;
                position: fixed;
                top: 0;
                z-index: 9;
            }

            .right .buscador {
                form {
                    width: calc(100% - 50px);
                    margin: 5px auto;
                }
                input {
                    background: transparent;
                    box-shadow: none;
                    border-bottom-color: black;
                }
                button:before {
                   color: black;
               }
            }
        }

        .second {
            display: block;
            text-align: center;
            .right .agenda {
                display: none;
            }
            .right ul li a {
                width: 40px !important; 
                height: 40px !important;
            }

            .left.menu {
                position: fixed;
                background: #191919;
                color: white;
                left: 0;
                top: 44px;
                width: 170px;
                z-index: 9;
                height: 100vh;
                left: -100%;
                transition: .7s all ease;   
                li {
                    display: block;
                    border-bottom: 1px solid rgba(140, 140, 139, 0.55);
                    a {
                       color: #8c8c8b;
                       text-align: left;
                       font-size: 17px;
                       padding: 15px;
                   }
                
                }
            }


            .icon-cartel-ico-menu {
                display: block;
                width: 30px;
                height: 30px;
                background-image: url('../img/mas.png');
                background-size: contain;
                position: fixed;
                top: 8px;
                left: 10px;
                font-size: 0;
                z-index: 99;
                transition: .6s all ease;
                &.icon-cartel-ico-menu-toggle{
                    transform: rotate(226deg);
                }
                &.icon-cartel-ico-menu-toggle + .left.menu {
                   left: 0;
                }
            }
            
        }
    }
    
    #block-header-menu-2018-header > div > div.second > div.right > ul > li.agenda.last {
        display: block !important;
        margin: 0 !important;
        position: relative !important;
        top: 10px !important;
    }
}


section#block-header-menu-2018-header > div{
	
	padding-top: 50px;
	
	& > .first {
	    position: fixed;
    	    width: 100%;
    	    top: 0;
   	    z-index: 99;
	}
}
