/* COLORES ESPECIFICOS PARA MUSICA
======================================================= */
#div_sub_menu_section.musica {
	border-top: 2px solid @color-musica;
	border-bottom: 2px solid @color-musica;
}

#div_sub_menu_section.musica #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-musica;
}

#div_sub_menu_section.musica #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-musica;
}

/* COLORES ESPECIFICOS PARA DEPORTES
======================================================= */
#div_sub_menu_section.deportes {
	border-top: 2px solid @color-deportes;
	border-bottom: 2px solid @color-deportes;
}

#div_sub_menu_section.deportes #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-deportes;
}

#div_sub_menu_section.deportes #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-deportes;
}


/* COLORES ESPECIFICOS PARA arte
======================================================= */
#div_sub_menu_section.arte {
	border-top: 2px solid @color-arte;
	border-bottom: 2px solid @color-arte;
}

#div_sub_menu_section.arte #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-arte;
}

#div_sub_menu_section.arte #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-arte;
}


/* COLORES ESPECIFICOS PARA noticias
======================================================= */
#div_sub_menu_section.noticias {
	border-top: 2px solid @color-noticias;
	border-bottom: 2px solid @color-noticias;
}

#div_sub_menu_section.noticias #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-noticias;
}

#div_sub_menu_section.noticias #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-noticias;
}


/* COLORES ESPECIFICOS PARA sexo
======================================================= */
#div_sub_menu_section.sexo {
	border-top: 2px solid @color-sexo;
	border-bottom: 2px solid @color-sexo;
}

#div_sub_menu_section.sexo #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-sexo;
}

#div_sub_menu_section.sexo #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-sexo;
}


/* COLORES ESPECIFICOS PARA opinion
======================================================= */
#div_sub_menu_section.opinion {
	border-top: 2px solid @color-opinion;
	border-bottom: 2px solid @color-opinion;
}

#div_sub_menu_section.opinion #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-opinion;
}

#div_sub_menu_section.opinion #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-opinion;
}


/* COLORES ESPECIFICOS PARA diseño
======================================================= */
#div_sub_menu_section.diseno {
	border-top: 2px solid @color-diseno;
	border-bottom: 2px solid @color-diseno;
}

#div_sub_menu_section.diseno #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-diseno;
}

#div_sub_menu_section.diseno #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-diseno;
}


/* COLORES ESPECIFICOS PARA historia
======================================================= */
#div_sub_menu_section.historias {
	border-top: 2px solid @color-historias;
	border-bottom: 2px solid @color-historias;
}

#div_sub_menu_section.historias #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-historias;
}

#div_sub_menu_section.historias #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-historias;
}


/* COLORES ESPECIFICOS PARA moda
======================================================= */
#div_sub_menu_section.moda {
	border-top: 2px solid @color-moda;
	border-bottom: 2px solid @color-moda;
}

#div_sub_menu_section.moda #flexslider-items-submenu ul.slides li > div.node {
	border-left: 1px solid @color-moda;
}

#div_sub_menu_section.moda #flexslider-items-submenu ul.slides li > div.node .field-name-title a {
	color: @color-moda;
}
