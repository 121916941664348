.page-proteccion-de-datos{

   .agenda-top{
      position: relative;
      img{
         width: 100% !important;
         height: auto !important;
      }
      .texto-politicas {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         font-size: 70px;
         color: @blanco;
         font-family: 'bebas_neueregular';
         &::after{
            content: " ";
            width: 50%;
            height: 5px;
            background-color: @azul;
            position: relative;
            display: block;
            margin: auto;
         }
      }
   }

   .region-content{
      margin-top: 40px;
      .tabs-processed{/*tabs*/
         border: none;
         .ui-tabs-nav{/*ul*/
            max-width: 650px;
            width: 100%;
            margin: auto;
            border: none;
            background: none;
            margin-top: -55px;
            .ui-tabs-active,
            .ui-state-active{
               outline: none;
               background: none;
               border: none;
               font-weight: bold;
            }
            .ui-tabs-focus {
               outline: none;
            }
            .ui-state-default{
               outline: none;
               background: none;
               border: none;
               border-radius: 0px;
               border-right: 2px solid @azul;


               a{
                  color: @blanco;
                  font-size: 15px;
                  text-transform: uppercase;
                  text-align: center;
               }
               a:focus {
                outline: none;
               }  
            }
         }
         .ui-tabs-panel{
            width: 80%;
            margin: auto;
            margin-top: 27px;
            p{
               font-family: 'Lato',sans-serif !important;
               font-weight: normal;
               font-size: 16px;
               color: @gris-oscuro;
            }
         }
      }
   }
}



