@media (max-width: 767px) and (min-width: 320px) {
    /*---------------------------nodos-------------------------*/
    @import "_m-proteccion-de-datos";
    @import "_m-galeria";
    @import "_m-pag";
    /*------------- pag interna galeria  -------------*/
    @import "_m-galery-pag-interna";



    .node-type-eventos{
        .field-name-fblikebutton-ds-field{
            position: static;
            display: block;
            margin: 0 auto;
        }
        .field-name-social-network{
            position: static;
            width: 100%;
            text-align: center;
            ul{
                width: 100%;
                li{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    ul{
                        width: 100%;
                        margin-top: 10px;
                        display: inline-block;
                        position: relative;
                        left: 50%;
                        transform: translate(-50%);
                        li{
                            display: inline-block;
                            width: auto;
                            margin-right: 15%;
                        }
                    }
                }
            }
        }
    }

}/*/mediaq*/


//tamaño de la fuente
body.mobile-site{
  font-size: 16px;
  .page-node .field-name-body {
    font-size: 16px;
  }
}
