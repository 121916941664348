/* ajax link */
#ajax_link {
   .clearfix();
   display: block;
   float: left;
   width: 100%;
   text-align: center;
}

/* header seccion - mobile first! */
.region-top .cabezote-seccion {
   position: relative;
   overflow: hidden;

   img {
      width: 100%;
     // max-width: 1300px;
      height: auto;
   }

   .titulo-seccion {
      background-color: @azul;
      /* text styles */
      text-align: left;
      color: white;

      h1 {
         margin: 5px 15px;
         font-size: 40px;

         &::after { content: ' //'; letter-spacing: -5px; }
      }

      span {
         display: none;
      }
   }

   div.background-titulo-seccion {
      position: absolute;
      height: 100px;
      width: 100%;
      left: 0;
      top: ~"calc(50% + 110px)";
      margin-top: -50px;
      background-color: rgba(0,0,0,0.7);

      .texto-entradilla-seccion {
         display: table;
         margin: auto;
         width: 60%;
         height: 100px;
      }

      p {
         display: table-cell;
         vertical-align: middle;
         /* text styles */
         text-align: center;
         color: white;
      }
   }

   @media (max-width: @screen-sm-min) {
      img,
      .background-titulo-seccion { .hidden; }
   }

   @media (min-width: @screen-sm-min) {
      .titulo-seccion {
         position: absolute;
         width: 50%;
         height: 100px;
         left: 50%;
         top: 50%;
         margin-left: -25%;
         margin-top: -50px;
         background: transparent;

         text-align: center;

         h1,
         span {
            display: inline-block;
            margin: 0;
            line-height: 100px;
            font-size: 105px;
            text-shadow: 0 2px 10px #000;
         }

         h1 {
            &::after { content: ''; }
         }

         span {
            visibility: visible;
            position: relative;
            width: 100px;
            height: 100px;
            left: 30px;
            top: -12px;

            font-family: "cartel-urbano";
            font-size: 50px;
            z-index: 1;

            transform: scale(0.7);

            &::after {
               background-color: @azul;
            }
         }

         @media (min-width: @high-res) {
            top: 45%;
            h1 { font-size: 150px; }
            span { top:-30px; transform: scale(1); }
         }

      }
   }
}


/* cuerpo del landing
///////////////////////////////////////============ */

.page-taxonomy .one-column-main .row > .region-main {
   > .panel-separator { .clearfix(); }

   /* quitar blockquotes ============== */
   blockquote { .hidden; }
}

.page-taxonomy .view-mode-destacado_seccion { position: relative; }

.page-taxonomy .pane-cu-list-content-section-block-list-content-section {
   &::before {
      content: '';
      display: block;
      width: ~"calc(100% - 30px)";
      border-top: 1px solid @gris;
      margin: 25px auto;
   }
}

.page-taxonomy .region-main .panel-separator:nth-of-type(2),
.page-taxonomy .one-column-main .row > .region-main > .panel-separator:nth-of-type(3) { float: left; }

.page-taxonomy .one-column-main .row > .region-main > .panel-separator:nth-of-type(3) {
   &::before,
   &::after { clear: initial; }
}

@media (min-width: @screen-sm-min) {
   .page-taxonomy .pane-cartel-section-publicidad {
      width: 35%;
      float: left;
   }
}

.page-taxonomy .pane-cartel-section-publicidad .publicidad {
   position: relative;
   padding-top: 100%;
}

.page-taxonomy .pane-cartel-section-publicidad .publicidad > div {
   position: absolute;
   width: 300px;
   height: 250px;
   top: 50%;
   left: 50%;
   transform: translate3d(-50%, -50%, 0);
   -webkit-transform: translate3d(-50%, -50%, 0);
   -moz-transform: translate3d(-50%, -50%, 0);
   -ms-transform: translate3d(-50%, -50%, 0);
   -o-transform: translate3d(-50%, -50%, 0);
}

/* destacado */
.pane-nodequeue-1 {
   position: relative;
   background-position: center center;
   background-repeat: no-repeat;
   background-size: cover;

   img { width: 100%; height: auto; }

   /* stilos moviles */
   @media (max-width: @screen-sm-min) {
      padding: 15px;
      margin-left:-15px;
      margin-right:-15px;

      &::after {
         content:'';
         position: absolute;
         display: block;
         left: 0;
         top: 0;
         right: 0;
         bottom: 0;
         opacity: 0.5;
       //  .m-deg-destacado();
      }

      .group-left,
      .group-right {
         float: none;
         width: 100%;

         .group-textos-destacado {
            border: none !important;
            .field-name-post-date { font-family: @bebas; font-size: 16px; margin-top: 10px; }
         }
      }

      .group-left .field-name-titulo-moviles { .hidden(); } /* oculto temporalmente */

      .group-left .field-name-titulo-moviles,
      .group-left .group-textos-destacado {
         position: relative;
         z-index: 6;
      }

      .group-left .group-textos-destacado { padding-top: 70px; }

      .group-left .field-name-social-network {
         position: relative;
         left: 0;
         top: 0;

         .compartido_facebook { z-index: 105; }

      }

      .group-left .field-name-field-imagen-cuadrada img,
      .group-left .group-textos-destacado .field-name-field-seccion { /*.hidden();*/ } /* ocultamos seccion */

      .group-left .field-name-post-date,
      .group-left .field-name-titulo-moviles a,
      .group-right .field-name-summary-body {
         color: white;
      }

      .group-left .field-name-field-imagen-cuadrada a::after {
         content: '';
         position: absolute;
         display: block;
         left: 0;
         top: 0;
         right: 0;
         bottom: 0;
         z-index: 6;
      }

      .group-right {

         > div {
            position: relative;
            z-index: 5;
         }

         .field-name-title {} /* titulo de desktop, oculto en moviles */

         .field-name-title {
            position: absolute;
            top: -12px;
            left: 40px;
            width: 80%;

            a { color: white; }
         }
      }
   }

   /* stilos desktop */
   @media (min-width: @screen-sm-min) {
      width: ~"calc(65% - 30px)";
      padding-left: 15px;
      padding-right: 15px;
      float: left;
      background: none !important; /* quita imagen de fondo en moviles */

      .group-left {
         position: relative;
         overflow: hidden;
         width: 65%;

         .field-name-titulo-moviles { .hidden(); }

         .field-name-field-imagen-cuadrada {
            a {
               display: block;
               position: relative;

               &::before {
                  content: '';
                  position: absolute;
                  top: 20px;
                  left: 20px;
                  bottom: 20px;
                  right: 20px;
                //  .m-deg-destacado();
                  opacity: 0.5;
               }

               &::after {
                  content: '';
                  position: absolute;
                  top: 20px;
                  left: 20px;
                  bottom: 20px;
                  right: 20px;
                  z-index: 100;
               }
            }
         }

         .field-name-field-tipo {
            position: absolute;
            width: 135px;
            height: 142px;
            top: 40%;
            left: 50%;
            margin-left: -67.5px;
            margin-top: -71px;
            font-family: "cartel-urbano";
            font-size: 135px;
            color: white;
         }

         .group-textos-destacado {
            position: absolute;
            width: 160px;
            height: 56px;
            left: 50%;
            margin-left: -80px;
            bottom: 20%;
            border: 2px solid @azul;

            text-align: center;
            text-transform: uppercase;

            z-index: 5;

            .field-name-field-seccion {
               .field-name-field-icono { .hidden; }

               .field-name-title h3 {
                  margin: 0;
                  margin-top: 6px;
                  font-family: @strait;
                  font-size: 18px;
                  color: white;
               }
            }

            .field-name-post-date {
               font-family: @strait;
               font-size: 18px;
               color: white;
            }
         }
      }

      .group-right {
         width: ~"calc(35% - 15px)";
         padding-left: 15px;

         h2 {
            margin-top: 0;
            line-height: 36px;
         }

         .field-name-body,
         .field-name-summary-body,
         {
            bottom: 15px;
         }
      }

   }
}

/* single block */
.cartel-block {
   /* transitions */
   .group-textos-articulo,
   .group-textos-articulo::after,
   .group-textos-articulo::before,
   .field-name-title,
   .group-textos-articulo-der,
   .field-name-field-tipo .field-name-field-icono,
   .field-name-body,
   .field-name-field-subtitulo,
   .field-name-field-entradilla
   {
      .transition (all 0.5s ease);
   }

   /*
   specific selectors:
   .node-article,
   .node-gallery,
   .node-video,
   .node-eventos
   */
   .node {
      position: relative;
      overflow: hidden;
      padding-bottom: 0;

      @media (max-width: @screen-sm-min) {
         .field-name-body,
         .field-name-summary-body,
         .field-name-field-subtitulo,
         .field-name-field-entradilla {
            p, span { font-size: 13px !important; }
         }
      }

      /* desktop */
      @media (min-width: @screen-sm-min) {
         .field-type-image a {
            display: block;
            position: relative;
         }

         &:hover {
            .field-type-image a {
               &::after {
                  content:'';
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  z-index: 100;
               }
            }

            .group-textos-articulo {
               min-height: ~"calc(100% - 30px)";
               width: ~"calc(100% - 30px)";
               left: 15px;

               &::after { opacity: 0.7; }
               &::before { opacity: 0; }

               .field-name-title {
                  text-align: center;
                  width: 70%;
                  padding: 0;
                  margin: auto;
               }

               .group-textos-articulo-der {
                  top: 60%;
                  right: 50%;
                  margin-right: -42.5px;

                  .field-name-field-icono {
                     width: 80px;
                     top: -70px;
                     left: 50%;
                     margin-left: -40px;
                     background-color: transparent;
                     font-size: 80px;
                  }
               }

               .field-name-field-subtitulo,
               .field-name-field-entradilla {
                  .translate(-50%,0);
               }
            }
         }
      }

   }

   .group-textos-articulo {

      h2 { font-size: 26px; line-height: 22px; }
      h3 { font-size: 24px; }

      .field-name-field-icono {
         background-color: @azul;
         /* text styles */
         text-align: center;
         font-family: "cartel-urbano";
         font-size: 30px;
         color: white;

         .field-items {
            display: table;
            width: 40px;
            height: 40px;
         }

         .field-item { display: table-cell; vertical-align: middle; }
      }

      .field-name-field-seccion .field-name-title,
      .field-name-field-tipo { display: none; visibility: hidden; }

      .field-name-field-seccion .field-name-field-icono {
         position: absolute;
         overflow: hidden;
         width: 40px;
         height: 40px;
         top: 0;
         right: 0;
      }
   }
   /* stilos desktop */
   @media (min-width: @screen-sm-min) {
      padding-top: 15px;
      padding-bottom: 15px;

      .node-article,
      .node-gallery {
         //min-height: 338px;
      }

      .group-textos-articulo {
         position: absolute;
         overflow: hidden;
         width: 100%;
         min-height: 30%;
         left: 0;
         bottom: 15px;
         padding: 7px 15px;

         z-index: 2;

         color: white;

         &::after,
         &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
         }

         &::after {
           // .m-deg-destacado();
            opacity: 0;
         }

         &::before {
            background-color: black;
            opacity: 1;
         }

         a { color: white; }

         .field-name-field-seccion .field-name-field-icono {
            .hidden;
         }

         .field-name-field-seccion .field-name-title,
         .field-name-field-tipo { .mostrar; }

         .field-name-title {
            width: 60%;
            padding-left: 10px;

            h2 {
               font-size: 22px;
               line-height: 19px;

               a { color: white; }
            }
         }

         .group-textos-articulo-der {
            position: absolute;
            width: 85px;
            height: 40px;
            right: 20px;
            top: 50%;
            margin-top: -20px;
            border: 1px solid @azul;

            /* icono tipo */
            .field-name-field-icono {
               position: absolute;
               width: 40px;
               height: 40px;
               top: -1px;
               left: -40px;

               line-height: 40px;
            }

            /* nombre de sección y fecha */
            .field-name-post-date .field-items,
            .field-name-field-fecha, /* fecha de los eventos */
            .field-name-field-seccion h3 {
               font-family: @strait;
               text-transform: uppercase;
               font-size: 13px;
               text-align: center;
            }

            .field-name-field-seccion h3 {
               margin-top: 5px;
               margin-bottom: 0;
            }

            .field-name-title { width: 100%; padding: 0; }

            /* fecha de los eventos */
            .field-name-field-fecha {
               padding-top: 5px;
               text-align: center;
               line-height: 13px;
            }
         }

         /* Subtitulo / entradilla --------------------- */
         .field-name-field-subtitulo,
         .field-name-field-entradilla {
            position: absolute;
            width: 80%;
            max-height: 50px;
            left: 50%;
            bottom: 20px;
            .translate(-500%,0);

            line-height: 16px;
            text-align: center;
         }
      }
   }
}
/* Poniendo diamante
===================================================== */
@media (min-width: @screen-sm-min) {
   .page-taxonomy .cartel-block > div {

      &:hover {
         &::after { .translate (100%, 0) }
         &::before { .translate (-100%, 0) }
      }

      &::after,
      &::before {
         .transition(transform 0.3s ease-in-out);
         content: '';
         display: block;
         position: absolute;
         width: 0;
         height: 0;
         top: 75%;

         z-index: 10;

         border-bottom: 20px solid transparent;
         border-top: 20px solid transparent;
      }

      &::before {
         left: 0;
         border-left: 20px solid white;
      }

      &::after {
         right: 0;
         border-right: 20px solid white;
      }
   }
}
/* separar grupos de nodos con ornamento
===================================================== */
#div_more {
   .clearfix();
   &::before { clear:both; }
   > div {
      .clearfix();
      position: relative;
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px solid @negro;

      &::before {
         content:' I I I ';
         position: absolute;
         display: block;
         width: 200px;
         height: 30px;
         top: -22px;
         left: 50%;
         margin-left: -100px;

         background-color: white;

         text-align: center;
         font-family: "cartel-urbano";
         font-size: 35px;
         color: @negro;
      }
   }
}
ul.pager.pager-load-more{
   height: auto !important;
}
/* VER MAS
===================================================== */
.page-taxonomy a#ajax_link.ajax-processed,
.page-galerias .pager-load-more a,
.view-lista-ediciones-impresas .pager-load-more a,
.pager-next.first.last a{ /* cargar más en galerias */
         position: relative;
         display: block;
         border-radius: 0;
         border: none;
         padding: 10px 0;
         margin-bottom: 80px;
         border-bottom: 1px solid @negro;
         clear: both;
        // font-family: @strait;
         font-size: 12px;
         letter-spacing: 5px;
         color: @negro;
         font-size: 15px;
         font-weight: 500;
         text-transform: uppercase;
         &:hover { background: transparent; }

         &::after {
            text-transform: none;
            content: 'm' !important;
            display: block !important;
            position: absolute;
            width: 200px;
            height: 50px;
            top: 34px;
            left: 50%;
            margin-left: -100px;

            background-color: white;

            font-size: 73px;
            line-height: 46px;
            font-family: "cartel-urbano";
         }
      }
/* ajustes por nuevo navbar
===================================================== */

.page-galerias{
   .row{
      margin-top: 10px;
      @media(max-width:768px){
            margin-top: 0;
      }
   }
}


/* sobre escribiendo clase para moviles
==================================================== */
.page-taxonomy .cartel-block .group-textos-articulo .group-textos-articulo-der {
   /* stilos moviles */
   @media (max-width: @screen-sm-min) {
      border: none !important;
      .field-name-post-date { font-family: @bebas; font-size: 16px; }
   }
}

@media (max-width: @screen-sm-min) {
   .pane-nodequeue-1 .field-name-field-tipo,
   .pane-nodequeue-1 .field-name-field-icono { .hidden; }
}

@media (max-width: 768px){
	.pane-nodequeue-1 .group-right .field-name-title {
	    position: absolute;
	    top: 300px;
	    left: 40px;
	    width: 80%;
	}

@media (max-width: 768px){
	.pane-nodequeue-1 .group-right .field-name-title {
	    position: absolute;
	    top: 300px;
	    left: 40px;
	    width: 80%;
	}
	.pane-nodequeue-1 .group-right .field-name-title a {
    	color: #000;
	}
}
}
