.view-id-nodequeue_8 { //Selector for view (nodequeue) recommended events
  .view-header {
    margin-bottom: 35px;
    .header-recommended-events {
      width: 100%;
      margin: 18% 0px 15px 0px;
      padding: 0;
      .centered-text {
        text-align: center;
        color: #fff;
        background-color: #000;
        height: 1px;
        span {
          background-color: #000;
          padding: 5px 32px 7px;
          font-weight: bold;
          position: relative;
          bottom: 15px !important;
        }
      }
    }
  }
  //End header

  .view-content { //Content
    .views-row {
      .view-mode-eventos_recomendados {
        margin: 10px 0;
        //Image
        //Date
        .field-name-field-fecha {
          margin-top: 15px;
          .field-items .field-item .date-display-single a {
            color: #000;
          }
        }

        //Title
        .field-name-title {
          border: none;
          margin: 0px 0px 0px 0px;
          padding: 0;
          height: auto;
          .field-items .field-item h2 a {
            color: #818285;
          }
        }
      }
    }
  }

  //End content
  .view-footer { //Footer
    .footer-recommended-events {
      .centered-text.uppercased {
        text-align: center;
        text-transform: uppercase;
        background: #000;
        height: 1px;
        position: relative;
        a,
        a:hover,
        a:focus {
          color: #000;
          position: relative;
          bottom: 10px;
          background-color: #fff;
          padding: 6px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}